import React, { useState } from "react";

import Label from "./label";
import Input from "./input";
import Button from "./button";
import LineMsg from "./lineMsg";
import RemovableItemWithDropdown from "./removableItemWithDropdown";

import { strings } from "../../util/formatter";
import SVGIcon from "./svgIcons";

export const USER_TYPE = [
    {
        label: "مالك",
        value: "owner"
    },
    {
        label: "مشرف",
        value: "admin"
    }
];

const ManageEntityUsers = (props) =>
{
    const { entity, setEntity } = props;
    const [entityUsers, setEntityUsers] = useState(entity?.users);
    const [email, setEmail] = useState("");

    const invite = () =>
    {
        console.log("invite");
    };

    const removeUser = (item) =>
    {
        const temp = entityUsers;
        const arr = temp?.filter(user => user.id !== item.id);
        setEntityUsers([...arr]);
        setEntity({ users: arr });
    };

    const addUserType = (type, item) =>
    {
        const temp = entityUsers;
        const foundItem = temp?.find(category => category.value === item.value);

        if (foundItem)
        {
            foundItem.type = type;
            setEntityUsers([...temp]);
            setEntity({ users: temp });
        }
    };

    return (
        <>
            <div className="w-full p-grid-xs">
                <Label label={strings("label.enterEmailToAddUser")}/>
                <div className="flex justify-between items-center gap-grid-sm">
                    <div className="w-full">
                        <Input
                            name="email"
                            placeholder={strings("placeholder.email")}
                            value={email}
                            setValue={setEmail}
                        />
                    </div>
                    <div onClick={invite} className="cursor-pointer hover:opacity-70 transition-opacity duration-fast">
                        <SVGIcon name="addInCircle" width={24} height={24} viewBox="0 0 44 44" className="fill-neutral-low-darkest"/>
                    </div>
                </div>
                <LineMsg type="error"/>
            </div>
            {entityUsers?.length > 0 &&
                entityUsers?.map((user, index) =>
                    (
                        <RemovableItemWithDropdown
                            key={index}
                            index={index}
                            item={user}
                            placeholder={strings("placeholder.userType")}
                            labelKey={"name"}
                            selected={user?.type?.label ? user?.type : {}}
                            dropdownList={USER_TYPE}
                            listLength={entityUsers?.length}
                            addValue={(e) => addUserType(e, user)}
                            removeItem={() => removeUser(user)}
                        />
                    ))
            }
        </>
    );
};

export default ManageEntityUsers
;