import React, { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";

import Section from "../view/component/section";
import Avatar from "../view/component/avatar";
import Table from "../view/component/table";

import { SessionContext } from "../util/context";
import constant from "../config/constant";
import { strings } from "../util/formatter";

export const branches = [
    { id: 1, city: "الرياض", address: "روبين بلازا - طريق الدائري الشمالي", branchType: "نقطة بيع", link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu" },
    { id: 1, city: "الرياض", address: "روبين بلازا - طريق الدائري الشمالي", branchType: "متجر إلكتروني", link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu" },
    { id: 1, city: "الرياض", address: "روبين بلازا - طريق الدائري الشمالي", branchType: "نقطة بيع", link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu" },
    { id: 1, city: "الرياض", address: "روبين بلازا - طريق الدائري الشمالي", branchType: "نقطة بيع", link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu" },
    { id: 1, city: "الرياض", address: "روبين بلازا - طريق الدائري الشمالي", branchType: "نقطة بيع", link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu" },
    { id: 1, city: "الرياض", address: "روبين بلازا - طريق الدائري الشمالي", branchType: "نقطة بيع", link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu" },
    { id: 1, city: "الرياض", address: "روبين بلازا - طريق الدائري الشمالي", branchType: "نقطة بيع", link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu" },
    { id: 1, city: "الرياض", address: "روبين بلازا - طريق الدائري الشمالي", branchType: "نقطة بيع", link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu" },
    { id: 1, city: "الرياض", address: "روبين بلازا - طريق الدائري الشمالي", branchType: "نقطة بيع", link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu" },
    { id: 1, city: "الرياض", address: "روبين بلازا - طريق الدائري الشمالي", branchType: "نقطة بيع", link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu" }
];

const Seller = () =>
{
    const { prefs } = useContext(SessionContext);
    const [tableData, setTableData] = useState([]);

    const ITEM = {
        id: 1,
        logo: "https://play-lh.googleusercontent.com/sGJ92rD1dBl0Z-ybhp3GAUp8Pj-R3G44WCz4at5Z7kCoS0bPdPQmrbffYCbeBN9tDTM",
        name: "مكتبة جرير",
        commercial_register: "101097537535",
        country: "المملكة العربية السعودية",
        entityType: "متجر كتب",
        url: "https://www.jarir.com",
        availability: branches
    };
    const ITEM_EN = {
        id: 1,
        logo: "https://play-lh.googleusercontent.com/sGJ92rD1dBl0Z-ybhp3GAUp8Pj-R3G44WCz4at5Z7kCoS0bPdPQmrbffYCbeBN9tDTM",
        name: "Jarir Bookstore",
        commercial_register: "101097537535",
        country: "Saudi Arabia",
        entityType: "Book Store",
        url: "https://www.jarir.com",
        availability: branches
    };

    const BasicContent = (title, item, itemEn, isLink = false) =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm text-base">
                <p className="w-[180px] font-primary">
                    {strings(title)}:
                </p>
                {isLink
                    ? <Link to={item} className="underline underline-offset-2 text-primary-regular">
                        {prefs?.lang === constant.lang.values[0] ? item : itemEn}
                    </Link>
                    : <p>
                        {prefs?.lang === constant.lang.values[0] ? item : itemEn}
                    </p>
                }
            </div>
        );
    };

    const onClickRow = (item) =>
    {
        if (item?.link)
        {
            window.open(item?.link, "_blank");
        }
    };

    useEffect(() =>
    {
        setTableData(prefs?.lang === constant.lang.values[0] ? ITEM?.availability : ITEM_EN?.availability);
    }, []);

    return (
        <Section>
            <div className="relative pt-[60%] sm:pt-[40%] md:pt-[35%] lg:pt-[20%]">
                <p className="px-grid-lg md:px-0 md:container mx-auto text-xl font-primary text-primary-lightest">
                    {prefs?.lang === constant.lang.values[0] ? ITEM.name : ITEM_EN.name}
                </p>
                <div className="mx-grid-lg md:mx-auto md:container mt-grid-xl flex flex-col md:flex-row gap-grid-sm lg:gap-grid-lg items-center justify-start p-grid-sm lg:p-grid-lg bg-primary-lightest text-secondary-darkest text-lg font-secondary rounded-sm shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]">
                    <div className="w-auto">
                        <Avatar src={prefs?.lang === constant.lang.values[0] ? ITEM.logo : ITEM_EN.logo} size="w-48 h-48"/>
                    </div>
                    <div className="w-full md:w-[66.66%] font-secondary">
                        {BasicContent("common.commercialRegister", ITEM.commercial_register, ITEM_EN.commercial_register)}
                        {BasicContent("common.country", ITEM.country, ITEM_EN.country)}
                        {BasicContent("common.entityType", ITEM.entityType, ITEM_EN.entityType)}
                        {BasicContent("common.storeURL", ITEM.url, ITEM_EN.url, true)}
                    </div>
                </div>
                <div className="px-grid-lg md:mx-auto container mt-grid-xxl mb-grid-xxl">
                    <p className="pt-grid-xxl pb-grid-xl text-xl font-primary text-primary-lightest">
                        {strings(`productPage.salePoints`)}
                    </p>
                    {tableData?.length > 0 &&
                        <Table
                            titles={tableData?.length > 0 ? Object.keys(tableData[0])?.filter(title => !["id", "link"]?.includes(title)) : []}
                            tableData={tableData?.filter(title => !["id", "link"]?.includes(title))}
                            columnType={{ clickable: [] }}
                            shouldLoadMore={false}
                            shouldShowArrow={true}
                            onClickRow={onClickRow}
                        />
                    }
                </div>
            </div>
        </Section>
    );
};

export default Seller;