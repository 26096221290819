import React from "react";

import LoadingSkeleton from "./loadingSkeleton";

const DataCardPercentage = (props) =>
{
    const { number, label, description, loading = true } = props;

    return (
        <div className={`p-grid-sm flex flex-col justify-center items-start text-start bg-neutral-high-regular text-neutral-low-dark transition-colors duration-fast ease-in rounded-sm`}>
            {loading
            ? <div className="pb-grid-sm"><LoadingSkeleton rows="1" rowHeight="h-[48px]" rowWidth = "w-20" align="items-start"/></div>
            : <p className="text-xl text-primary-regular transition-colors duration-fast ease-in font-primary pb-grid-sm">
                {number}
            </p>
            }
            <p className="text-md font-primary pb-grid-xs">
                {label}
            </p>
            <p className="text-xs">
                {description}
            </p>
        </div>
    );
};

export default DataCardPercentage;