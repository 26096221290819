import React, { createRef, useState } from "react";

import LineMsg from "./lineMsg";
import Label from "./label";
import Input from "./input";
import Textarea from "./textarea";
import DropdownMenu from "./dropdownMenu";
import FileInput from "./fileInput";

import { strings } from "../../util/formatter";

export const LANGUAGE = [
    {
        label: "العربية",
        value: "ar"
    },
    {
        label: "الإنجليزية",
        value: "en"
    }
];

export const CURRENCY = [
    {
        label: "ريال سعودي",
        value: "sar"
    },
    {
        label: "دولار امريكي",
        value: "dollar"
    }
];

const AddProductStep1 = () =>
{
    const coverRef = createRef(null);
    const [nameAR, setNameAR] = useState("");
    const [nameEN, setNameEN] = useState("");
    const [descriptionAR, setDescriptionAR] = useState("");
    const [descriptionEN, setDescriptionEN] = useState("");
    const [pagesCount, setPagesCount] = useState("");
    const [productLang, setProductLang] = useState({});
    const [price, setPrice] = useState("");
    const [currency, setCurrency] = useState({});
    const [cover, setCover] = useState({});

    const selectCover = () =>
    {
        if (coverRef.current !== null)
        {
            coverRef.current.click();
        }
    };

    return (
        <>
            <div className="flex flex-col sm:flex-row justify-between sm:gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.nameAR")}/>
                    <Input
                        name="nameAR"
                        value={nameAR}
                        setValue={setNameAR}
                    />
                    <LineMsg type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.nameEN")}/>
                    <Input
                        name="nameEN"
                        value={nameEN}
                        setValue={setNameEN}
                    />
                    <LineMsg type="error"/>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-between sm:gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.descriptionAR")}/>
                    <Input
                        name="descriptionAR"
                        value={descriptionAR}
                        setValue={setDescriptionAR}
                    />
                    <LineMsg type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.descriptionEN")}/>
                    <Input
                        name="descriptionEN"
                        value={descriptionEN}
                        setValue={setDescriptionEN}
                    />
                    <LineMsg type="error"/>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-between sm:gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.pagesCount")}/>
                    <Input
                        name="pagesCount"
                        value={pagesCount}
                        setValue={setPagesCount}
                    />
                    <LineMsg type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.productLang")}/>
                    <DropdownMenu
                        list={LANGUAGE}
                        labelKey="label"
                        placeholder={strings("placeholder.selectLang")}
                        selectedValue={productLang}
                        setSelected={setProductLang}
                    />
                    <LineMsg type="error"/>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-between sm:gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.price")}/>
                    <Input
                        name="price"
                        value={price}
                        setValue={setPrice}
                    />
                    <LineMsg type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.currency")}/>
                    <DropdownMenu
                        list={CURRENCY}
                        labelKey="label"
                        placeholder={strings("placeholder.selectCurrency")}
                        selectedValue={currency}
                        setSelected={setCurrency}
                    />
                    <LineMsg type="error"/>
                </div>
            </div>
            <div className="pb-grid-sm">
                <Label label={strings("label.coverImage")}/>
                <FileInput
                    fileRef={coverRef}
                    value={cover}
                    setValue={setCover}
                    selectFile={selectCover}
                />
                <LineMsg type="error"/>
            </div>
        </>
    );
};

export default AddProductStep1;