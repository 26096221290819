import React, { useContext, useRef } from "react";
import { Trans } from "react-i18next";
import { motion, useScroll, useTransform } from "framer-motion";

import Button from "./button";
import SVGIcon from "./svgIcons";

import { SessionContext } from "../../util/context";
import { strings } from "../../util/formatter";

import landingPageShape4 from "../../assets/image/landing_page_shape_4.webp";

const Section6 = () =>
{
    const { prefs, setActiveModal, modalOptions, setModalOptions } = useContext(SessionContext);
    const targetRef = useRef();
    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset: ["start end", "end end"]
    });
    const shapeTranslateY = useTransform(scrollYProgress, [0, 0.5], [-50, 0]);
    const shapeScale = useTransform(scrollYProgress, [0, 0.5], [0.8, 1]);
    const shapeOpacity = useTransform(scrollYProgress, [0, 0.5], [0, 1]);
    const lgCircleContainerRotate = useTransform(scrollYProgress, [0, 1], [50, 0]);
    const mdCircleContainerRotate = useTransform(scrollYProgress, [0, 1], [-50, 0]);
    const smCircleContainerRotate = useTransform(scrollYProgress, [0, 1], [30, 0]);
    const circleContainerOpacity = useTransform(scrollYProgress, [0.3, 0.7], [0.3, 1]);
    const contentContainerTranslateY = useTransform(scrollYProgress, [0, 1], [100, 0]);
    const contentContainerOpacity = useTransform(scrollYProgress, [0, 0.8], [0.3, 1]);

    return (
        <div className="relative mx-grid-md lg:mx-0 pb-grid-xxl">
            <motion.img style={{ translateY: shapeTranslateY, scale: shapeScale, opacity: shapeOpacity }} src={landingPageShape4} className="z-0 mx-auto w-[20%] blur-[2px]"/>
            <div ref={targetRef} className="flex flex-col lg:flex-row justify-between">
                <div className="w-full lg:w-1/2 h-[300px] sm:h-[450px] relative flex justify-center items-center">
                    <motion.div style={{ rotate: lgCircleContainerRotate, opacity: circleContainerOpacity }} className="absolute w-[300px] h-[300px] sm:w-[450px] sm:h-[450px] border border-primary-light/80 rounded-full flex justify-center items-center">
                        <div className="absolute top-[3%] end-[9%] w-[50px] h-[50px] sm:w-[70px] sm:h-[70px] flex justify-center items-center bg-primary-lightest rounded-full shadow-[0px_10px_20px_0_rgba(var(--color-secondary-darkest),0.15)]">
                            <SVGIcon name="shop" stroke="stroke-secondary-darkest"/>
                        </div>
                        <div className="absolute top-[6%] start-[10%] w-[35px] h-[35px] sm:w-[55px] sm:h-[55px] flex justify-center items-center bg-primary-lightest rounded-full shadow-[0px_10px_20px_0_rgba(var(--color-secondary-darkest),0.15)]">
                            <SVGIcon name="building2" stroke="stroke-secondary-darkest"/>
                        </div>
                        <div className="absolute top-[42%] -end-[5%] w-[36px] h-[36px] sm:w-[44px] sm:h-[44px] flex justify-center items-center bg-primary-lightest rounded-full shadow-[0px_10px_20px_0_rgba(var(--color-secondary-darkest),0.15)]">
                            <SVGIcon name="building1" stroke="stroke-secondary-darkest"/>
                        </div>
                        <div className="absolute top-[84%] start-[14%] w-[32px] h-[32px] flex justify-center items-center bg-primary-lightest rounded-full shadow-[0px_10px_20px_0_rgba(var(--color-secondary-darkest),0.15)]">
                            <SVGIcon name="bag1" stroke="stroke-secondary-darkest"/>
                        </div>
                    </motion.div>
                    <motion.div style={{ rotate: mdCircleContainerRotate, opacity: circleContainerOpacity }} className="absolute w-[200px] h-[200px] sm:w-[350px] sm:h-[350px] border border-primary-light/80 rounded-full flex justify-center items-center">
                        <div className="absolute top-[80%] end-[9%] w-[33px] h-[33px] sm:w-[53px] sm:h-[53px] flex justify-center items-center bg-primary-lightest rounded-full shadow-[0px_10px_20px_0_rgba(var(--color-secondary-darkest),0.15)]">
                            <SVGIcon name="house" stroke="stroke-secondary-darkest"/>
                        </div>
                        <div className="absolute top-[46%] -start-[6%] w-[32px] h-[32px] sm:w-[45px] sm:h-[45px] flex justify-center items-center bg-primary-lightest rounded-full shadow-[0px_10px_20px_0_rgba(var(--color-secondary-darkest),0.15)]">
                            <SVGIcon name="bag2" stroke="stroke-secondary-darkest"/>
                        </div>
                    </motion.div>
                    <motion.div style={{ rotate: smCircleContainerRotate, opacity: circleContainerOpacity }} className="absolute w-[100px] h-[100px] sm:w-[250px] sm:h-[250px] border border-primary-light/80 rounded-full">
                        <div className="absolute top-[-12%] sm:top-[-9%] start-1/2 w-[36px] h-[36px] sm:w-[56px] sm:h-[56px] flex justify-center items-center bg-primary-lightest rounded-full shadow-[0px_10px_20px_0_rgba(var(--color-secondary-darkest),0.15)]">
                            <SVGIcon name="cart" stroke="stroke-secondary-darkest"/>
                        </div>
                        <div className="absolute top-[87%] sm:top-[95%] start-[40%] w-[24px] h-[24px] flex justify-center items-center bg-primary-lightest rounded-full shadow-[0px_10px_20px_0_rgba(var(--color-secondary-darkest),0.15)]">
                            <SVGIcon name="dollar" stroke="stroke-secondary-darkest"/>
                        </div>
                    </motion.div>
                </div>
                <motion.div style={{ translateY: contentContainerTranslateY, opacity: contentContainerOpacity }} className="w-full lg:w-1/2 lg:ps-grid-xl">
                    <div className="relative z-10 pt-grid-xxl pb-grid-lg text-xl font-primary text-secondary-darkest">
                        <Trans
                            i18nKey="landingPage.sixthSectionHead"
                            components={{ span: <span className="text-primary-regular" />, p: <p /> }}
                        />
                    </div>
                    <p>
                        {strings("landingPage.sixthSectionSubsidiary")}
                    </p>
                    <div className="flex justify-end py-grid-lg">
                        <Button
                            text={strings("button.openFacilityAccount")}
                            onClick={() =>
                            {
                                setActiveModal("signup");
                                setModalOptions({
                                    ...modalOptions,
                                    title: strings("title.signup")
                                });
                            }}
                            icon="arrow"
                            bgColor="bg-secondary-darkest"
                            font="text-md"
                            padding="py-grid-xxs pe-grid-xs ps-grid-sm"
                            iconStyle={`${prefs.dir === "rtl" ? "" : "rotate-180"} fill-none`}
                            optionalStyle="flex items-center gap-grid-md"
                        />
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default Section6;