import React, { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";

import Label from "../view/component/label";
import Input from "../view/component/input";
import LineMsg from "../view/component/lineMsg";
import Button from "../view/component/button";

import { SessionContext } from "../util/context";
import { getLangCountryURL, strings } from "../util/formatter";

const Signup = () =>
{
    const { prefs, setActiveModal, modalOptions, setModalOptions } = useContext(SessionContext);
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const onClickVerify = () =>
    {
        setActiveModal(undefined);
        navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/directory`);
    };

    const onClick = () =>
    {
        setModalOptions({
            ...modalOptions,
            title: strings("title.otp"),
            onVerify: onClickVerify
        });
        setActiveModal("otp");
    };

    return (
        <>
            <div className="flex justify-between gap-4">
                <div className="w-full pb-4">
                    <Label label={strings("label.firstName")}/>
                    <Input
                        name="firstName"
                        value={firstName}
                        placeholder={strings("placeholder.firstName")}
                        setValue={setFirstName}
                    />
                    <LineMsg type="error"/>
                </div>
                <div className="w-full pb-4">
                    <Label label={strings("label.lastName")}/>
                    <Input
                        name="lastName"
                        value={lastName}
                        placeholder={strings("placeholder.lastName")}
                        setValue={setLastName}
                    />
                    <LineMsg type="error"/>
                </div>
            </div>
            <div className="pb-4">
                <Label label={strings("label.email")}/>
                <Input
                    name="email"
                    value={email}
                    placeholder={strings("placeholder.email")}
                    setValue={setEmail}
                />
                <LineMsg type="error"/>
            </div>
            <div className="pb-4">
                <Label label={strings("label.password")}/>
                <Input
                    name="password"
                    value={password}
                    type="password"
                    placeholder={strings("placeholder.password")}
                    setValue={setPassword}
                />
                <LineMsg type="error"/>
            </div>
            <div className="text-end pt-grid-lg">
                <Button
                    text={strings("button.next")}
                    padding = "py-2 px-10"
                    onClick={onClick}
                />
            </div>
        </>
    );
};

export default Signup;