import React from "react";

const Section = (props) =>
{
    const { children, optionalStyle = "", useContainer = true, id } = props;
    const sectionProps = {
        id
    };
    return (
        <div {...sectionProps} className={`relative transition-colors duration-middle ease-in ${optionalStyle}`}>
            <div className={`${useContainer ? "container max-w-screen-lg mx-auto" : ""}`}>
                {children}
            </div>
        </div>
    );
};

export default Section;