/* eslint-disable no-constant-condition */
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { AnimatePresence, motion } from "framer-motion";

import PostLoginHeader from "../view/component/postLoginHeader";
import SubMenu from "../view/component/subMenu";

import { SessionContext } from "../util/context";
import { getLangCountryURL } from "../util/formatter";
import useMediaQuery from "../hook/useMediaQuery";

const NOTIFICATIONS_LIST = [
    {
        id: 0,
        title: "كتابك 'إهانة غير ضرورية' الأعلى مبيعًا هذا اليوم",
        description: "يتصدر اليوم كتاب 'إهانة غير ضرورية' الآعلى مبيعًا هذا اليوم من بين 100 كتاب، اذهب لمشاهدة القائمة.",
        creation_date: "06-02-2024"
    },
    {
        id: 1,
        title: "كتابك 'إهانة غير ضرورية' الأعلى مبيعًا هذا اليوم",
        description: "يتصدر اليوم كتاب 'إهانة غير ضرورية' الآعلى مبيعًا هذا اليوم من بين 100 كتاب، اذهب لمشاهدة القائمة.",
        creation_date: "06-02-2024"
    },
    {
        id: 2,
        title: "كتابك 'إهانة غير ضرورية' الأعلى مبيعًا هذا اليوم",
        description: "يتصدر اليوم كتاب 'إهانة غير ضرورية' الآعلى مبيعًا هذا اليوم من بين 100 كتاب، اذهب لمشاهدة القائمة.",
        creation_date: "06-02-2024"
    },
    {
        id: 3,
        title: "كتابك 'إهانة غير ضرورية' الأعلى مبيعًا هذا اليوم",
        description: "يتصدر اليوم كتاب 'إهانة غير ضرورية' الآعلى مبيعًا هذا اليوم من بين 100 كتاب، اذهب لمشاهدة القائمة.",
        creation_date: "06-02-2024"
    },
    {
        id: 4,
        title: "كتابك 'إهانة غير ضرورية' الأعلى مبيعًا هذا اليوم",
        description: "يتصدر اليوم كتاب 'إهانة غير ضرورية' الآعلى مبيعًا هذا اليوم من بين 100 كتاب، اذهب لمشاهدة القائمة.",
        creation_date: "06-02-2024"
    },
    {
        id: 5,
        title: "كتابك 'إهانة غير ضرورية' الأعلى مبيعًا هذا اليوم",
        description: "يتصدر اليوم كتاب 'إهانة غير ضرورية' الآعلى مبيعًا هذا اليوم من بين 100 كتاب، اذهب لمشاهدة القائمة.",
        creation_date: "06-02-2024"
    },
    {
        id: 6,
        title: "كتابك 'إهانة غير ضرورية' الأعلى مبيعًا هذا اليوم",
        description: "يتصدر اليوم كتاب 'إهانة غير ضرورية' الآعلى مبيعًا هذا اليوم من بين 100 كتاب، اذهب لمشاهدة القائمة.",
        creation_date: "06-02-2024"
    },
    {
        id: 7,
        title: "كتابك 'إهانة غير ضرورية' الأعلى مبيعًا هذا اليوم",
        description: "يتصدر اليوم كتاب 'إهانة غير ضرورية' الآعلى مبيعًا هذا اليوم من بين 100 كتاب، اذهب لمشاهدة القائمة.",
        creation_date: "06-02-2024"
    },
    {
        id: 8,
        title: "كتابك 'إهانة غير ضرورية' الأعلى مبيعًا هذا اليوم",
        description: "يتصدر اليوم كتاب 'إهانة غير ضرورية' الآعلى مبيعًا هذا اليوم من بين 100 كتاب، اذهب لمشاهدة القائمة.",
        creation_date: "06-02-2024"
    },
    {
        id: 9,
        title: "كتابك 'إهانة غير ضرورية' الأعلى مبيعًا هذا اليوم",
        description: "يتصدر اليوم كتاب 'إهانة غير ضرورية' الآعلى مبيعًا هذا اليوم من بين 100 كتاب، اذهب لمشاهدة القائمة.",
        creation_date: "06-02-2024"
    }
]

const Dashboard = () =>
{
    const { prefs, shouldShowBnr, animateOpacity } = useContext(SessionContext);
    const location = useLocation();
    const [shouldShowMenu, setShouldShowMenu] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const isLarge = useMediaQuery("(min-width: 1024px)");

    useLayoutEffect(() =>
    {
        if (document.getElementById("postLoginInnerContainer"))
        {
            document.getElementById("postLoginInnerContainer").scrollTo(0, 0);
        }
    }, [location.pathname]);

    useEffect(() =>
    {
        getNotifications();
    }, []);

    useEffect(() =>
    {
        if (isLarge)
        {
            setShouldShowMenu(false);
        }
    }, [isLarge]);

    useEffect(() =>
    {
        if (location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard` || location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/`)
        {
            window.location = `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/overview`;
        }
        else if (location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/trend` || location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/trend/`)
        {
            window.location = `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/trend/product`;
        }
        else if (location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/producer` || location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/producer/`)
        {
            window.location = `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/producer/product-list`;
        }
        else if (location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/distributor` || location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/distributor/`)
        {
            window.location = `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/distributor/outlet-list`;
        }
    }, [location.pathname]);

    const getNotifications = () =>
    {
        setNotifications(NOTIFICATIONS_LIST);
    }

    return (
        <div className="flex relative box-border p-grid-xs md:p-grid-sm">
            {!shouldShowMenu && <SubMenu />}
            <div id="postLoginInnerContainer" data-lenis-prevent className={`${shouldShowBnr ? "h-[calc(100vh-56px)] md:h-[calc(100vh-72px)]" : "h-[calc(100vh-16px)] md:h-[calc(100vh-32px)]"} w-full relative bg-neutral-high-lightest transition-colors duration-fast ease-in rounded-md lg:rounded-e-md lg:rounded-s-none overflow-y-scroll overflow-x-clip`}>
                <PostLoginHeader notifications={notifications} shouldShowMenu={shouldShowMenu} toggleMenu={() => setShouldShowMenu(!shouldShowMenu)}/>
                <Outlet/>
                {/* <div className="absolute top-0 start-0 h-full w-full bg-neutral-high-lightest z-50 rounded-xl" /> */}
                <AnimatePresence>
                    {animateOpacity &&
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className="absolute top-0 start-0 h-full w-full bg-neutral-high-lightest z-[70] rounded-xl"
                        />
                    }
                </AnimatePresence>
            </div>
        </div>
    );
};

export default Dashboard;