import React, { useContext, useRef } from "react";

import DateRange from "./dateRange";
import ShowAll from "./showAll";
import ChartContestantSmall from "./chartContestantSmall";

import { SessionContext } from "../../util/context";
import { getLangCountryURL, strings } from "../../util/formatter";
import useMediaQuery from "../../hook/useMediaQuery";
import book1 from "../../assets/image/books/book1.jpg"
import book2 from "../../assets/image/books/book2.jpg"
import book3 from "../../assets/image/books/book3.jpg"
import book4 from "../../assets/image/books/book4.jpg"
import book5 from "../../assets/image/books/book5.jpg"

const Section3 = () =>
{
    const { prefs } = useContext(SessionContext);
    const targetRef = useRef(null);
    const isSmall = useMediaQuery("(min-width: 640px) and (max-width: 767px)");
    const isMedium = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
    const bookList = [
        {
            id: 1,
            cover: book1,
            name: "شقة الحورية",
            contributor_name: "غازي القصيبي",
            rank: 1,
            rank_status: "up"
        },
        {
            id: 2,
            cover: book2,
            name: "جنوب الحدود غرب الشمس",
            contributor_name: "هاروكي موراكامي",
            rank: 2,
            rank_status: "star"
        },
        {
            id: 3,
            cover: book3,
            name: "ثلاثية غرناطة",
            contributor_name: "رضوى عاشور",
            rank: 3,
            rank_status: "down"
        },
        {
            id: 4,
            cover: book4,
            name: "قريب من البحر بعيد عن الزرقة",
            contributor_name: "جاسم الصحيح",
            rank: 4,
            rank_status: "up"
        },
        {
            id: 5,
            cover: book5,
            name: "مزرعة الحيوان",
            contributor_name: "جورج أورويل",
            rank: 5,
            rank_status: "same"
        }
    ];

    return (
        <div ref={targetRef} className={`relative mb-grid-xxl mx-grid-lg md:mx-0 flex flex-col justify-start`}>
            <p className="pb-grid-sm text-xl font-primary text-primary-lightest">
                {strings("landingPage.thirdSectionHead")}
            </p>
            <div className="flex justify-between items-start">
                <DateRange dateFrom={"2023-08-13"} dateTo={"2023-08-19"} />
                <ShowAll route={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/charts/product/all`}/>
            </div>
            <div className="mb-[10%] w-full bg-primary-lightest shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)] rounded-md">
                <div className="md:mx-0 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-grid-md p-grid-md justify-items-center md:justify-start lg:justify-center">
                    {bookList?.slice(0, isMedium ? 4 : isSmall ? 3 : 5)?.map((item, index) =>
                        (
                            <ChartContestantSmall
                                key={index}
                                item={item}
                                containerClassName="min-w-[176px] max-w-[176px] h-[344px] flex flex-col justify-between bg-primary-light text-secondary-darkest p-grid-sm cursor-pointer rounded-sm"
                                imgClassName="w-[144px] h-[190px] mx-auto"
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default Section3;