import React, { useContext, useRef } from "react";

import { Trans } from "react-i18next";
import { motion, useScroll, useTransform } from "framer-motion";

import InfoCard from "./infoCard";

import useMediaQuery from "../../hook/useMediaQuery";

import constant from "../../config/constant";
import { strings } from "../../util/formatter";
import { SessionContext } from "../../util/context";

import landingPageShape6 from "../../assets/image/landing_page_shape_6.webp";

const Section9 = () =>
{
    const { prefs, setUserPersona, setActiveModal, modalOptions, setModalOptions } = useContext(SessionContext);
    const isSmall = useMediaQuery("(max-width: 400px)");
    const isMedium = useMediaQuery("(max-width: 768px)");
    const isLarge = useMediaQuery("(max-width: 1024px)");
    const isXLarge = useMediaQuery("(max-width: 1280px)");
    const isXXLarge = useMediaQuery("(min-width: 1281px) and (max-width: 2000px)");
    const targetRef = useRef();
    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset: ["start start", "end end"]
    });
    const cards = [
        {
            iconName: "pieChart",
            title: strings("landingPage.ninthSectionFirstCardTitle"),
            description: strings("landingPage.ninthSectionFirstCardDescription"),
            withShadow: false
        },
        {
            iconName: "share",
            title: strings("landingPage.ninthSectionSecondCardTitle"),
            description: strings("landingPage.ninthSectionSecondCardDescription"),
            withShadow: false
        },
        {
            iconName: "lock",
            title: strings("landingPage.ninthSectionThirdCardTitle"),
            description: strings("landingPage.ninthSectionThirdCardDescription"),
            withShadow: false
        },
        {
            iconName: "lock",
            title: strings("landingPage.ninthSectionForthCardTitle"),
            description: strings("landingPage.ninthSectionForthCardDescription"),
            withShadow: false
        },
        {
            iconName: "lock",
            title: strings("landingPage.ninthSectionFifthCardTitle"),
            description: strings("landingPage.ninthSectionFifthCardDescription"),
            withShadow: false
        },
        {
            iconName: "lock",
            title: strings("landingPage.ninthSectionSixthCardTitle"),
            description: strings("landingPage.ninthSectionSixthCardDescription"),
            withShadow: false
        }
    ];

    const cardsTranslateX = useTransform(scrollYProgress, [0, 1], ["0%", `${prefs?.lang === constant.lang.values[0] ? "" : "-"}${isSmall ? "82%" : isMedium ? "107%" : isLarge ? "87%" : isXLarge ? "79%" : isXXLarge ? "75%" : "46%"}`]);

    return (
        <div className="relative pb-grid-xxl ps-grid-md md:ps-grid-xxl">
            <div ref={targetRef} className="w-auto h-[500vh] lg:h-[300vh]">
                <div className="sticky top-[27%] pe-grid-lg py-grid-xxl flex gap-grid-lg justify-start">
                    <motion.div style={{ translateX: cardsTranslateX }} className="flex gap-grid-lg justify-start">
                        <div className="min-w-[23%] max-w-[23%] md:min-w-[15%] md:max-w-[15%]">
                            <img src={landingPageShape6} className="w-[47%] z-10 mx-auto blur-[2px] mb-24"/>
                            <div className="relative z-10 pe-grid-xxl md:px-grid-sm text-xl font-primary text-secondary-darkest">
                                <Trans
                                    i18nKey="landingPage.ninthSectionHead"
                                    components={{ span: <span className="text-primary-regular" />, p: <p /> }}
                                />
                            </div>
                        </div>
                        {cards.map((item, index) => (
                            <InfoCard
                                key={index}
                                item={item}
                                buttonText={strings("button.joinAsProducer")}
                                buttonAction={() =>
                                {
                                    setActiveModal("signup");
                                    setUserPersona("producer");
                                    setModalOptions({
                                        ...modalOptions,
                                        title: strings("title.signup")
                                    });
                                }}
                            />
                        ))}
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default Section9;