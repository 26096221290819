import React, { useContext, useRef } from "react";

import { useNavigate } from "react-router-dom";
import { motion, useScroll, useTransform } from "framer-motion";
import { Trans } from "react-i18next";

import InfoCard from "./infoCard";

import { SessionContext } from "../../util/context";
import { getLangCountryURL, strings } from "../../util/formatter";

import landingPageShape1 from "../../assets/image/landing_page_shape_1.webp";
import landingPageShape7 from "../../assets/image/landing_page_shape_7.webp";

const Section7 = () =>
{
    const { prefs } = useContext(SessionContext);
    const navigate = useNavigate();
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset: ["start end", "end start"]
    });
    const textTranslateX = useTransform(scrollYProgress, [0.1, 0.3], [70, 0]);
    const textOpacity = useTransform(scrollYProgress, [0.1, 0.3], [0.5, 1]);
    const imagesScale = useTransform(scrollYProgress, [0, 1], [0.7, 1]);
    const cardsScale = useTransform(scrollYProgress, [0.3, 0.54], [0.7, 1]);
    const cards = [
        {
            iconName: "shop",
            title: strings("landingPage.seventhSectionFirstCardTitle"),
            description: strings("landingPage.seventhSectionFirstCardDescription"),
            withShadow: false
        },
        {
            iconName: "clock",
            title: strings("landingPage.seventhSectionSecondCardTitle"),
            description: strings("landingPage.seventhSectionSecondCardDescription"),
            withShadow: true
        },
        {
            iconName: "safe",
            title: strings("landingPage.seventhSectionThirdCardTitle"),
            description: strings("landingPage.seventhSectionThirdCardDescription"),
            withShadow: false
        }
    ];
    return (
        <div ref={targetRef} className="relative mx-grid-md lg:mx-0 pt-grid-xxl">
            <motion.img style={{ scale: imagesScale }} src={landingPageShape1} className="absolute top-grid-xl z-0 start-grid-xl"/>
            <motion.div style={{ opacity: textOpacity, translateX: textTranslateX }} className="relative z-10 pt-[30%] md:pt-grid-xxl text-xl font-primary text-secondary-darkest">
                <Trans
                    i18nKey="landingPage.seventhSectionHead"
                    components={{ span: <span className="text-primary-regular" />, p: <p /> }}
                />
            </motion.div>
            <motion.img style={{ scale: imagesScale }} src={landingPageShape7} className="w-[150px] md:w-[200px] absolute top-[250px] md:top-[130px] pb-grid-xxl z-0 end-0 lg:end-[100px] blur-[2px]"/>
            <motion.div style={{ scale: cardsScale }} className="pt-[60px] md:pt-[100px] pb-[80px] flex flex-wrap lg:flex-nowrap gap-grid-lg justify-center">
                {cards.map((item, index) => (
                    <InfoCard
                        key={index}
                        item={item}
                        buttonText={strings("button.readMore")}
                        buttonAction={() => navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/page/about`)}
                    />
                ))}
            </motion.div>
        </div>
    );
};

export default Section7;