import React, { createRef, useState } from "react";

import LineMsg from "./lineMsg";
import Label from "./label";
import Input from "./input";
import DropdownMenu from "./dropdownMenu";
import FileInput from "./fileInput";

import { strings } from "../../util/formatter";

export const DOCUMENT_TYPE = [
    {
        id: 1,
        name: "سجل تجاري"
    },
    {
        id: 2,
        name: "هوية وطنية"
    }
];

export const DOCUMENT_ISSUED_COUNTRY = [
    {
        id: 1,
        name: "المملكة العربية السعودية"
    }
];

const ManageEntityLegal = () =>
{
    const documentFileRef = createRef(null);
    const [documentType, setDocumentType] = useState("");
    const [documentNumber, setDocumentNumber] = useState("");
    const [documentIssuedCountry, setDocumentIssuedCountry] = useState("");
    const [documentFile, setDocumentFile] = useState({});

    const selectDocumentFile = () =>
    {
        if (documentFileRef.current !== null)
        {
            documentFileRef.current.click();
        }
    };

    return (
        <>
            <div className="flex flex-col sm:flex-row justify-between sm:gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.documentType")}/>
                    <DropdownMenu
                        list={DOCUMENT_TYPE}
                        labelKey="name"
                        placeholder={strings("placeholder.selectType")}
                        selectedValue={documentType}
                        setSelected={setDocumentType}
                    />
                    <LineMsg type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.documentNumber")}/>
                    <Input
                        name="documentNumber"
                        value={documentNumber}
                        setValue={setDocumentNumber}
                    />
                    <LineMsg type="error"/>
                </div>
            </div>
            <div className="w-1/2 pe-grid-md pb-grid-sm">
                <Label label={strings("label.documentIssuedCountry")}/>
                <DropdownMenu
                    list={DOCUMENT_ISSUED_COUNTRY}
                    labelKey="name"
                    placeholder={strings("placeholder.selectCountry")}
                    selectedValue={documentIssuedCountry}
                    setSelected={setDocumentIssuedCountry}
                />
                <LineMsg type="error"/>
            </div>
            <div className="pb-grid-sm">
                <Label label={strings("label.documentFile")}/>
                <FileInput
                    fileRef={documentFileRef}
                    value={documentFile}
                    setValue={setDocumentFile}
                    selectFile={selectDocumentFile}
                />
                <LineMsg type="error"/>
            </div>
        </>
    );
};

export default ManageEntityLegal;