import React, { useState } from "react";

import LineMsg from "./lineMsg";
import Label from "./label";
import Input from "./input";

import { strings } from "../../util/formatter";

const CreateDirectoryStep2 = () =>
{
    const [entityNameAR, setEntityNameAR] = useState("");
    const [entityNameEN, setEntityNameEN] = useState("");

    return (
        <>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.entityNameAR")}/>
                <Input
                    name="entityNameAR"
                    value={entityNameAR}
                    setValue={setEntityNameAR}
                />
                <LineMsg type="error"/>
            </div>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.entityNameEN")}/>
                <Input
                    name="entityNameEN"
                    value={entityNameEN}
                    setValue={setEntityNameEN}
                />
                <LineMsg type="error"/>
            </div>
        </>
    );
};

export default CreateDirectoryStep2;