/* eslint-disable no-constant-condition */
import React, { useContext, useEffect, useLayoutEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { HelmetProvider, Helmet } from "react-helmet-async";

import Header from "../component/header";
import Footer from "../component/footer";
import NoInternetConnection from "../component/noInternetConnection";

import { SessionContext } from "../../util/context";
import constant from "../../config/constant";
import checkNetworkConnection from "../../hook/checkNetworkConnection";
import DismissibleBanner from "../component/dismissibleBanner";

const Layout = () =>
{
    const { prefs, isPostLogin } = useContext(SessionContext);
    const location = useLocation();
    const isOnline = checkNetworkConnection();

    useLayoutEffect(() =>
    {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <div className="w-full font-secondary">
            <HelmetProvider>
                <Helmet>
                    <html lang={prefs?.lang} data-theme={prefs?.theme} dir={prefs?.dir}/>
                </Helmet>
                {(window.location.origin === "https://mofahres.com" || window.location.origin === "https://qa.mofahres.com" || window.location.origin === "https://preprod.mofahres.com")
                    ? <div className="w-full h-screen flex justify-center items-center">
                        <p dir="rtl" className="text-xl font-primary text-primary-lightest">
                            قريبا! ...
                        </p>
                    </div>
                    : <>
                        {isOnline
                        ? <>
                                {!isPostLogin
                                ? <>
                                        <Header />
                                        <div className="pt-[70px]">
                                            <Outlet/>
                                        </div>
                                        {!constant?.hide_footer?.some(path => location.pathname.includes(path)) &&
                                            <Footer />
                                        }
                                    </>
                                : <Outlet/>
                                }
                            </>
                        : <NoInternetConnection />
                        }
                    </>
                }
            </HelmetProvider>
        </div>
    );
};

export default Layout;