import React, { useContext } from "react";

import { Link } from "react-router-dom";

import Section from "../view/component/section";
import Avatar from "../view/component/avatar";
import CachedImg from "../view/component/cachedImg";

import constant from "../config/constant";
import { SessionContext } from "../util/context";
import { getDate, getLangCountryURL, strings } from "../util/formatter";

import eyadImage from "../assets/image/Eyad.jpg";

const Contributor = () =>
{
    const { prefs } = useContext(SessionContext);
    const ITEM = {
        id: 1,
        image: eyadImage,
        name: "إياد عبدالرحمن",
        country: "المملكة العربية السعودية",
        languages: [
            "العربية",
            "الانجليزية"
        ],
        categories: [
            "رواية",
            "رحلات",
            "تاريخ"
        ],
        products: [
            {
                id: 1,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                isbn: "798-098-09876",
                producers: [
                    {
                        name: "منشورات تكوين",
                        id: "Takween_Publishing"
                    }
                ],
                produced_at: "2023-9-24",
                languages: [
                    "العربية"
                ],
                pages_count: 382,
                categories: [
                    "رواية"
                ],
                types: [
                    "ورقي"
                ]
            },
            {
                id: 2,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                isbn: "798-098-09876",
                producers: [
                    {
                        name: "دار ميلاد للنشر والتوزيع",
                        id: "Melad_Publishing_and_Distribution_House"
                    }
                ],
                produced_at: "2022-9-24",
                languages: [
                    "العربية",
                    "الانجليزية"
                ],
                pages_count: 382,
                categories: [
                    "رواية",
                    "رحلات",
                    "تاريخ"
                ],
                types: [
                    "ورقي"
                ]
            },
            {
                id: 3,
                cover: "https://cdn.salla.sa/RnneE/sfEvzTfiw4bR6OURBrgmT8gu36SBzzcbnpUxUYt7.png",
                name: "قلب امرأة",
                isbn: "798-098-09876",
                producers: [
                    {
                        name: "دار ميلاد للنشر والتوزيع",
                        id: "Melad_Publishing_and_Distribution_House"
                    }
                ],
                produced_at: "2022-9-22",
                languages: [
                    "العربية",
                    "الانجليزية"
                ],
                pages_count: 382,
                categories: [
                    "رواية",
                    "رحلات",
                    "تاريخ"
                ],
                types: [
                    "ورقي"
                ]
            }
        ]
    };
    const ITEM_EN = {
        id: 1,
        image: eyadImage,
        name: "Eyad Abdulrahman",
        country: "Saudi Arabia",
        languages: [
            "Arabic",
            "English"
        ],
        categories: [
            "Novel",
            "Trips",
            "History"
        ],
        products: [
            {
                id: 1,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                isbn: "798-098-09876",
                producer_name: "Takween Publishing",
                producers: [
                    {
                        name: "Takween Publishing",
                        id: "Takween_Publishing"
                    }
                ],
                produced_at: "2023-9-22",
                languages: [
                    "Arabic"
                ],
                pages_count: 382,
                categories: [
                    "novel"
                ],
                types: [
                    "Paper"
                ]
            },
            {
                id: 2,
                cover: "https://cdn.salla.sa/RnneE/sfEvzTfiw4bR6OURBrgmT8gu36SBzzcbnpUxUYt7.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                isbn: "798-098-09876",
                producers: [
                    {
                        name: "Melad Publishing and Distribution House",
                        id: "Melad_Publishing_and_Distribution_House"
                    }
                ],
                produced_at: "2022-9-24",
                languages: [
                    "Arabic",
                    "English"
                ],
                pages_count: 382,
                categories: [
                    "novel",
                    "trips",
                    "history"
                ],
                types: [
                    "Paper"
                ]
            },
            {
                id: 3,
                cover: "https://cdn.salla.sa/RnneE/sfEvzTfiw4bR6OURBrgmT8gu36SBzzcbnpUxUYt7.png",
                name: "قلب امرأة",
                isbn: "798-098-09876",
                producers: [
                    {
                        name: "Melad Publishing and Distribution House",
                        id: "Melad_Publishing_and_Distribution_House"
                    }
                ],
                produced_at: "2022-9-22",
                languages: [
                    "Arabic",
                    "English"
                ],
                pages_count: 382,
                categories: [
                    "novel",
                    "trips",
                    "history"
                ],
                types: [
                    "Paper"
                ]
            }
        ]
    };

    const BasicContentList = (title, list, listEn, isLink = false, id = "", path = "") =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm text-base">
                <p className="w-[180px] font-primary">
                    {strings(title)}:
                </p>
                <div className="flex flex-col gap-grid-xs">
                    {list.map((item, index) => (
                        isLink
                            ? <Link key={index} to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${path}/${item?.[id]}`} className="underline underline-offset-2 text-primary-regular">
                                {prefs?.lang === constant.lang.values[0] ? list[index]?.name : listEn[index]?.name}
                            </Link>
                            : <p key={index}>
                                {prefs?.lang === constant.lang.values[0] ? list[index]?.name : listEn[index]?.name}
                            </p>
                    ))}
                </div>
            </div>
        );
    };

    const BasicContent = (title, item, itemEn) =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm text-base">
                <p className="w-[180px] font-primary">
                    {strings(title)}:
                </p>
                <p>
                    {prefs?.lang === constant.lang.values[0] ? item : itemEn}
                </p>
            </div>
        );
    };

    const ContentWithBG = (title, list, listEn) =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm text-base">
                <p className="w-[180px] font-primary text-start">
                    {strings(title)}:
                </p>
                <div className="flex flex-1 gap-grid-sm flex-wrap">
                    {list.map((item, index) => (
                        <p key={index} className="px-grid-md py-grid-xxs bg-primary-light text-secondary-darkest text-center rounded-xl">
                            {prefs?.lang === constant.lang.values[0] ? list[index] : listEn[index]}
                        </p>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <Section>
            <div className="relative pt-[60%] sm:pt-[40%] md:pt-[35%] lg:pt-[20%]">
                <p className="px-grid-lg md:px-0 md:container mx-auto text-xl font-primary text-primary-lightest">
                    {prefs?.lang === constant.lang.values[0] ? ITEM.name : ITEM_EN.name}
                </p>
                <div className="mx-grid-lg md:mx-auto md:container mt-grid-xl flex flex-col md:flex-row gap-grid-sm lg:gap-grid-lg items-center justify-start p-grid-sm lg:p-grid-lg bg-primary-lightest text-secondary-darkest text-lg font-secondary rounded-sm shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]">
                    <div className="w-auto">
                        <Avatar src={prefs?.lang === constant.lang.values[0] ? ITEM.image : ITEM_EN.image} size="w-48 h-48"/>
                    </div>
                    <div className="w-full md:w-[66.66%] font-secondary">
                        {BasicContent("common.country", ITEM.country, ITEM_EN.country)}
                        {ContentWithBG("common.languages", ITEM.languages, ITEM_EN.languages)}
                        {ContentWithBG("common.category", ITEM.categories, ITEM_EN.categories)}
                    </div>
                </div>
                <div className="mx-grid-lg md:mx-auto md:container mt-grid-xxl mb-grid-xxl">
                <p className="px-grid-lg md:px-0 md:container mx-auto text-xl font-primary text-primary-lightest">
                        {strings(`common.editions`)}
                    </p>
                    <div className="mt-grid-xl gap-grid-sm lg:gap-grid-lg items-start justify-start p-grid-sm lg:p-grid-lg bg-primary-lightest text-secondary-darkest text-lg font-secondary rounded-sm shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]">
                        {ITEM.products?.map((product, index) => (
                            <div key={index} className="flex gap-grid-sm md:gap-grid-xl">
                                <div className="w-[30px] md:w-[50px] relative px-grid-sm md:px-grid-lg border-e border-dashed border-secondary-darkest">
                                    <div className="absolute top-grid-xxs end-[-6px]">
                                        <div className="flex items-center gap-grid-xxs">
                                            <div className="font-primary text-secondary-darkest text-sm">
                                                {getDate(prefs?.lang, product.produced_at, "YYYY")}
                                            </div>
                                            <div className="w-[12px] h-[12px] rounded-full flex items-center justify-center bg-secondary-darkest">
                                                <div className="w-[5.5px] h-[5.5px] rounded-full bg-primary-lightest"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${index !== ITEM.products?.length - 1 && "pb-40"} flex flex-col md:flex-row gap-grid-sm lg:gap-grid-lg items-start justify-start`}>
                                    <div className="w-[200px]">
                                        <Link to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${ITEM.products[index]?.id}`}>
                                            <CachedImg src={prefs?.lang === constant.lang.values[0] ? ITEM.products[index]?.cover : ITEM_EN.products[index]?.cover} alt="" className="min-w-[200px] h-[300px] bg-primary-light"/>
                                        </Link>
                                    </div>
                                    <div className="w-full md:w-[66.66%] font-secondary text-base">
                                        <p className="font-primary text-xl">
                                            {prefs?.lang === constant.lang.values[0] ? ITEM.products[index]?.name : ITEM_EN.products[index]?.name}
                                        </p>
                                        {BasicContentList("common.theProducer", ITEM.products[index]?.producers, ITEM_EN.products[index]?.producers, true, "id", "producer")}
                                        {BasicContent("common.producedAt", getDate(prefs?.lang, ITEM.products[index]?.produced_at), getDate(prefs?.lang, ITEM_EN.products[index]?.produced_at))}
                                        {BasicContent("common.isbn", ITEM.products[index]?.isbn, ITEM_EN.products[index]?.isbn)}
                                        {ContentWithBG("common.languages", ITEM.products[index]?.languages, ITEM_EN.products[index]?.languages)}
                                        {BasicContent("common.pagesCount", ITEM.products[index]?.pages_count, ITEM_EN.products[index]?.pages_count)}
                                        {ContentWithBG("common.category", ITEM.products[index]?.categories, ITEM_EN.products[index]?.categories)}
                                        {ContentWithBG("common.type", ITEM.products[index]?.types, ITEM_EN.products[index]?.types)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default Contributor;