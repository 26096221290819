import React, { useContext } from "react";

import { Link } from "react-router-dom";

import Button from "./button";
import SVGIcon from "./svgIcons";
import LoadingSkeleton from "./loadingSkeleton";

import { SessionContext } from "../../util/context";
import { getDate, strings } from "../../util/formatter";
import useMediaQuery from "../../hook/useMediaQuery";

const Table = (props) =>
{
    const { prefs, isPostLogin } = useContext(SessionContext);
    const isSmall = useMediaQuery("(min-width: 640px)");
    const isMedium = useMediaQuery("(min-width: 768px)");
    const isLarge = useMediaQuery("(min-width: 1024px)");
    const isXLarge = useMediaQuery("(min-width: 1280px)");

    // eslint-disable-next-line brace-style
    const { loading = false, titles, tableData, columnType, onClickKeys = {}, onClickRow = () => {}, shouldLoadMore, loadMore, shouldShowArrow = false, pageNumber, maxPageNumber, responsive = undefined } = props;
    const filteredTitles = titles?.filter(title => !responsive ? title : isXLarge ? responsive?.maxXL?.includes(title) : isLarge ? responsive?.maxLG?.includes(title) : isMedium ? responsive?.maxMD?.includes(title) : isSmall ? responsive?.maxSM?.includes(title) : responsive?.maxXS?.includes(title));
    return (
        <>
            <div data-lenis-prevent className={isPostLogin ? "overflow-x-auto" : "overflow-x-auto shadow-[0_50px_50px_0_rgba(0,0,0,0.3)] rounded-sm"}>
                <table className={`${loading && "mb-1"} min-w-full`}>
                    <thead>
                        <tr className="font-primary text-md">
                            {filteredTitles?.map((title, index) => (
                                <th key={index} className={`py-grid-sm px-grid-xs text-base font-light text-center ${isPostLogin ? `border-b-4 border-b-neutral-high-lightest bg-neutral-high-dark text-neutral-low-darkest first:rounded-s-xs last:rounded-e-xs transition-colors duration-fast ease-in` : `bg-primary-dark text-primary-lightest ${index === 0 ? "rounded-ss-sm" : !shouldShowArrow && index === titles?.length - 1 ? "rounded-se-sm" : ""}`}`}>
                                    {strings(`common.${title}`)}
                                </th>
                            ))}
                            {shouldShowArrow &&
                                <th className="py-grid-sm px-grid-xs bg-primary-dark text-base font-light text-center text-primary-lightest rounded-se-sm" />
                            }
                        </tr>
                    </thead>
                    {
                        !loading && <tbody>
                            {tableData?.map((row, rowIndex) => (
                                <tr
                                    onClick={() => onClickRow(row)}
                                    key={rowIndex}
                                    className={isPostLogin ? "bg-neutral-high-regular transition-colors duration-fast ease-in" : `${(rowIndex % 2 === 0 ? "bg-primary-lightest " : "bg-primary-light")} hover:opacity-95 hover:transition-opacity hover:duration-fast hover:ease-in cursor-pointer`}
                                >
                                    {filteredTitles?.map((title, colIndex) =>
                                    {
                                        return (
                                            <td key={colIndex} className={`py-grid-sm px-grid-xs text-base text-center ${isPostLogin ? `${rowIndex !== tableData?.length - 1 && "border-b-4 border-b-neutral-high-lightest transition-colors duration-fast ease-in"} first:rounded-s-xs last:rounded-e-xs` : `${rowIndex === tableData.length - 1 && colIndex === 0 ? "rounded-es-sm" : rowIndex === tableData.length - 1 && colIndex === titles?.length - 1 && !shouldShowArrow ? "rounded-ee-sm" : ""}`}`}>
                                                {columnType?.link?.includes(title)
                                                    ? <Link to={row[title]} target="_blank" className={`underline underline-offset-1 cursor-pointer ${isPostLogin ? "text-neutral-low-darkest transition-colors duration-fast ease-in" : "text-secondary-darkest"}`}>
                                                        {strings("common.clickToDownload")}
                                                    </Link>
                                                    : <span
                                                        className={`${isPostLogin ? "text-neutral-low-darkest transition-colors duration-fast ease-in" : "text-secondary-darkest"} ${columnType?.clickable?.includes(title) && "underline underline-offset-1 cursor-pointer transition-colors hover:transition-opacity duration-fast ease-in hover:opacity-70"}`}
                                                        // eslint-disable-next-line brace-style
                                                        onClick={onClickKeys[title] === undefined ? () => {} : () => onClickKeys[title](row)}
                                                    >
                                                        {columnType?.date?.includes(title)
                                                            ? getDate(prefs?.lang, row[title])
                                                            : columnType?.dateTime?.includes(title)
                                                                ? getDate(prefs?.lang, row[title], "DD MMMM YYYY - hh:mm A", "YYYY-MM-DDTHH:mm:ss A Z", true)
                                                                : columnType?.price?.includes(title)
                                                                    ? `${row[title]} رس`
                                                                    : row[title]
                                                        }
                                                    </span>
                                                }
                                            </td>
                                        );
                                    })}
                                    {shouldShowArrow &&
                                        <td className={`py-grid-sm px-grid-md text-base ${rowIndex === tableData.length - 1 ? "rounded-ee-sm" : ""}`}>
                                            <span className="float-end">
                                                <SVGIcon name="chevron" viewBox="0 0 24 24" width={17} height={17} stroke="stroke-secondary-darkest" className={`${prefs?.dir === "rtl" ? "rotate-180" : ""} fill-none`}/>
                                            </span>
                                        </td>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    }
                </table>
                {loading && <LoadingSkeleton rows="8" rowHeight="h-[50px]" rowWidth = "w-full"/>}
            </div>
            {isPostLogin &&
                (
                    <div className="flex justify-between mt-grid-xxl mb-grid-sm">
                        <Button
                            text={strings("button.previous")}
                            bgColor={"bg-transparent"}
                            hoverBGColor={"hover:bg-neutral-low-darkest"}
                            textColor={"text-neutral-low-darkest"}
                            hoverTextColor={"hover:text-neutral-high-lightest"}
                            hoverBorder={"hover:border-2 hover:border-transparent"}
                            border={"border-2 border-neutral-low-darkest"}
                            padding="py-2 px-10"
                            disabled={pageNumber === 1}
                            shouldHover={pageNumber !== 1}
                            onClick={() => loadMore("previous")}
                        />
                        <Button
                            text={strings("button.next")}
                            bgColor={"bg-transparent"}
                            hoverBGColor={"hover:bg-neutral-low-darkest"}
                            textColor={"text-neutral-low-darkest"}
                            hoverTextColor={"hover:text-neutral-high-lightest"}
                            hoverBorder={"hover:border-2 hover:border-transparent"}
                            border={"border-2 border-neutral-low-darkest"}
                            padding="py-2 px-10"
                            disabled={pageNumber === maxPageNumber}
                            shouldHover={pageNumber !== maxPageNumber}
                            onClick={() => loadMore("next")}
                        />
                    </div>
                )}
            {shouldLoadMore && !isPostLogin &&
                (
                    <div className="text-center mt-grid-xxl mb-grid-sm">
                        <Button
                            text={strings("common.loadMore")}
                            padding="px-grid-xxl py-grid-xs"
                            bgColor="bg-secondary-darkest"
                            optionalStyle="border-2 border-primary-lightest"
                            font="text-md"
                            onClick={loadMore}
                        />
                    </div>
                )}
        </>
    );
};

export default Table;