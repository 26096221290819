import React, { memo } from "react";
import ICONS from "../../config/icon";

// eslint-disable-next-line react/display-name
const SVGIcon = memo(({ name, width = 24, height = 24, stroke = "currentColor", pathFill, className = "fill-none", viewBox = undefined }) =>
{
    const key = ICONS[name] || [];

    return (
        <svg width={width} height={height} viewBox={viewBox || `0 0 ${width} ${height}`} className={className} xmlns="http://www.w3.org/2000/svg">
            {key.map(({ path, ellipse, fill, stroke: ellipseStroke, strokeWidth, strokeLinecap, strokeLinejoin, fillRule, clipRule }, index) =>
            {
                return path
                    ? (
                        <path key={index} d={path} className={`transition-colors duration-fast ease-in ${stroke} ${fill && pathFill}`} strokeWidth={strokeWidth || "0"} strokeLinecap={strokeLinecap || "none"} strokeLinejoin={strokeLinejoin || "none"} fillRule={fillRule || "none"} clipRule={clipRule || "none"}/>
                    )
                    : (
                        <ellipse key={index} {...ellipse} fill={fill} stroke={ellipseStroke || stroke} />
                    );
            })}
        </svg>
    );
});

export default SVGIcon;