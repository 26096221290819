import React, { useContext, useState } from "react";

import Table from "../view/component/table";
import Button from "../view/component/button";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";

export const data = [
    { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" },
    { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" },
    { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" }
];

const ProducerProductList = () =>
{
    const { setActiveModal, setModalOptions } = useContext(SessionContext);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState(data);
    const maxPageNumber = 1;

    const loadMore = (type) =>
    {
        setIsLoading(true);
        const pageList = [
            { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" },
            { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" },
            { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" }
        ];
        if (type === "next")
        {
            setTimeout(() =>
            {
                setPageNumber(prevState => prevState + 1);
                setTableData(pageList);
                setIsLoading(false);
            }, 1500);
        }
        else
        {
            setTimeout(() =>
            {
                setPageNumber(prevState => prevState - 1);
                setTableData(data);
                setIsLoading(false);
            }, 1500);
        }
    };

    return (
        <div className="p-grid-md">
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-end gap-grid-md pt-grid-md pb-grid-lg">
                <p className="pb-grid-xs lg:pb-0 text-lg font-primary text-neutral-low-dark transition-colors duration-fast ease-in">
                    {strings("title.productList")}
                </p>
                <div className="flex self-end items-center gap-grid-sm">
                    <Button
                        text={strings("button.addProduct")}
                        font={"text-sm sm:text-md font-secondary"}
                        onClick={() =>
                        {
                            setActiveModal("addProduct");
                            setModalOptions({
                                showStepsBar: true,
                                currentStep: 1,
                                totalSteps: 5,
                                title: strings("title.addProduct")
                            });
                        }}
                    />
                    <Button
                        text={strings("button.addContributor")}
                        bgColor={"bg-neutral-low-darkest"}
                        hoverBGColor={"hover:bg-transparent"}
                        textColor={"text-neutral-high-lightest"}
                        hoverTextColor={"hover:text-neutral-low-darkest"}
                        hoverBorder={"hover:border-2 hover:border-neutral-low-darkest"}
                        border={"border-2 border-transparent"}
                        font={"text-sm sm:text-md font-secondary"}
                        onClick={() =>
                        {
                            setActiveModal("addContributor");
                            setModalOptions({
                                title: strings("title.addContributor")
                            });
                        }}
                    />
                </div>
            </div>
            <Table
                loading={isLoading}
                titles={tableData?.length > 0 ? Object.keys(tableData[0])?.filter(title => !["id"]?.includes(title)) : []}
                tableData={tableData?.filter(title => !["id"]?.includes(title))}
                responsive={
                    {
                        maxXL: ["standardNumber", "title", "price", "producedAt"],
                        maxLG: ["standardNumber", "title", "price", "producedAt"],
                        maxMD: ["standardNumber", "title", "price", "producedAt"],
                        maxSM: ["standardNumber", "title", "price"],
                        maxXS: ["standardNumber", "title"]
                    }
                }
                columnType={
                    {
                        date: ["producedAt"],
                        price: ["price"]
                    }
                }
                pageNumber={pageNumber}
                maxPageNumber={maxPageNumber}
                loadMore={loadMore}
            />
        </div>
    );
};

export default ProducerProductList;