import React, { useContext, useState } from "react";

import Table from "../view/component/table";
import Button from "../view/component/button";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";

export const data = [
    { id: 1, name: "روبين بلازا - الدائري الشمالي", region: "الرياض", country: "المملكة العربية السعودية" }
];

const DistributorOutletList = () =>
{
    const { setActiveModal, setModalOptions } = useContext(SessionContext);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState(data);
    const maxPageNumber = 1;

    const loadMore = (type) =>
    {
        setIsLoading(true);
        const pageList = [
            { id: 1, name: "روبين بلازا - الدائري الشمالي", region: "الرياض", country: "المملكة العربية السعودية" },
            { id: 1, name: "روبين بلازا - الدائري الشمالي", region: "الرياض", country: "المملكة العربية السعودية" },
            { id: 1, name: "روبين بلازا - الدائري الشمالي", region: "الرياض", country: "المملكة العربية السعودية" }
        ];
        if (type === "next")
        {
            setTimeout(() =>
            {
                setPageNumber(prevState => prevState + 1);
                setTableData(pageList);
                setIsLoading(false);
            }, 1500);
        }
        else
        {
            setTimeout(() =>
            {
                setPageNumber(prevState => prevState - 1);
                setTableData(data);
                setIsLoading(false);
            }, 1500);
        }
    };

    return (
        <div className="p-grid-md">
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-end gap-grid-md pt-grid-md pb-grid-lg">
                <p className="pb-grid-xs lg:pb-0 text-lg font-primary text-neutral-low-dark transition-colors duration-fast ease-in">
                    {strings("title.outletList")}
                </p>
                <div className="flex self-end gap-grid-xs">
                    <Button
                        text={strings("button.addNewOutlet")}
                        onClick={() =>
                        {
                            setActiveModal("newOutlet");
                            setModalOptions({
                                title: strings("title.newOutlet")
                            });
                        }}
                    />
                </div>
            </div>
            <Table
                loading={isLoading}
                titles={tableData?.length > 0 ? Object.keys(tableData[0])?.filter(title => !["id"]?.includes(title)) : []}
                tableData={tableData?.filter(title => !["id"]?.includes(title))}
                responsive={
                    {
                        maxXL: ["name", "region", "country"],
                        maxLG: ["name", "region", "country"],
                        maxMD: ["name", "region", "country"],
                        maxSM: ["name", "region", "country"],
                        maxXS: ["name", "region"]
                    }
                }
                pageNumber={pageNumber}
                maxPageNumber={maxPageNumber}
                loadMore={loadMore}
            />
        </div>
    );
};

export default DistributorOutletList;