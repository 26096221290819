import React, { useContext } from "react";

import Section from "../view/component/section";
import ProductCard from "../view/component/productCard";
import ShowAll from "../view/component/showAll";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";

import book1 from "../assets/image/books/book1.jpg"
import book2 from "../assets/image/books/book2.jpg"
import book3 from "../assets/image/books/book3.jpg"
import book4 from "../assets/image/books/book4.jpg"
import book5 from "../assets/image/books/book5.jpg"
import book6 from "../assets/image/books/book6.jpeg"
import book7 from "../assets/image/books/book7.jpg"
import book8 from "../assets/image/books/book8.jpg"

const NewArrival = () =>
{
    const { prefs } = useContext(SessionContext);
    const NEWLY_ITEMS = {
        novels: [
            {
                id: 1,
                cover: book1,
                name: "شقة الحورية",
                contributor_name: "غازي القصيبي"

            },
            {
                id: 2,
                cover: book2,
                name: "جنوب الحدود غرب الشمس",
                contributor_name: "هاروكي موراكامي"
            },
            {
                id: 3,
                cover: book3,
                name: "ثلاثية غرناطة",
                contributor_name: "رضوى عاشور"
            },
            {
                id: 4,
                cover: book4,
                name: "قريب من البحر بعيد عن الزرقة",
                contributor_name: "جاسم الصحيح"
            }
        ],
        poetry: [
            {
                id: 5,
                cover: book5,
                name: "مزرعة الحيوان",
                contributor_name: "جورج أورويل"

            },
            {
                id: 6,
                cover: book6,
                name: "السعودية المتغيرة",
                contributor_name: "شون فولي"
            },
            {
                id: 7,
                cover: book7,
                name: "الحرب و السلم",
                contributor_name: "ليف تولستوي"
            },
            {
                id: 8,
                cover: book8,
                name: "الحالة الحرجة للمدعو ك",
                contributor_name: "عزيز محمد"
            }
        ]
    };

    return (
        <Section useContainer={true}>
            <div className="relative pt-[60%] sm:pt-[40%] md:pt-[35%] lg:pt-[20%] pb-grid-xl md:px-0">
                {Object.keys(NEWLY_ITEMS)?.map((key, index) => (
                    <div key={index} dir={prefs?.dir}>
                        <div className="flex justify-between gap-grid-sm">
                            <p className="text-xl font-primary text-primary-lightest">
                                {strings(`common.${key}`)}
                            </p>
                            <ShowAll route={""}/>
                        </div>
                        <div className="flex justify-between gap-grid-sm">
                            {NEWLY_ITEMS[key]?.slice(0, 4)?.map((item, indexOfItem) => (
                                <ProductCard
                                    key={indexOfItem}
                                    item={item}
                                    containerClassName="min-w-[218px] min-h-[410px] max-w-[218px] max-h-[410px] mt-grid-md mb-[115px] p-grid-lg flex flex-col justify-between items-center bg-primary-lightest rounded-sm cursor-pointer shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]"
                                    imgClassName="w-[218px] h-[270px]"
                                    textClassName="pt-grid-xs text-center"
                                />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </Section>
    );
};

export default NewArrival;