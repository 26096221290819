import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import Section from "../view/component/section";
import Loader from "../view/component/loader";
import ChartContestantSmall from "../view/component/chartContestantSmall";

import { strings } from "../util/formatter";

const Search = () =>
{
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState([]);

    const SEARCH_RESULTS =
    [
        {
            id: 1,
            cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
            name: "إهانة غير ضرورية",
            contributor_name: "اسم المؤلف"
        },
        {
            id: 2,
            cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
            name: "اعرف لماذا يغرد الطائر الحبيس",
            contributor_name: "اسم المؤلف"
        },
        {
            id: 3,
            cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
            name: "إهانة غير ضرورية",
            contributor_name: "اسم المؤلف"
        },
        {
            id: 4,
            cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
            name: "اعرف لماذا يغرد الطائر الحبيس",
            contributor_name: "اسم المؤلف"
        },
        {
            id: 5,
            cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
            name: "إهانة غير ضرورية",
            contributor_name: "اسم المؤلف"
        },
        {
            id: 6,
            cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
            name: "اعرف لماذا يغرد الطائر الحبيس",
            contributor_name: "اسم المؤلف"
        },
        {
            id: 7,
            cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
            name: "إهانة غير ضرورية",
            contributor_name: "اسم المؤلف"
        },
        {
            id: 8,
            cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
            name: "اعرف لماذا يغرد الطائر الحبيس",
            contributor_name: "اسم المؤلف"
        },
        {
            id: 9,
            cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
            name: "إهانة غير ضرورية",
            contributor_name: "اسم المؤلف"
        },
        {
            id: 10,
            cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
            name: "اعرف لماذا يغرد الطائر الحبيس",
            contributor_name: "اسم المؤلف"
        },
        {
            id: 11,
            cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
            name: "إهانة غير ضرورية",
            contributor_name: "اسم المؤلف"
        },
        {
            id: 12,
            cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
            name: "اعرف لماذا يغرد الطائر الحبيس",
            contributor_name: "اسم المؤلف"
        },
        {
            id: 13,
            cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
            name: "إهانة غير ضرورية",
            contributor_name: "اسم المؤلف"
        },
        {
            id: 14,
            cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
            name: "اعرف لماذا يغرد الطائر الحبيس",
            contributor_name: "اسم المؤلف"
        },
        {
            id: 15,
            cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
            name: "إهانة غير ضرورية",
            contributor_name: "اسم المؤلف"
        },
        {
            id: 16,
            cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
            name: "اعرف لماذا يغرد الطائر الحبيس",
            contributor_name: "اسم المؤلف"
        },
        {
            id: 17,
            cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
            name: "إهانة غير ضرورية",
            contributor_name: "اسم المؤلف"
        },
        {
            id: 18,
            cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
            name: "اعرف لماذا يغرد الطائر الحبيس",
            contributor_name: "اسم المؤلف"
        },
        {
            id: 19,
            cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
            name: "إهانة غير ضرورية",
            contributor_name: "اسم المؤلف"
        },
        {
            id: 20,
            cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
            name: "اعرف لماذا يغرد الطائر الحبيس",
            contributor_name: "اسم المؤلف"
        }
    ];

    useEffect(() =>
    {
        setIsLoading(true);
        setTimeout(() =>
        {
            setResults(SEARCH_RESULTS);
            setIsLoading(false);
        }, 1500);
    }, []);

    useEffect(() =>
    {
        setResults([]);
        setIsLoading(true);
        setTimeout(() =>
        {
            setResults(SEARCH_RESULTS);
            setIsLoading(false);
        }, 1500);
    }, [searchParams.get("q")]);

    return (
        <Section>
            {isLoading && <Loader />}
            <div className="relative pt-[60%] sm:pt-[40%] md:pt-[35%] lg:pt-[20%]">
                <div className="mx-grid-lg md:mx-auto md:container my-grid-xxl">
                    <p className="text-xl font-primary text-primary-lightest">
                        {`${strings("common.searchResult")} (${results?.length})` }
                    </p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-grid-xl sm:gap-grid-sm md:gap-grid-xl py-grid-lg justify-items-center md:justify-start lg:justify-center">
                        {results?.map((product, index) => (
                            <ChartContestantSmall
                                key={index}
                                item={product}
                                containerClassName="min-h-[400px] max-w-[280px] max-h-[400px] p-grid-lg flex flex-col justify-between items-center bg-primary-lightest rounded-sm cursor-pointer shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]"
                                imgClassName="w-[150px] h-[210px]"
                                textClassName="pt-grid-xs text-center"
                            />
                        ))}
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default Search;