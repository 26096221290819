import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import CachedImg from "./cachedImg";
import Rank from "./rank";

import { SessionContext } from "../../util/context";
import { getLangCountryURL } from "../../util/formatter";

const ProductCard = (props) =>
{
    const { prefs } = useContext(SessionContext);
    const { isLastItem, lastItemRef } = props;
    const {
        item,
        containerClassName = "w-[190px] h-[270px] bg-primary-light text-secondary-darkest p-grid-md cursor-pointer rounded-md",
        imgClassName = "w-[70px] h-[115px] mx-auto",
        textClassName = "text-base text-center pt-grid-sm",
        secondTextClassName = "text-xs text-center pt-grid-sm",
        shouldScale = true
    } = props;
    return (
        <Link to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${item.id}`}>
            <motion.div ref={isLastItem ? lastItemRef : null} whileHover={{ scale: shouldScale ? 1.05 : 1 }} className={containerClassName}>
                <div>
                    <CachedImg src={item?.cover} className={imgClassName}/>
                    {item?.rank &&
                        <div className="py-2"><Rank rank={item?.rank} shouldShowRankText={true} rankStatus={item?.rank_status} /></div>
                    }
                </div>
                <p className={textClassName}>
                    {item?.name}
                </p>
                <p className={secondTextClassName}>
                    {item?.contributor_name}
                </p>
            </motion.div>
        </Link>
    );
};

export default ProductCard;