import React from 'react';

const LoadingSkeleton = (props) => {
    const {
        rows = "1",
        rowHeight = "h-[15px]",
        rowWidth = "w-full",
        align = "items-center"
    } = props;
    const skeletonRows = Array.from({ length: rows }, (_, rowIndex) => (
    <div key={rowIndex} className={`${rowHeight} ${rowWidth} ${rowIndex !== 0 && "mt-2"} bg-neutral-high-dark animate-pulse rounded-xs transition-colors duration-fast ease-in`}/>
    ));

    return (
    <div className={`w-full flex flex-col ${align}`}>
        {skeletonRows}
    </div>
    );
};

export default LoadingSkeleton;