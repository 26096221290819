import React, { useContext, useState } from "react";

import Label from "../view/component/label";
import DropdownMenu from "../view/component/dropdownMenu";
import LineMsg from "../view/component/lineMsg";
import Input from "../view/component/input";
import Textarea from "../view/component/textarea";
import Button from "../view/component/button";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";

export const TYPE = [
    {
        name: "متجر تجزئة",
        value: "branch"
    },
    {
        name: "متجر إلكتروني",
        value: "online_store"
    }
];

export const COUNTY = [
    {
        name: "المملكة العربية السعودية",
        value: "sa"
    },
    {
        name: "الإمارات العربية المتحدة",
        value: "ea"
    }
];

export const CITY = [
    {
        name: "الرياض",
        value: "riyadh"
    },
    {
        name: "جدة",
        value: "jeddah"
    }
];

const NewOutlet = () =>
{
    const { setActiveModal } = useContext(SessionContext);
    const [type, setType] = useState({});
    const [nameAR, setNameAR] = useState("");
    const [nameEN, setNameEN] = useState("");
    const [addressEN, setAddressEN] = useState("");
    const [addressAR, setAddressAR] = useState("");
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [country, setCountry] = useState({});
    const [city, setCity] = useState({});
    const [url, setURL] = useState("");

    const add = () =>
    {
        setActiveModal(undefined);
    };

    return (
        <>
            <div className="flex flex-col sm:flex-row justify-between sm:gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.nameAR")}/>
                    <Input
                        name="nameAR"
                        value={nameAR}
                        setValue={setNameAR}
                    />
                    <LineMsg type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.nameEN")}/>
                    <Input
                        name="nameEN"
                        value={nameEN}
                        setValue={setNameEN}
                    />
                    <LineMsg type="error"/>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-between sm:gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.addressAR")}/>
                    <Input
                        name="addressAR"
                        value={addressAR}
                        setValue={setAddressAR}
                    />
                    <LineMsg type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.addressEN")}/>
                    <Input
                        name="addressEN"
                        value={addressEN}
                        setValue={setAddressEN}
                    />
                    <LineMsg type="error"/>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-between sm:gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.longitude")} />
                    <Input
                        name="long"
                        value={longitude}
                        setValue={setLongitude} />
                    <LineMsg type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.latitude")} />
                    <Input
                        name="lat"
                        value={latitude}
                        setValue={setLatitude} />
                    <LineMsg type="error"/>
                </div>
            </div>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.country")} />
                <DropdownMenu
                    list={COUNTY}
                    labelKey="name"
                    placeholder={strings("placeholder.selectCountry")}
                    selectedValue={country}
                    setSelected={setCountry} />
                <LineMsg type="error"/>
            </div>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.city")} />
                <DropdownMenu
                    list={CITY}
                    labelKey="name"
                    placeholder={strings("placeholder.selectCity")}
                    selectedValue={city}
                    setSelected={setCity} />
                <LineMsg type="error"/>
            </div>
            <div className="text-end pt-grid-lg">
                <Button
                    text={strings("button.add")}
                    padding="py-2 px-grid-xl"
                    onClick={add}
                />
            </div>
        </>
    );
};

export default NewOutlet;