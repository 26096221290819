import React, { useState } from "react";

import dayjs from "dayjs";

import DateRange from "../view/component/dateRange";
import DataCardWithIcon from "../view/component/dataCardWithIcon";
import DataCardPercentage from "../view/component/dataCardPercentage";
import PieChartView from "../view/component/pieChartView";
import BarChartView from "../view/component/barChartView";
import LineChartView from "../view/component/lineChartView";
import DropdownMenu from "../view/component/dropdownMenu";
import DateRangeByFilter from "../view/component/dateRangeByFilter";

import { numberToNumberWithCommas, strings } from "../util/formatter";
import constant from "../config/constant";

export const barChartDataForCities = [
    {
        name: "الرياض",
        uv: 4000,
        pv: 2400,
        amt: 2400
    },
    {
        name: "جدة",
        uv: 3000,
        pv: 1398,
        amt: 2210
    },
    {
        name: "تبوك",
        uv: 2000,
        pv: 9800,
        amt: 2290
    },
    {
        name: "الدمام",
        uv: 2780,
        pv: 3908,
        amt: 2000
    },
    {
        name: "المدينة",
        uv: 1890,
        pv: 4800,
        amt: 2181
    },
    {
        name: "حائل",
        uv: 2390,
        pv: 3800,
        amt: 2500
    },
    {
        name: "ينبع",
        uv: 3490,
        pv: 4300,
        amt: 2100
    }
];

export const lineChartDataForCategories = [
    {
        name: "رواية",
        uv: 6000,
        pv: 2400,
        amt: 2400
    },
    {
        name: "تاريخ",
        uv: 5000,
        pv: 1398,
        amt: 2210
    },
    {
        name: "رعب",
        uv: 1000,
        pv: 9800,
        amt: 2290
    },
    {
        name: "جريمة",
        uv: 4780,
        pv: 3908,
        amt: 2000
    },
    {
        name: "حقائق",
        uv: 3890,
        pv: 4800,
        amt: 2181
    },
    {
        name: "شعر",
        uv: 1390,
        pv: 3800,
        amt: 2500
    },
    {
        name: "فانتازيا",
        uv: 490,
        pv: 4300,
        amt: 2100
    }
];

export const pieChartData = [
    { name: "رواية", value: 400 },
    { name: "تاريخ", value: 300 },
    { name: "رعب", value: 300 },
    { name: "جريمة", value: 102 },
    { name: "حقائق", value: 500 },
    { name: "شعر", value: 340 },
    { name: "فانتازيا", value: 100 },
    { name: "قصص", value: 200 },
    { name: "علوم", value: 420 }
];

const DATE_RANGE =
[
    {
        label: "اسبوع",
        value: "week"
    },
    {
        label: "شهر",
        value: "month"
    },
    {
        label: "ربع سنوي",
        value: "quarter"
    },
    {
        label: "سنة",
        value: "year"
    }
];

const Overview = () =>
{
    const [isLoading, setIsLoading] = useState(false);
    const [dateRangeValue, setDateRangeValue] = useState(DATE_RANGE?.[0]);
    const [date, setDate] = useState(dayjs().toDate());

    const onDateChange = (e) =>
    {
        setIsLoading(true);
        setDate(e)
        setTimeout(() => setIsLoading(false), 1500);
    };

    return (
        <div className="p-grid-md">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-grid-md pt-grid-md pb-grid-xl">
                <div className="text-neutral-low-dark transition-colors duration-fast ease-in">
                    <p className="pb-grid-xs text-md md:text-lg font-primary">
                        {strings("overview.welcome")}
                    </p>
                    <DateRange
                        loading={isLoading}
                        dateFrom="2023-08-13"
                        isPostLogin={true}
                        isFromOnly={true}
                        shouldShowTime={true}
                        time={"13:00:00"}
                    />
                </div>
                <div className="flex self-end items-center">
                    <DropdownMenu
                        list={DATE_RANGE}
                        labelKey="label"
                        selectedValue={dateRangeValue}
                        setSelected={setDateRangeValue}
                        gap="gap-grid-sm sm:gap-grid-xl"
                        wrapperStyle="me-grid-sm"
                    />
                    <DateRangeByFilter
                        isDisabled={isLoading}
                        showMonthYearPicker={dateRangeValue?.value === "month"}
                        showQuarterYearPicker={dateRangeValue?.value === "quarter"}
                        showWeekPicker={dateRangeValue?.value === "week"}
                        showYearPicker={dateRangeValue?.value === "year"}
                        showTimeSelect={false}
                        inputClassName="ps-grid-sm bg-neutral-high-regular hover:bg-transparent text-neutral-low-darkest border-2 border-transparent hover:border-neutral-low-darkest transition-colors duration-fast ease-in"
                        value={date}
                        onChange={onDateChange}
                    />
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-grid-md pb-grid-md">
                <DataCardWithIcon
                    loading={isLoading}
                    icon={"bookmark"}
                    iconViewBox="0 0 24 24"
                    label={strings("overview.copieSold")}
                    value={numberToNumberWithCommas("12876")}
                />
                <DataCardWithIcon
                    loading={isLoading}
                    icon={"circleDollar"}
                    iconViewBox="0 0 22 22"
                    label={strings("overview.totalSales")}
                    value={`${numberToNumberWithCommas("35987001.45")} رس`}
                />
                <DataCardWithIcon
                    loading={isLoading}
                    icon={"shop"}
                    iconViewBox="0 0 24 24"
                    label={strings("overview.participatingSellingCenters")}
                    value={numberToNumberWithCommas("3482")}
                />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-grid-md pb-grid-md">
                <DataCardPercentage
                    loading={isLoading}
                    number={"5%+"}
                    label={strings("overview.publicationsGrowth")}
                    description={strings("overview.publicationsIssued")}
                />
                <DataCardPercentage
                    loading={isLoading}
                    number={"9%-"}
                    label={strings("overview.authorsGrowth")}
                    description={strings("overview.authorsPublished")}
                />
                <DataCardPercentage
                    loading={isLoading}
                    number={"0%+"}
                    label={strings("overview.publishersGrowth")}
                    description={strings("overview.publishersPublished")}
                />
                <DataCardPercentage
                    loading={isLoading}
                    number={"400%+"}
                    label={strings("overview.sellingCentersGrowth")}
                    description={strings("overview.sellingCentersSold")}
                />
            </div>
            <div className="grid grid-rows-6 grid-flow-row md:grid-flow-col gap-grid-md pb-grid-md">
                <div className="p-grid-sm row-span-3 col-span-2 bg-neutral-high-regular text-neutral-low-dark transition-colors duration-fast ease-in text-center rounded-sm">
                    <p className="text-md font-primary pt-grid-sm pb-grid-lg">
                        {strings("overview.copiesSoldByRegions")}
                    </p>

                    <BarChartView
                        loading={isLoading}
                        data={barChartDataForCities}
                        labelKey="name"
                        valueKey="uv"
                    />
                </div>
                <div className="p-grid-sm row-span-3 col-span-2 bg-neutral-high-regular text-neutral-low-dark transition-colors duration-fast ease-in text-center rounded-sm">
                    <p className="text-md font-primary pt-grid-sm pb-grid-lg">
                        {strings("overview.totalSalesByRegion")}
                    </p>
                    <BarChartView
                        loading={isLoading}
                        data={barChartDataForCities}
                        labelKey="name"
                        valueKey="uv"
                    />
                </div>
                <div className="p-grid-sm md:auto-cols-auto col-span-2 md:col-span-1 row-span-6 md:row-span-full bg-neutral-high-regular text-neutral-low-dark transition-colors duration-fast ease-in text-center rounded-sm">
                    <p className="text-md font-primary pt-grid-sm pb-grid-lg">
                        {strings("overview.salesPerformanceByClassification")}
                    </p>
                    <PieChartView
                        loading={isLoading}
                        data={pieChartData}
                        labelKey="name"
                        valueKey="value"
                        colors={constant?.chartColors}
                    />
                </div>
            </div>
            <div className="p-grid-sm bg-neutral-high-regular text-neutral-low-dark transition-colors duration-fast ease-in text-center rounded-sm mb-grid-md">
                <p className="text-md font-primary pt-grid-sm">
                    {strings("overview.regionsOfMostGrowing")}
                </p>
                <p className="text-xs font-secondary pt-grid-sm pb-grid-lg">
                    {strings("overview.comparedToLastPeriod")}
                </p>
                <LineChartView
                    loading={isLoading}
                    data={barChartDataForCities}
                    labelKey="name"
                    valueKey="uv"
                />
            </div>
            <div className="p-grid-sm bg-neutral-high-regular text-neutral-low-dark transition-colors duration-fast ease-in text-center rounded-sm">
                <p className="text-md font-primary pt-grid-sm">
                    {strings("overview.mostGrowingCategories")}
                </p>
                <p className="text-xs font-secondary pt-grid-sm pb-grid-lg">
                    {strings("overview.comparedToLastPeriod")}
                </p>
                <LineChartView
                    loading={isLoading}
                    data={lineChartDataForCategories}
                    labelKey="name"
                    valueKey="uv"
                />
            </div>
        </div>
    );
};

export default Overview;