import React, { useContext } from "react";

import { Link } from "react-router-dom";

import SVGIcon from "./svgIcons";

import { SessionContext } from "../../util/context";
import { strings } from "../../util/formatter";

const ShowAll = ({ route = "" }) =>
{
    const { prefs } = useContext(SessionContext);
    return (
        <Link to={route} className="w-fit md:w-auto ms-auto mb-grid-sm md:mb-0 md:ms-0 flex items-center gap-grid-xs text-md flex-nowrap text-primary-lightest cursor-pointer hover:opacity-60 transition-opacity ease-linear duration-200">
            {strings("common.showAll")}
            <SVGIcon name="chevron" viewBox="0 0 24 24" width={17} height={17} stroke="stroke-primary-lightest" className={`${prefs?.dir === "rtl" ? "rotate-180" : ""} fill-none`}/>
        </Link>
    );
};

export default ShowAll;