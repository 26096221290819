import React, { useContext, useState } from "react";

import Label from "../view/component/label";
import LineMsg from "../view/component/lineMsg";
import Button from "../view/component/button";
import DropdownMenu from "../view/component/dropdownMenu";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";

export const REASONS = [
    {
        id: 1,
        label: "تم إقفال المنشأة"
    },
    {
        id: 2,
        label: "تعطيل مؤقت"
    },
    {
        id: 3,
        label: "اخرى"
    }
];

const DisableEntityAccount = () =>
{
    const { setActiveModal, setModalOptions } = useContext(SessionContext);
    const [reason, setReason] = useState({});

    const goBack = () =>
    {
        setActiveModal("manageEntity");
        setModalOptions({
            title: strings("common.manageEntity"),
            containerPadding: "p-0"
        });
    };

    const disbaleAccount = () =>
    {
        setActiveModal(undefined);
    };

    return (
        <>
            <p className="text-md mb-grid-sm">
                {strings("common.disableEntityAccountMsg")}
            </p>
            <div className="h-[1px] bg-primary-light mt-grid-md mb-grid-xl"/>
            <div className="w-full py-grid-sm">
                <Label label={strings("label.disableAccountReason")}/>
                <DropdownMenu
                    list={REASONS}
                    labelKey="label"
                    placeholder={strings("placeholder.selectDisbaleReason")}
                    selectedValue={reason}
                    setSelected={setReason}
                />
                <LineMsg type="error"/>
            </div>
            <div className="flex justify-between pt-grid-lg">
                <Button
                    text={strings("button.goBack")}
                    bgColor="bg-secondary-darkest"
                    padding="py-2 px-grid-xl"
                    onClick={goBack}
                />
                <Button
                    text={strings("button.disbaleAccount")}
                    bgColor="bg-secondary-darkest"
                    padding="py-2 px-grid-xl"
                    onClick={disbaleAccount}
                />
            </div>
        </>
    );
};

export default DisableEntityAccount;