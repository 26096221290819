import React, { useContext } from "react";
import {
    Routes,
    Route,
    useLocation
} from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";

import Layout from "./view/layout/layout.js";

import Modal from "./view/component/modal.js";

import LandingPage from "./controller/landingPage.js";
import NewArrival from "./controller/newArrival.js";
import Directory from "./controller/directory.js";
import Charts from "./controller/charts.js";
import Product from "./controller/product.js";
import Contributor from "./controller/contributor.js";
import Producer from "./controller/producer.js";
import Seller from "./controller/seller.js";
import Catalog from "./controller/catalog.js";
import StaticPage from "./controller/staticPage.js";
import Search from "./controller/search.js";

import Dashboard from "./controller/dashboard.js";
import Overview from "./controller/overview.js";
import HeatMap from "./controller/heatMap.js";
import TrendProduct from "./controller/trendProduct.js";
import TrendContributor from "./controller/trendContributor.js";
import TrendProducer from "./controller/trendProducer.js";
import TrendDistributor from "./controller/trendDistributor.js";
import TrendOutlet from "./controller/trendOutlet.js";
import TrendRegion from "./controller/trendRegion.js";
import TrendCategory from "./controller/trendCategory.js";
import TrendAge from "./controller/trendAge.js";
import ProducerProductList from "./controller/producerProductList.js";
import ProducerProductDownload from "./controller/producerProductDownload.js";
import ProducerProductSharing from "./controller/producerProductSharing.js";
import DistributorOutletList from "./controller/distributorOutletList.js";
import DistributorReportList from "./controller/distributorReportList.js";
import DismissibleBanner from "./view/component/dismissibleBanner.js";

import ErrorHandler from "./controller/errorHandler.js";
import ErrorPage from "./controller/errorPage.js";

import { SessionContext } from "./util/context";

const App = () =>
{
    const location = useLocation();
    const { prefs, isPostLogin } = useContext(SessionContext);

    return (
        <>
            {isPostLogin && <DismissibleBanner />}
            <ReactNotifications />
            <Routes location={location}>
                <Route path={"/"} element={<Layout />}>
                    <Route path={`${prefs?.lang}-${prefs?.country_code}`}>
                        {(window.location.origin !== "https://mofahres.com" || window.location.origin !== "https://qa.mofahres.com" || window.location.origin !== "https://preprod.mofahres.com") &&
                            <>
                                <Route index element={<LandingPage />} />
                                <Route path={"new-arrival"} element={<NewArrival />} />
                                <Route path={"directory"} element={<Directory />} />
                                <Route path={"charts/:type/:category?"} element={<Charts />} />
                                <Route path={"product/:id"} element={<Product />} />
                                <Route path={"producer/:id"} element={<Producer />} />
                                <Route path={"contributor/:id"} element={<Contributor />} />
                                <Route path={"seller/:id"} element={<Seller />} />
                                <Route path={"catalog/:filterType/:filterValue?"} element={<Catalog />} />
                                <Route path={"page/:id"} element={<StaticPage />} />
                                <Route path={"search"} element={<Search />} />
                                {true &&
                                    <Route path={"dashboard"} element={<Dashboard/>}>
                                        <Route index element={<Overview />} />
                                        <Route path={"overview"} element={<Overview />} />
                                        <Route path={"heatmap"} element={<HeatMap />} />
                                        <Route path={"trend"}>
                                            <Route index element={<TrendProduct />} />
                                            <Route path={"product"} element={<TrendProduct />} />
                                            <Route path={"contributor"} element={<TrendContributor />} />
                                            <Route path={"producer"} element={<TrendProducer />} />
                                            <Route path={"distributor"} element={<TrendDistributor />} />
                                            <Route path={"outlet"} element={<TrendOutlet />} />
                                            <Route path={"region"} element={<TrendRegion />} />
                                            <Route path={"category"} element={<TrendCategory />} />
                                            <Route path={"age"} element={<TrendAge />} />
                                        </Route>
                                        <Route path={"producer"}>
                                            <Route index element={<ProducerProductList />} />
                                            <Route path={"product-list"} element={<ProducerProductList />} />
                                            <Route path={"product-download"} element={<ProducerProductDownload />} />
                                            <Route path={"product-sharing"} element={<ProducerProductSharing />} />
                                        </Route>
                                        <Route path={"distributor"}>
                                            <Route index element={<DistributorOutletList />} />
                                            <Route path={"outlet-list"} element={<DistributorOutletList />} />
                                            <Route path={"report-list"} element={<DistributorReportList />} />
                                        </Route>
                                        <Route path={"*"} element={<ErrorHandler />} />
                                    </Route>
                                }
                                <Route path={"error/:type"} element={<ErrorPage />} />
                                <Route path={"*"} element={<ErrorHandler />} />
                            </>
                        }
                    </Route>
                    <Route path={"*"} element={<ErrorHandler />} />
                </Route>
            </Routes>
            <Modal/>
        </>
    );
};

export default App;