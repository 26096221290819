import React, { Fragment, useContext, useState } from "react";

import LineMsg from "./lineMsg";
import Label from "./label";
import DropdownMenu from "./dropdownMenu";

import { strings } from "../../util/formatter";
import { SessionContext } from "../../util/context";

const ACCOUNT_TYPE =
[
    {
        key: "producer",
        label: strings("common.producer")
    },
    {
        key: "distributor",
        label: strings("common.distributor")
    },
    {
        key: "sponsor",
        label: strings("common.sponsor")
    }
];

const ACCOUNT_TYPE_DESCREPTION =
{
    producer: [
        strings("common.producerDesc"),
        strings("common.signupCaution")
    ],
    distributor: [
        strings("common.distributorDesc"),
        strings("common.signupCaution")
    ],
    sponsor: [
        strings("common.sponsorDesc"),
        strings("common.signupCaution")
    ]
};

const CreateDirectoryStep1 = () =>
{
    const { userPersona, setUserPersona } = useContext(SessionContext);
    const [accountType, setAccountType] = useState(ACCOUNT_TYPE?.filter(item => item.key === userPersona)?.[0] ? ACCOUNT_TYPE?.filter(item => item.key === userPersona)?.[0] : {});

    const onSelectAccountType = (type) =>
    {
        setUserPersona(type);
        setAccountType(type);
    };

    return (
        <>
            <div className="w-full">
                <Label label={strings("label.accountType")}/>
                <DropdownMenu
                    list={ACCOUNT_TYPE}
                    labelKey="label"
                    placeholder={strings("placeholder.accountType")}
                    selectedValue={accountType}
                    setSelected={onSelectAccountType}
                />
                <LineMsg type="error"/>
            </div>
            <div className="text-secondary-darkest">
                {ACCOUNT_TYPE_DESCREPTION[accountType?.key]?.map((item, index) =>
                    (
                        <div key={index} className="flex justify-start items-start">
                            <span className="text-[25px] pe-2">&#8226;</span>
                            <span className="pt-[7px]">{item}</span>
                        </div>
                    ))}
            </div>
        </>
    );
};

export default CreateDirectoryStep1;