import React, { createRef, useContext, useState } from "react";

import Label from "../view/component/label";
import DropdownMenu from "../view/component/dropdownMenu";
import LineMsg from "../view/component/lineMsg";
import Input from "../view/component/input";
import Button from "../view/component/button";
import FileInput from "../view/component/fileInput";
import Tabs from "../view/component/tabs";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";

export const BRANCH = [
    {
        name: "روبين",
        value: "robeen"
    },
    {
        name: "الروضة",
        value: "rawdah"
    }
];

const UploadReport = () =>
{
    const { isPostLogin, setActiveModal } = useContext(SessionContext);
    const reportRef = createRef(null);
    const [active, setActive] = useState("uploadFile");
    const [report, setReport] = useState({});
    const [ISBN, setISBN] = useState("");
    const [quantity, setQuantity] = useState("");
    const [branch, setBranch] = useState({});

    const selectReport = () =>
    {
        if (reportRef.current !== null)
        {
            reportRef.current.click();
        }
    };

    const save = () =>
    {
        setActiveModal(undefined);
    };

    return (
        <>
            <Tabs
                active={active}
                setActive={setActive}
                isModal={true}
                list={[
                    { key: "uploadFile", label: strings("common.uploadFile") },
                    { key: "manualEnterance", label: strings("common.manualEnterance") }
                ]}
                shouldShowAll={false}
            >
                <div className={`p-grid-md ${isPostLogin ? "text-neutral-low-darkest" : "text-secondary-darkest"} font-secondary`}>
                    {active === "uploadFile"
                        ? <div className="pb-grid-sm">
                                <Label label={strings("label.fileToUpload")}/>
                                <FileInput
                                    fileRef={reportRef}
                                    value={report}
                                    setValue={setReport}
                                    selectFile={selectReport}
                                />
                                <LineMsg type="error"/>
                            </div>
                        : <>
                            <div className="w-full pb-grid-sm">
                                <Label label={strings("label.isbn")}/>
                                <Input
                                    name="ISBN"
                                    value={ISBN}
                                    setValue={setISBN}
                                />
                                <LineMsg type="error"/>
                            </div>
                            <div className="w-full pb-grid-sm">
                                <Label label={strings("label.branch")}/>
                                <DropdownMenu
                                    list={BRANCH}
                                    labelKey="name"
                                    placeholder={strings("placeholder.selectBranch")}
                                    selectedValue={branch}
                                    setSelected={setBranch}
                                />
                                <LineMsg type="error"/>
                            </div>
                            <div className="w-1/2 pb-grid-sm">
                                <Label label={strings("label.quantitySold")}/>
                                <Input
                                    name="quantity"
                                    value={quantity}
                                    setValue={setQuantity}
                                />
                                <LineMsg type="error"/>
                            </div>
                        </>
                    }
                </div>
            </Tabs>
            <div className="flex justify-between pt-grid-lg px-grid-md gap-grid-md pb-grid-md">
                <Button
                    text={strings("button.downloadXLSSample")}
                    bgColor={"bg-transparent"}
                    hoverBGColor={"hover:bg-neutral-low-darkest"}
                    textColor={"text-neutral-low-darkest"}
                    hoverTextColor={"hover:text-neutral-high-lightest"}
                    hoverBorder={"hover:border-2 hover:border-transparent"}
                    border={"border-2 border-neutral-low-darkest"}
                    font="text-sm"
                    padding = "py-2 px-8"
                    onClick={() => {}}
                />
                <Button
                    text={strings("button.save")}
                    padding="py-2 px-grid-xl"
                    onClick={save}
                />
            </div>
        </>
    );
};

export default UploadReport;