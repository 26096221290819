import React, { useContext, useEffect, useState } from "react";
import { GoogleMap, HeatmapLayer, useLoadScript } from "@react-google-maps/api";
import { SessionContext } from "../../util/context";
import constant from "../../config/constant";

const HeatMapView = (props) =>
{
    const { prefs } = useContext(SessionContext);
    const [heatmapData, setHeatmapData] = useState([]);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyDJdIv4cG_-0L7t8zoPE-44ytMTddrQAPk",
        libraries: props?.libraries
    });

    const mapContainerStyle = {
        position: "relative",
        height: "calc(100vh - 15.9rem)",
        width: "100%"
    };

    const defaultMapOptions =
    {
        disableDefaultUI: true,
        clickableIcons: false,
        ...(prefs?.theme === "dark" ? { styles: constant?.map_theme?.dark } : {})
    };

    const center = {
        lat: 24.7136,
        lng: 46.6753
    };

    const heatmapOptions = {
        radius: 50,
        dissapating: false
    };

    const generateRandomCoordinatesInSaudiArabia = (numPoints) =>
    {
        const coordinates = [];
        const riyadhLatitude = 24.7136;
        const riyadhLongitude = 46.6753;

        const minLatitude = riyadhLatitude - 0.02;
        const maxLatitude = riyadhLatitude + 0.16;
        const minLongitude = riyadhLongitude - 0.04;
        const maxLongitude = riyadhLongitude + 0.24;

        for (let i = 0; i < numPoints; i++)
        {
            const latitude = minLatitude + Math.random() * (maxLatitude - minLatitude);
            const longitude = minLongitude + Math.random() * (maxLongitude - minLongitude);
            const randomIntensity = Math.random() * 100;
            coordinates.push({ location: new window.google.maps.LatLng(latitude, longitude), w: randomIntensity });
        }
        return coordinates;
    };

    useEffect(() =>
    {
        if (isLoaded)
        {
            setHeatmapData(generateRandomCoordinatesInSaudiArabia(300));
        }
    }, [isLoaded]);

    if (loadError)
    {
        return <div/>;
    }

    return (
        isLoaded
            ? (
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={13}
                    center={center}
                    options={defaultMapOptions}
                >
                    {isLoaded && (
                        <HeatmapLayer
                            data={heatmapData}
                            options={heatmapOptions}
                        />
                    )}
                </GoogleMap>

            )
            : (
                <div>Loading ...</div>
            )
    );
};

export default HeatMapView;