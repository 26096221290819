import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "../locale/en/translation.json";
import ar from "../locale/ar/translation.json";

// the translations
const resources = {
    en: {
        translation: en
    },
    ar: {
        translation: ar
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: !localStorage.getItem("lang") ? "ar" : localStorage.getItem("lang"),

        keySeparator: ".", // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;