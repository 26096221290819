import React, { useEffect, useContext } from "react";

import { useNavigate, useParams } from "react-router-dom";

import Section from "../view/component/section";
import Button from "../view/component/button";

import { SessionContext } from "../util/context";
import { getLangCountryURL, strings } from "../util/formatter";

const ErrorPage = () =>
{
    const { prefs, lenis } = useContext(SessionContext);
    const params = useParams();
    const navigate = useNavigate();
    const onClickBack = () =>
    {
        navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}`);
    };

    useEffect(() =>
    {
        setTimeout(() => {
            lenis.stop();
        }, 10)
        return () => {
            lenis.start();
        }
    }, []);

    return (
        <Section>
            <div className="h-screen flex flex-col gap-grid-md justify-center items-center text-primary-lightest">
                <p className="text-lg font-primary">
                    {params?.type === "500" ? strings("title.pageNotFound") : strings("title.pageNotFound")}
                </p>
                <p className="text-md font-secondary">
                    {params?.type === "500" ? strings("message.pageNotFound") : strings("message.pageNotFound")}
                </p>
                <Button
                    text={strings("button.backToHomePage")}
                    bgColor={"bg-accent-regular"}
                    hoverBGColor={"hover:bg-transparent"}
                    textColor={"text-accent-lightest"}
                    hoverTextColor={"hover:text-accent-regular"}
                    border={"border-2 border-transparent"}
                    hoverBorder={"hover:border-2 hover:border-accent-regular"}
                    padding={"py-2 px-8"}
                    onClick={onClickBack}
                />
            </div>
        </Section>
    );
};

export default ErrorPage;