import React, { useState } from "react";

import LineMsg from "./lineMsg";
import Label from "./label";
import DropdownMenu from "./dropdownMenu";

import { strings } from "../../util/formatter";
import DatePickerButton from "./datePickerButton";

export const PRODUCER = [
    {
        label: "دار ميلاد للنشر والتوزيع",
        value: "melad"
    },
    {
        label: "دار تكوين للنشر والتوزيع",
        value: "takween"
    }
];

export const AUDIENCE_GROUP = [
    {
        label: "أطفال",
        value: "kids"
    },
    {
        label: "بالغين",
        value: "adults"
    }
];

export const PRODUCING_STATUS = [
    {
        label: "متوفر",
        value: "available"
    },
    {
        label: "غير متوفر",
        value: "unavailable"
    }
];

export const DISTRIBUTING_STATUS = [
    {
        label: "متوفر",
        value: "available"
    },
    {
        label: "غير متوفر",
        value: "unavailable"
    }
];

const AddProductStep5 = () =>
{
    const [producer, setProducer] = useState({});
    const [producedAtDate, setProducedAtDate] = useState(null);
    const [producingStatus, setProducingStatus] = useState({});
    const [distributingStatus, setDistributingStatus] = useState({});

    return (
        <>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.producer")}/>
                <DropdownMenu
                    list={PRODUCER}
                    labelKey="label"
                    placeholder={strings("placeholder.selectProducer")}
                    selectedValue={producer}
                    setSelected={setProducer}
                />
                <LineMsg type="error"/>
            </div>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.producedAt")}/>
                <DatePickerButton
                    value={producedAtDate}
                    setValue={setProducedAtDate}
                />
                <LineMsg type="error"/>
            </div>
            <div className="flex flex-col sm:flex-row justify-between sm:gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.producingStatus")}/>
                    <DropdownMenu
                        list={PRODUCING_STATUS}
                        labelKey="label"
                        placeholder={strings("placeholder.selectProductType")}
                        selectedValue={producingStatus}
                        setSelected={setProducingStatus}
                    />
                    <LineMsg type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.distributingStatus")}/>
                    <DropdownMenu
                        list={DISTRIBUTING_STATUS}
                        labelKey="label"
                        placeholder={strings("placeholder.selectAudienceGroup")}
                        selectedValue={distributingStatus}
                        setSelected={setDistributingStatus}
                    />
                    <LineMsg type="error"/>
                </div>
            </div>
        </>
    );
};

export default AddProductStep5;