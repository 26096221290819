import React, { useContext, useState } from "react";

import Tabs from "../view/component/tabs";
import ListOfSales from "../view/component/listOfSales";
import Avatar from "../view/component/avatar";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";
import constant from "../config/constant";

export const countries =
[
    {
        name: "السعودية",
        value: "saudi"
    },
    {
        name: "الامارات",
        value: "emirates"
    }
];

const ITEM = {
    id: 1,
    logo: "https://cdn.salla.sa/RnneE/gjrIBT01nmvWYhwJV9yjTLn8SzmWawTKM5GWT1pv.png",
    name: "دار ميلاد للنشر والتوزيع",
    commercial_register: "101097537535",
    country: "المملكة العربية السعودية",
    languages: [
        "العربية",
        "الانجليزية"
    ],
    categories: [
        "رواية",
        "رحلات",
        "تاريخ"
    ],
    products: [
        {
            id: 1,
            cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
            name: "إهانة غير ضرورية",
            isbn: "798-098-09876"
        },
        {
            id: 2,
            cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
            name: "اعرف لماذا يغرد الطائر الحبيس",
            isbn: "798-098-09876"
        },
        {
            id: 3,
            cover: "https://cdn.salla.sa/RnneE/sfEvzTfiw4bR6OURBrgmT8gu36SBzzcbnpUxUYt7.png",
            name: "قلب امرأة",
            isbn: "798-098-09876"
        }
    ],
    sales_by_distributor: {
        total_sales: 165,
        list: [
            {
                id: 1,
                name: "الرياض",
                sales: 55
            },
            {
                id: 2,
                name: "جدة",
                sales: 55
            },
            {
                id: 3,
                name: "الدمام",
                sales: 55
            }
        ]
    },
    sales_by_region: {
        total_sales: 165,
        list: [
            {
                id: 1,
                name: "الرياض",
                sales: 55
            },
            {
                id: 2,
                name: "جدة",
                sales: 55
            },
            {
                id: 3,
                name: "الدمام",
                sales: 55
            }
        ]
    }
};

const ITEM_EN = {
    id: 1,
    logo: "https://cdn.salla.sa/RnneE/gjrIBT01nmvWYhwJV9yjTLn8SzmWawTKM5GWT1pv.png",
    name: "Melad Publishing and Distribution House",
    commercial_register: "101097537535",
    country: "Saudi Arabia",
    languages: [
        "Arabic",
        "English"
    ],
    categories: [
        "Novel",
        "Trips",
        "History"
    ],
    products: [
        {
            id: 1,
            cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
            name: "إهانة غير ضرورية",
            isbn: "798-098-09876"
        },
        {
            id: 2,
            cover: "https://cdn.salla.sa/RnneE/sfEvzTfiw4bR6OURBrgmT8gu36SBzzcbnpUxUYt7.png",
            name: "اعرف لماذا يغرد الطائر الحبيس",
            isbn: "798-098-09876"
        },
        {
            id: 3,
            cover: "https://cdn.salla.sa/RnneE/sfEvzTfiw4bR6OURBrgmT8gu36SBzzcbnpUxUYt7.png",
            name: "قلب امرأة",
            isbn: "798-098-09876"
        }
    ],
    sales_by_distributor: {
        total_sales: 165,
        list: [
            {
                id: 1,
                name: "Riyadh",
                sales: 55
            },
            {
                id: 2,
                name: "Jeddah",
                sales: 55
            },
            {
                id: 3,
                name: "Dammam",
                sales: 55
            }
        ]
    },
    sales_by_region: {
        total_sales: 165,
        list: [
            {
                id: 1,
                name: "Riyadh",

                sales: 55
            },
            {
                id: 2,
                name: "Jeddah",
                sales: 55
            },
            {
                id: 3,
                name: "Dammam",
                sales: 55
            }
        ]
    }
};

const ProducerModal = () =>
{
    const { prefs, isPostLogin } = useContext(SessionContext);
    const [active, setActive] = useState("details");
    const [selected, setSelected] = useState(countries[0]);
    const [date, setDate] = useState(Date.now());

    const BasicContent = (title, item, itemEn) =>
    {
        return (
            <div className="grid grid-flow-col grid-cols-[max-content] gap-grid-xs pt-grid-sm">
                <p className="w-[130px] font-primary">
                    {strings(title)}:
                </p>
                <div>
                    {prefs?.lang === constant.lang.values[0] ? item : itemEn}
                </div>
            </div>
        );
    };

    const ContentWithBG = (title, list, listEn) =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm">
                <p className="w-[130px] font-primary text-start">
                    {strings(title)}:
                </p>
                <div className="flex flex-1 gap-grid-sm flex-wrap">
                    {list.map((item, index) => (
                        <p key={index} className={`px-grid-md py-grid-xxs ${isPostLogin ? "bg-neutral-high-regular text-neutral-low-darkest" : "bg-primary-light text-secondary-darkest"} text-center rounded-xl`}>
                            {prefs?.lang === constant.lang.values[0] ? list[index] : listEn[index]}
                        </p>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <Tabs
            active={active}
            setActive={setActive}
            isModal={true}
            list={[
                { key: "details", label: strings("common.details") },
                { key: "salesByDistributor", label: strings("common.salesByDistributor") },
                { key: "salesByRegion", label: strings("common.salesByRegion") }
            ]}
            shouldShowAll={true}
        >
            {active === "details"
                ? <div className={`mx-grid-xl md:mx-auto p-grid-md flex flex-col md:flex-row gap-grid-sm lg:gap-grid-lg items-center justify-start ${isPostLogin ? "text-neutral-low-darkest" : "text-secondary-darkest"} text-sm font-secondary`}>
                    <div className="w-full md:w-[100px]">
                        <div className="w-auto">
                            <Avatar src={prefs?.lang === constant.lang.values[0] ? ITEM.logo : ITEM_EN.logo} size="w-24 h-24"/>
                        </div>
                    </div>
                    <div className="w-full md:w-[75%] font-secondary">
                        {BasicContent("common.commercialRegister", ITEM.commercial_register, ITEM_EN.commercial_register)}
                        {BasicContent("common.country", ITEM.country, ITEM_EN.country)}
                        {ContentWithBG("common.languages", ITEM.languages, ITEM_EN.languages)}
                        {ContentWithBG("common.category", ITEM.categories, ITEM_EN.categories)}
                    </div>
                </div>
                : active === "salesByDistributor"
                    ? <ListOfSales
                        list={ITEM?.sales_by_distributor?.list}
                        totalSales={ITEM.sales_by_distributor?.total_sales}
                        filterType="date"
                        filterValue={date}
                        setFilterValue={setDate}
                    />
                    : <ListOfSales
                        list={ITEM?.sales_by_region?.list}
                        totalSales={ITEM.sales_by_distributor?.total_sales}
                        filterType="date"
                        filterValue={date}
                        setFilterValue={setDate}
                    />
            }
        </Tabs>
    );
};

export default ProducerModal;