import React, { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";

import Label from "../view/component/label";
import Input from "../view/component/input";
import LineMsg from "../view/component/lineMsg";
import Button from "../view/component/button";

import { SessionContext } from "../util/context";
import { getLangCountryURL, strings } from "../util/formatter";

const Login = () =>
{
    const { prefs, setActiveModal, modalOptions, setModalOptions } = useContext(SessionContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const onResetPasswordClick = () =>
    {
        setActiveModal("resetPassword");
        setModalOptions({
            ...modalOptions,
            title: strings("title.resetPassword")
        });
    };

    const login = () =>
    {
        setActiveModal(undefined);
        setModalOptions({});
        navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/directory`);
    };

    return (
        <>
            <div className="pb-4">
                <Label label={strings("label.email")}/>
                <Input
                    name="email"
                    value={email}
                    placeholder={strings("placeholder.email")}
                    setValue={setEmail}
                />
                <LineMsg type="error"/>
            </div>
            <div className="pb-4">
                <Label label={strings("label.password")}/>
                <Input
                    name="password"
                    value={password}
                    type="password"
                    placeholder={strings("placeholder.password")}
                    setValue={setPassword}
                />
                <LineMsg type="error"/>
            </div>
            <div className="flex justify-between gap-grid-xs pt-grid-lg">
                <Button
                    text={strings("button.resetPassword")}
                    bgColor="bg-transparent"
                    hoverBGColor="hover:bg-secondary-darkest"
                    textColor="text-secondary-darkest"
                    hoverTextColor="hover:text-primary-lightest"
                    font="text-sm"
                    padding = "py-2 px-8"
                    border="border-2 border-secondary-darkest"
                    hoverBorder="hover:border-2 hover:border-transparent"
                    shouldHover = {true}
                    onClick={() => onResetPasswordClick()}
                />
                <Button
                    text={strings("button.login")}
                    bgColor="bg-secondary-darkest"
                    onClick={login}
                />
            </div>
        </>
    );
};

export default Login;