import React, { useContext } from "react";

import { motion } from "framer-motion";

import SVGIcon from "./svgIcons";

import { SessionContext } from "../../util/context";

const Button = (props) =>
{
    const { isPostLogin } = useContext(SessionContext)
    const {
        text,
        iconOnly = false,
        icon = undefined,
        iconBefore = undefined,
        iconWidth = 24,
        iconHeight = 24,
        viewBox = "0 0 0 0",
        iconStroke = "stroke-primary-lightest",
        hoverIconStroke = "group-hover:stroke-secondary-darkest",
        textColor = isPostLogin ? "text-neutral-high-lightest" : "text-primary-lightest",
        hoverTextColor = isPostLogin ? "hover:text-neutral-low-darkest" : "hover:text-secondary-darkest",
        bgColor = isPostLogin ? "bg-neutral-low-darkest" : "bg-secondary-darkest",
        hoverBGColor = "hover:bg-transparent",
        border = "border-2 border-transparent",
        hoverBorder = isPostLogin ? "hover:border-2 hover:border-neutral-low-darkest" : "hover:border-2 hover:border-secondary-darkest",
        font = "text-md font-secondary",
        padding = "py-2 px-4",
        iconStyle = "fill-none",
        optionalStyle = "",
        disabled = false,
        shouldHover = true,
        onClick
    } = props;
    return (
        <motion.button disabled={disabled} className={`group ${disabled ? "opacity-50 cursor-not-allowed" : "opacity-100"} h-[40px] transition-colors duration-fast ease-in whitespace-nowrap ${font} ${bgColor} ${textColor} ${border} ${shouldHover && `${hoverBGColor} ${hoverTextColor} ${hoverBorder}`} ${padding} ${optionalStyle} rounded-full`} onClick={onClick}>
            {iconBefore && <SVGIcon name={iconBefore} width={iconWidth} height={iconHeight} className={iconStyle} stroke={iconStroke}/>}
            {!iconOnly && <p>{text}</p>}
            {icon && <SVGIcon name={icon} width={iconWidth} height={iconHeight} viewBox={viewBox !== "0 0 0 0" ? viewBox : `0 0 ${iconWidth} ${iconHeight}`} className={iconStyle} stroke={`${iconStroke} ${shouldHover && hoverIconStroke}`}/>}
        </motion.button>
    );
};

export default Button;