import React, { useContext, useRef } from "react";

import { motion, useScroll, useTransform } from "framer-motion";
import { Trans } from "react-i18next";

import Button from "./button";
import CachedImg from "./cachedImg";

import { SessionContext } from "../../util/context";
import { strings } from "../../util/formatter";

import postLoginBackground from "../../assets/image/post_login_background.png";
import landingPageShape1 from "../../assets/image/landing_page_shape_1.webp";
import landingPageShape2 from "../../assets/image/landing_page_shape_2.webp";

const Section8 = () =>
{
    const { prefs, setActiveModal, modalOptions, setModalOptions } = useContext(SessionContext);
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset: ["start end", "end end"]
    });
    const textScale = useTransform(scrollYProgress, [0, 0.31], [0.8, 1]);
    const textTranslateY = useTransform(scrollYProgress, [0, 0.31], [100, 0]);
    const textOpacity = useTransform(scrollYProgress, [0, 0.31], [0.4, 1]);
    const activeTitleTranslateY = useTransform(scrollYProgress, [0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85], [2, 86, 86, 170, 170, 254, 254, 338, 338]);
    const firstTitleOpacity = useTransform(scrollYProgress, [0.22, 0.36, 0.5], [0, 1, 0]);
    const secondTitleOpacity = useTransform(scrollYProgress, [0.5, 0.55, 0.59], [0, 1, 0]);
    const thirdTitleOpacity = useTransform(scrollYProgress, [0.6, 0.65, 0.69], [0, 1, 0]);
    const fourthTitleOpacity = useTransform(scrollYProgress, [0.7, 0.75, 0.79], [0, 1, 0]);
    const fifthTitleOpacity = useTransform(scrollYProgress, [0.8, 0.85, 0.89], [0, 1, 0]);
    const titleContainerTranslateX = useTransform(scrollYProgress, [0.22, 0.36], [250, 0]);
    const titleContainerOpacity = useTransform(scrollYProgress, [0.22, 0.36], [0, 1]);
    const imgTranslateX = useTransform(scrollYProgress, [0.22, 0.36], [-350, 0]);
    const imgOpacity = useTransform(scrollYProgress, [0.22, 0.36], [0, 1]);
    const eighthSectionPoints = [
        {
            title: strings("landingPage.eighthSecFirstPointTitle"),
            description: strings("landingPage.eighthSecFirstDescription")
        },
        {
            title: strings("landingPage.eighthSecSecondPointTitle"),
            description: strings("landingPage.eighthSecSecondDescription")
        },
        {
            title: strings("landingPage.eighthSecThirdPointTitle"),
            description: strings("landingPage.eighthSecThirdDescription")
        },
        {
            title: strings("landingPage.eighthSecFourthPointTitle"),
            description: strings("landingPage.eighthSecFourthDescription")
        },
        {
            title: strings("landingPage.eighthSecFifthPointTitle"),
            description: strings("landingPage.eighthSecFifthDescription")
        }
    ];

    return (
        <div ref={targetRef} className="relative mx-grid-md md:mx-0 pt-grid-xxl flex flex-col justify-between">
            <CachedImg src={landingPageShape1} className="absolute top-grid-xxl z-0 start-1/2"/>
            <CachedImg src={landingPageShape2} className="absolute -top-grid-sm z-0 end-grid-xl"/>
            <div className="min-h-[300vh] pb-[100px]">
                <motion.div style={{ scale: textScale, translateY: textTranslateY, opacity: textOpacity }} className="relative z-10 py-grid-xxl text-xl font-primary text-secondary-darkest text-center">
                    <Trans
                        i18nKey="landingPage.eighthSectionHead"
                        components={{ span: <span className="text-primary-regular" />, p: <p /> }}
                    />
                    <Button
                        text={strings("button.openFacilityAccount")}
                        onClick={() =>
                        {
                            setActiveModal("signup");
                            setModalOptions({
                                ...modalOptions,
                                title: strings("title.signup")
                            });
                        }}
                        icon="arrow"
                        bgColor="bg-secondary-darkest"
                        padding="py-grid-xxs pe-grid-xs ps-grid-sm"
                        iconStyle={`${prefs.dir === "rtl" ? "" : "rotate-180"} fill-none`}
                        optionalStyle="mx-auto my-grid-xl flex items-center gap-grid-md"
                    />
                </motion.div>
                <div className="sticky top-[250px] flex flex-col lg:flex-row gap-grid-md">
                    <div className="hidden lg:block">
                        <motion.div style={{ translateX: titleContainerTranslateX, opacity: titleContainerOpacity }} className="w-full flex gap-grid-md px-grid-md">
                            <motion.div style={{ translateY: activeTitleTranslateY }}>
                                <div className="flex w-[14px] h-[14px] rounded-full bg-secondary-darkest justify-center items-center">
                                    <div className="w-[6px] h-[6px] rounded-full bg-primary-lightest"/>
                                </div>
                            </motion.div>
                            <div className="h-auto flex flex-col gap-10 text-seconbg-secondary-darkest leading-snug">
                                {eighthSectionPoints?.map((item, index) =>
                                {
                                    return (
                                        <div key={index} className="flex flex-col justify-end text-md">
                                            <p className="font-primary pb-grid-xs">
                                                {strings(item.title)}
                                            </p>
                                            <p className="text-sm whitespace-nowrap">
                                                {strings(item.description)}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </motion.div>
                    </div>
                    <div className="block lg:hidden">
                        <motion.div className="w-full">
                            <div className="relative h-auto gap-10 text-secondary-darkest leading-snug">
                                <motion.div style={{ opacity: firstTitleOpacity, translateX: imgTranslateX }} className="absolute top-0 start-0 flex flex-col justify-end text-lg opacity-0">
                                    <p className="font-primary pb-grid-xs">
                                        {strings(eighthSectionPoints[0].title)}
                                    </p>
                                    <p className="whitespace-nowrap">
                                        {strings(eighthSectionPoints[0].description)}
                                    </p>
                                </motion.div>
                                <motion.div style={{ opacity: secondTitleOpacity }} className="absolute top-0 start-0 flex flex-col justify-end text-lg opacity-0">
                                    <p className="font-primary pb-grid-xs">
                                        {strings(eighthSectionPoints[1].title)}
                                    </p>
                                    <p className="whitespace-nowrap">
                                        {strings(eighthSectionPoints[1].description)}
                                    </p>
                                </motion.div>
                                <motion.div style={{ opacity: thirdTitleOpacity }} className="absolute top-0 start-0 flex flex-col justify-end text-lg opacity-0">
                                    <p className="font-primary pb-grid-xs">
                                        {strings(eighthSectionPoints[2].title)}
                                    </p>
                                    <p className="whitespace-nowrap">
                                        {strings(eighthSectionPoints[2].description)}
                                    </p>
                                </motion.div>
                                <motion.div style={{ opacity: fourthTitleOpacity }} className="absolute top-0 start-0 flex flex-col justify-end text-lg opacity-0">
                                    <p className="font-primary pb-grid-xs">
                                        {strings(eighthSectionPoints[3].title)}
                                    </p>
                                    <p className="whitespace-nowrap">
                                        {strings(eighthSectionPoints[3].description)}
                                    </p>
                                </motion.div>
                                <motion.div style={{ opacity: fifthTitleOpacity }} className="absolute top-0 start-0 flex flex-col justify-end text-lg opacity-0">
                                    <p className="font-primary pb-grid-xs">
                                        {strings(eighthSectionPoints[4].title)}
                                    </p>
                                    <p className="whitespace-nowrap">
                                        {strings(eighthSectionPoints[4].description)}
                                    </p>
                                </motion.div>
                            </div>
                        </motion.div>
                    </div>
                    <div className="w-full lg:w-auto pt-grid-xxl lg:pt-0">
                        <div className="relative">
                            <motion.img style={{ translateX: imgTranslateX, opacity: imgOpacity }} src={postLoginBackground} className="rounded-sm shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Section8;