import React from "react";

const Label = ({ label }) =>
{
    return (
        <div className="text-md mb-grid-xs">
            {label}
        </div>
    );
};

export default Label;