import React, { useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import Section from "../view/component/section";
import ChartContestantLarge from "../view/component/chartContestantLarge";
import ChartContestantMedium from "../view/component/chartContestantMedium";
import DateRange from "../view/component/dateRange";

import { SessionContext } from "../util/context";
import { strings, getLangCountryURL } from "../util/formatter";
import Button from "../view/component/button";
import DropdownMenu from "../view/component/dropdownMenu";

import book1 from "../assets/image/books/book1.jpg"
import book2 from "../assets/image/books/book2.jpg"
import book3 from "../assets/image/books/book3.jpg"
import book4 from "../assets/image/books/book4.jpg"
import book5 from "../assets/image/books/book5.jpg"
import book6 from "../assets/image/books/book6.jpeg"
import book7 from "../assets/image/books/book7.jpg"
import book8 from "../assets/image/books/book8.jpg"
import book9 from "../assets/image/books/book9.jpg"
import book10 from "../assets/image/books/book10.jpg"

import author1 from "../assets/image/authors/author1.webp";
import author2 from "../assets/image/authors/author2.jpg";
import author3 from "../assets/image/authors/author3.jpg";
import author4 from "../assets/image/authors/author4.jpg";
import author5 from "../assets/image/authors/author5.png";
import author6 from "../assets/image/authors/author6.jpg";
import author7 from "../assets/image/authors/author7.jpg";
import author8 from "../assets/image/authors/author8.jpg";
import author9 from "../assets/image/authors/author9.jpg";
import author10 from "../assets/image/authors/author10.jpg";

import publisher1 from "../assets/image/publishers/publisher1.png";
import publisher2 from "../assets/image/publishers/publisher2.jpg";
import publisher3 from "../assets/image/publishers/publisher3.webp";
import publisher4 from "../assets/image/publishers/publisher4.jpg";
import publisher5 from "../assets/image/publishers/publisher5.jpg";
import publisher6 from "../assets/image/publishers/publisher6.jpg";
import publisher7 from "../assets/image/publishers/publisher7.webp";
import publisher8 from "../assets/image/publishers/publisher8.webp";
import publisher9 from "../assets/image/publishers/publisher9.webp";
import publisher10 from "../assets/image/publishers/publisher10.png";

const TOP_LIST = [
    {
        title: "common.products",
        key: "product"
    },
    {
        title: "common.contributors",
        key: "contributor"
    },
    {
        title: "common.producers",
        key: "producer"
    }
];

const CATEGORY = [
    {
        title: strings("common.all"),
        key: "all"
    },
    {
        title: strings("common.novel"),
        key: "novel"
    },
    {
        title: strings("common.poetry"),
        key: "poetry"
    }
];

const Charts = () =>
{
    const { prefs } = useContext(SessionContext);
    const params = useParams();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(params?.type ? params?.type : "products");
    const [activeCategory, setActiveCategory] = useState(params?.category ? params?.category : "all");
    const [category, setCategory] = useState(CATEGORY?.filter(item => item.key === activeCategory)?.[0]);

    const CHART_ITEMS = [
        {
            id: 1,
            cover: book1,
            name: "شقة الحورية",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 1,
            rank_status: "up"
        },
        {
            id: 2,
            cover: book2,
            name: "جنوب الحدود غرب الشمس",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "230 ألف",
            rank: 2,
            rank_status: "up"
        },
        {
            id: 3,
            cover: book3,
            name: "ثلاثية غرناطة",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "10 آلاف",
            rank: 3,
            rank_status: "down"
        },
        {
            id: 4,
            cover: book4,
            name: "قريب من البحر بعيد عن الزرقة",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 4,
            rank_status: "down"
        },
        {
            id: 5,
            cover: book5,
            name: "مزرعة الحيوان",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 5,
            rank_status: "up"
        },
        {
            id: 6,
            cover: book6,
            name: "السعودية المتغيرة",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 6,
            rank_status: "up"
        },
        {
            id: 7,
            cover: book7,
            name: "الحرب و السلم",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 7,
            rank_status: "up"
        },
        {
            id: 8,
            cover: book8,
            name: "الحالة الحرجة للمدعو ك",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 8,
            rank_status: "up"
        },
        {
            id: 9,
            cover: book9,
            name: "الليالي البيضاء",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 9,
            rank_status: "up"
        },
        {
            id: 10,
            cover: book10,
            name: "موت صغير",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 10,
            rank_status: "up"
        }
    ];

    const CHART_ITEMS_AUTHOR = [
        {
            id: 1,
            cover: author1,
            name: "ستيفان زفايج",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 1,
            rank_status: "up"
        },
        {
            id: 2,
            cover: author2,
            name: "ريتشارد فورد",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "230 ألف",
            rank: 2,
            rank_status: "up"
        },
        {
            id: 3,
            cover: author3,
            name: "جورج مارتن",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "10 آلاف",
            rank: 3,
            rank_status: "down"
        },
        {
            id: 4,
            cover: author4,
            name: "جاسم الصحيح",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 4,
            rank_status: "down"
        },
        {
            id: 5,
            cover: author5,
            name: "جوزيه ساراماغو",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 5,
            rank_status: "up"
        },
        {
            id: 6,
            cover: author6,
            name: "عزيز محمد",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 6,
            rank_status: "up"
        },
        {
            id: 7,
            cover: author7,
            name: "زهران القاسمي",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 7,
            rank_status: "up"
        },
        {
            id: 8,
            cover: author8,
            name: "كارلوس زافون",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 8,
            rank_status: "up"
        },
        {
            id: 9,
            cover: author9,
            name: "محمد حسن علوان",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 9,
            rank_status: "up"
        },
        {
            id: 10,
            cover: author10,
            name: "احلام مستغانمي",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 10,
            rank_status: "up"
        }
    ];

    const CHART_ITEMS_PUBLISHER = [
        {
            id: 1,
            cover: publisher1,
            name: "عصير الكتاب",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 1,
            rank_status: "up"
        },
        {
            id: 2,
            cover: publisher2,
            name: "مسكيلياني",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "230 ألف",
            rank: 2,
            rank_status: "up"
        },
        {
            id: 3,
            cover: publisher3,
            name: "الكرمة",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "10 آلاف",
            rank: 3,
            rank_status: "down"
        },
        {
            id: 4,
            cover: publisher4,
            name: "أثر",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 4,
            rank_status: "down"
        },
        {
            id: 5,
            cover: publisher5,
            name: "الدار العربية للعلوم ناشرون",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 5,
            rank_status: "up"
        },
        {
            id: 6,
            cover: publisher6,
            name: "دار الساقي",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 6,
            rank_status: "up"
        },
        {
            id: 7,
            cover: publisher7,
            name: "رشم للنشر والتوزيع",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 7,
            rank_status: "up"
        },
        {
            id: 8,
            cover: publisher8,
            name: "تكوين",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 8,
            rank_status: "up"
        },
        {
            id: 9,
            cover: publisher9,
            name: "حياة",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 9,
            rank_status: "up"
        },
        {
            id: 10,
            cover: publisher10,
            name: "دار ميلاد",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 10,
            rank_status: "up"
        }
    ];

    const setSecondParam = (categoryObj) =>
    {
        if (categoryObj?.key !== activeCategory)
        {
            setActiveCategory(categoryObj?.key);
            setCategory(categoryObj);
            navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/charts/${activeTab}/${categoryObj?.key}`);
        }
    };

    useEffect(() =>
    {
        setActiveTab(params?.type);
    }, [params?.type]);

    return (
        <>
            <Section optionalStyle="min-h-auto">
                <div className="relative pt-[60%] sm:pt-[40%] md:pt-[35%] lg:pt-[20%] pb-grid-xl px-grid-md md:px-0">
                    <div className="flex items-center justify-between gap-grid-xs text-primary-lightest">
                        <div>
                            <p className="py-grid-sm text-xl font-primary text-primary-lightest">
                                {strings("chartPage.mostHundredPaid").replace("{chart}", strings(`common.${activeTab}`))}
                            </p>
                            <DateRange dateFrom={"2023-08-13"} dateTo={"2023-08-19"} />
                        </div>
                        <div className="flex gap-grid-xs">
                            {TOP_LIST.map((item, index) => (
                                <Button
                                    key={index}
                                    text={strings(`${item.title}`)}
                                    bgColor={activeTab === item.key ? "bg-accent-lightest" : "bg-transparent"}
                                    hoverBGColor={activeTab !== item.key ? "hover:bg-accent-lightest" : "hover:bg-transparent"}
                                    textColor={activeTab === item.key ? "text-primary-regular" : "text-accent-lightest"}
                                    hoverTextColor={activeTab !== item.key ? "hover:text-primary-regular" : "hover:text-accent-lightest"}
                                    border={`border-2 ${activeTab === item.key ? "border-transparent" : "border-accent-lightest"}`}
                                    hoverBorder={activeTab !== item.key ? "hover:border-2 hover:border-transparent" : ""}
                                    shouldHover={activeTab !== item.key}
                                    padding="py-2 px-[30px]"
                                    onClick={() => activeTab !== item.key && navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/charts/${item.key}/${activeCategory}`)}
                                />
                            ))}
                            <DropdownMenu
                                list={CATEGORY}
                                selectedValue={category}
                                labelKey="title"
                                placeholder={strings("placeholder.category")}
                                bgColor="bg-accent-regular"
                                textColor="text-accent-lightest"
                                iconStroke="stroke-accent-lightest"
                                font="text-md font-secondary"
                                roundedStyle="rounded-full"
                                optionalStyle="border-2 border-transparent"
                                padding="py-2 px-4"
                                width="w-[120px]"
                                gap="gap-grid-xs"
                                setSelected={setSecondParam}
                            />
                        </div>
                    </div>
                </div>
            </Section>
            <Section>
                <div className="relative pb-grid-xl px-grid-md md:px-0">
                    <div className="hidden xl:flex flex-wrap xl:flex-nowrap gap-grid-xxl pb-grid-xxl">
                        {params?.type === "producer"
                        ? CHART_ITEMS_PUBLISHER?.slice(0, 3)?.map((item, index) => (
                                <ChartContestantLarge
                                    key={index}
                                    item={item}
                                    path={params?.type === "product"
                                        ? `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${item.id}`
                                        : `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${params?.type}/${item.id}`
                                    }
                                    roundedImg={params?.type === "contributor" || params?.type === "producer"}

                                />
                            ))
                        : params?.type === "contributor"
                        ? CHART_ITEMS_AUTHOR?.slice(0, 3)?.map((item, index) => (
                                <ChartContestantLarge
                                    key={index}
                                    item={item}
                                    path={params?.type === "product"
                                        ? `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${item.id}`
                                        : `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${params?.type}/${item.id}`
                                    }
                                    roundedImg={params?.type === "contributor" || params?.type === "producer"}

                                />
                            ))
                        : CHART_ITEMS?.slice(0, 3)?.map((item, index) => (
                            <ChartContestantLarge
                                key={index}
                                item={item}
                                path={params?.type === "product"
                                    ? `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${item.id}`
                                    : `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${params?.type}/${item.id}`
                                }
                                roundedImg={params?.type === "contributor" || params?.type === "producer"}

                            />
                        ))}
                    </div>
                    <div className="flex xl:hidden flex-col gap-grid-sm pt-grid-md pb-grid-md">
                        {params?.type === "producer"
                        ? CHART_ITEMS_PUBLISHER?.slice(0, 3)?.map((item, index) => (
                            <ChartContestantMedium
                                key={index}
                                item={item}
                                path={params?.type === "product"
                                    ? `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${item.id}`
                                    : `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${params?.type}/${item.id}`
                                }
                                roundedImg={params?.type === "contributor" || params?.type === "producer"}
                            />
                        ))
                        : params?.type === "contributor"
                        ? CHART_ITEMS_AUTHOR?.slice(0, 3)?.map((item, index) => (
                            <ChartContestantMedium
                                key={index}
                                item={item}
                                path={params?.type === "product"
                                    ? `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${item.id}`
                                    : `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${params?.type}/${item.id}`
                                }
                                roundedImg={params?.type === "contributor" || params?.type === "producer"}
                            />
                        ))
                        : CHART_ITEMS?.slice(0, 3)?.map((item, index) => (
                            <ChartContestantMedium
                                key={index}
                                item={item}
                                path={params?.type === "product"
                                    ? `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${item.id}`
                                    : `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${params?.type}/${item.id}`
                                }
                                roundedImg={params?.type === "contributor" || params?.type === "producer"}
                            />
                        ))}
                    </div>
                    <div className="flex flex-col gap-grid-sm">
                        {params?.type === "producer"
                        ? CHART_ITEMS_PUBLISHER?.slice(3, CHART_ITEMS?.length)?.map((item, index) => (
                            <ChartContestantMedium
                                key={index}
                                item={item}
                                path={params?.type === "product"
                                    ? `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${item.id}`
                                    : `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${params?.type}/${item.id}`
                                }
                                roundedImg={params?.type === "contributor" || params?.type === "producer"}
                            />
                        ))
                        : params?.type === "contributor"
                        ? CHART_ITEMS_AUTHOR?.slice(3, CHART_ITEMS?.length)?.map((item, index) => (
                            <ChartContestantMedium
                                key={index}
                                item={item}
                                path={params?.type === "product"
                                    ? `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${item.id}`
                                    : `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${params?.type}/${item.id}`
                                }
                                roundedImg={params?.type === "contributor" || params?.type === "producer"}
                            />
                        ))
                        : CHART_ITEMS?.slice(3, CHART_ITEMS?.length)?.map((item, index) => (
                            <ChartContestantMedium
                                key={index}
                                item={item}
                                path={params?.type === "product"
                                    ? `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${item.id}`
                                    : `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${params?.type}/${item.id}`
                                }
                                roundedImg={params?.type === "contributor" || params?.type === "producer"}
                            />
                        ))}
                    </div>
                </div>
            </Section>
        </>
    );
};

export default Charts;