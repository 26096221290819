import React, { useContext } from "react";

import { SessionContext } from "../../util/context";
import { AnimatePresence, motion } from "framer-motion";

const DismissibleBanner = () =>
{
    const { shouldShowBnr, setShouldShowBnr, dismissableBnrMsg, setDismissableBnrMsg } = useContext(SessionContext);

    return (
        <AnimatePresence>
            {shouldShowBnr &&
                <motion.div
                    initial={{ height: 0 }}
                    animate={{ height: 40 }}
                    exit={{ height: 0 }}
                    transition={{ duration: 0.6 }}
                    className="flex items-center justify-between bg-status-warning-lightest"
                >
                    <motion.div
                        initial={{ translateY: -40 }}
                        animate={{ translateY: 0 }}
                        exit={{ translateY: -40 }}
                        transition={{ duration: 0.6 }}
                        className="w-[32px]"
                    />
                    <motion.div
                        initial={{ translateY: -40 }}
                        animate={{ translateY: 0 }}
                        exit={{ translateY: -40 }}
                        transition={{ duration: 0.6 }}
                        className="font-primary"
                    >
                        {dismissableBnrMsg}
                    </motion.div>
                    <motion.div
                        initial={{ translateY: -40, paddingBottom: "0" }}
                        animate={{ translateY: 0, paddingBottom: "0.25rem" }}
                        exit={{ translateY: -40, paddingBottom: "0" }}
                        transition={{ duration: 0.6 }}
                        className="text-center w-[32px] text-[18px] hover:opacity-70 transition-opacity duration-too-fast ease-in cursor-pointer"
                        onClick={() => {
                            setShouldShowBnr(false);
                            setDismissableBnrMsg("")
                        }}
                    >
                        &times;
                    </motion.div>
                </motion.div>
        }
        </AnimatePresence>
    );
};

export default DismissibleBanner;