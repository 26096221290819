import React, { useContext, useEffect } from "react";

import { SessionContext } from "../../util/context";

const Loader = () =>
{
    const { lenis } = useContext(SessionContext);

    useEffect(() =>
    {
        lenis.stop();

        return lenis.start();
    }, []);

    return (
        <div className="fixed z-[9999] top-0 start-0 min-w-full min-h-screen flex justify-center items-center backdrop-blur-sm">
            <div className="w-[70px] h-[100px] fill-primary-lightest">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                    viewBox="0 0 100 100" enableBackground="new 0 0 100 100" xmlSpace="preserve">
                    <rect x="0" fill="fill-primary-lightest" width="18" height="70" transform="translate(0) rotate(180 17 50)" rx="3">
                        <animate
                            attributeName="height"
                            attributeType="XML"
                            dur="1.2s"
                            values="30; 70; 30"
                            repeatCount="indefinite"
                            begin="0.1s"
                        />
                    </rect>
                    <rect x="17" fill="fill-primary-lightest" width="18" height="70" transform="translate(0) rotate(180 38 50)" rx="3">
                        <animate
                            attributeName="height"
                            attributeType="XML"
                            dur="1.2s"
                            values="30; 70; 30"
                            repeatCount="indefinite"
                            begin="0.3s"/>
                    </rect>
                    <rect x="40" fill="fill-primary-lightest" width="18" height="70" transform="translate(0) rotate(180 62 50)" rx="3">
                        <animate
                            attributeName="height"
                            attributeType="XML"
                            dur="1.2s"
                            values="30; 70; 30"
                            repeatCount="indefinite"
                            begin="0.6s"/>
                    </rect>
                </svg>
            </div>
        </div>
    );
};

export default Loader;