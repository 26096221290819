import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../view/component/button";
import SVGIcon from "../view/component/svgIcons";

import { getLangCountryURL, strings } from "../util/formatter";
import { SessionContext } from "../util/context";

export const SUBSCRIPTION_TYPE = [
    {
        id: 1,
        label: "مراقبة حركة المنافسات",
        price: "100 رس",
        period: "شهريًا"
    },
    {
        id: 2,
        label: "إدارة المنشورات",
        price: "100 رس",
        period: "شهريًا"
    },
    {
        id: 3,
        label: "إدارة المبيعات",
        price: "100 رس",
        period: "شهريًا"
    },
    {
        id: 4,
        label: "استعراض المبيعات",
        price: "100 رس",
        period: "شهريًا"
    }
];

const Subscription = () =>
{
    const { prefs, setActiveModal, setModalOptions } = useContext(SessionContext);
    const [listOfSelected, setListOfSelected] = useState([]);
    const navigate = useNavigate();

    const selectSubscription = (item) =>
    {
        const arr = listOfSelected;
        if (arr?.some(selected => selected.id === item.id))
        {
            arr?.splice(arr?.map(e => e?.id)?.indexOf(item.id), 1);
            setListOfSelected([...arr]);
        }
        else
        {
            setListOfSelected([...listOfSelected, item]);
        }
    };

    const onSubmit = () =>
    {
        setModalOptions({});
        setActiveModal(undefined);
        navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/overview`);
    };

    return (
        <>
            <div className="inline-block py-grid-lg">
                {SUBSCRIPTION_TYPE.map((item, index) => (
                    <div key={index} className="mb-grid-md flex justify-start items-center gap-grid-sm">
                        <div
                            className={`w-[20px] h-[20px] ${listOfSelected?.some(selected => selected.id === item.id) ? "bg-secondary-darkest" : "bg-secondary-light"} cursor-pointer`}
                            onClick={() => selectSubscription(item)}
                        >
                            {listOfSelected?.some(selected => selected.id === item.id) &&
                                <SVGIcon name="checkMark" width={20} height={20} viewBox="-2 1 18 8" pathFill="fill-primary-lightest"/>
                            }
                        </div>
                        <div className="text-start">
                            {`${item.label} (${item.price} ${item.period})`}
                        </div>
                    </div>
                ))}
            </div>
            <div className="text-end">
                <Button
                    text={strings("button.next")}
                    bgColor="bg-secondary-darkest"
                    padding="py-2 px-grid-xl"
                    onClick={onSubmit}
                />
            </div>
        </>
    );
};

export default Subscription;