import React from "react";

import { motion } from "framer-motion";

const LineMsg = (props) =>
{
    const { type, msg } = props;
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6 }}
            className={`mt-1 h-[20px] text-sm text-start ${type === "error" ? "text-red-600" : "text-green-600"}`}
        >
            {msg || ""}
        </motion.div>
    );
};

export default LineMsg;