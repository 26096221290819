/* eslint-disable no-constant-condition */
import React from "react";

import Button from "./button";

import { strings } from "../../util/formatter";

const NoInternetConnection = () =>
{
    const onClickRefresh = () =>
    {
        window.location.reload();
    }

    return (
        <div className="h-screen flex flex-col gap-grid-md justify-center items-center text-primary-lightest">
            <p className="text-lg font-primary">
                {strings("title.noInternetConnection")}
            </p>
            <p className="text-md font-secondary">
                {strings("message.noInternetConnection")}
            </p>
            <Button
                text={strings("button.reloadPage")}
                bgColor={"bg-accent-regular"}
                hoverBGColor={"hover:bg-transparent"}
                textColor={"text-accent-lightest"}
                hoverTextColor={"hover:text-accent-regular"}
                border={"border-2 border-transparent"}
                hoverBorder={"hover:border-2 hover:border-accent-regular"}
                padding={"py-2 px-8"}
                onClick={onClickRefresh}
            />
        </div>
    );
};

export default NoInternetConnection;