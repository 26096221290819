import React, { useEffect, useState } from "react";

import HeatMapView from "../view/component/heatMapView";

import { strings } from "../util/formatter";
import Loader from "../view/component/loader";

const HeatMap = () =>
{
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() =>
    {
        setIsLoading(true);
        setTimeout(() =>
        {
            setIsLoading(false);
        }, 1500);
    }, []);

    return (
        <div className="p-grid-md">
            {isLoading && <Loader />}
            <p className="pt-grid-md pb-grid-lg text-md md:text-lg font-primary text-neutral-low-dark">
                {strings("title.heatMap")}
            </p>
            <div className="google-map-custom-wrapper">
                <HeatMapView libraries={["visualization"]}/>
            </div>
        </div>
    );
};

export default HeatMap;