import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import SVGIcon from "./svgIcons";

import { SessionContext } from "../../util/context";
import { strings } from "../../util/formatter";

const RankRow = (props) =>
{
    const { prefs } = useContext(SessionContext);
    const { rank, path } = props;

    return (
        <Link to={path}>
            <motion.div whileHover={{ scale: 1.02 }} className="p-grid-sm flex gap-grid-sm items-center justify-between bg-primary-dark text-primary-lightest text-lg font-primary rounded-sm shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_30px_30px_0_rgba(0,0,0,0.3)]">
                <div className="flex gap-grid-xs items-center">
                    <SVGIcon name="rank" stroke="stroke-primary-lightest"/>
                    <p>
                        {strings("common.occupiesPlace")?.replace("{RANK}", rank)}
                    </p>
                </div>
                <div className="flex items-center">
                    <SVGIcon name="chevron" viewBox="0 0 24 24" width={17} height={17} stroke="stroke-primary-lightest" className={`${prefs?.dir === "rtl" ? "rotate-180" : ""} fill-none`}/>
                </div>
            </motion.div>
        </Link>
    );
};

export default RankRow;