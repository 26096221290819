import React, { createRef, useState } from "react";

import LineMsg from "./lineMsg";
import Label from "./label";
import Input from "./input";
import DropdownMenu from "./dropdownMenu";
import DatePickerButton from "./datePickerButton";
import FileInput from "./fileInput";

import { strings } from "../../util/formatter";

const CreateDirectoryStep3 = () =>
{
    const documentFileRef = createRef(null);
    const [selectedEntityType, setSelectedEntityType] = useState({});
    const [selectedDocumentType, setSelectedDocumentType] = useState({});
    const [selectedDocumentCountry, setSelectedDocumentCountry] = useState({});
    const [documentNumber, setDocumentNumber] = useState("");
    const [documentExpiryDate, setDocumentExpiryDate] = useState(null);
    const [documentFile, setDocumentFile] = useState({});

    const ENTITY_TYPE = [
        {
            id: 1,
            name: "منشأة حكومية"
        },
        {
            id: 2,
            name: "منشأة خاصة"
        }
    ];

    const DOCUMENT_TYPE = [
        {
            id: 1,
            name: "سجل تجاري"
        },
        {
            id: 2,
            name: "هوية وطنية"
        }
    ];

    const DOCUMENT_ISSUED_COUNTRY = [
        {
            id: 1,
            name: "المملكة العربية السعودية"
        }
    ];

    const selectDocumentFile = () =>
    {
        if (documentFileRef.current !== null)
        {
            documentFileRef.current.click();
        }
    };

    return (
        <>
            <div className="pb-grid-sm">
                <Label label={strings("label.entityType")}/>
                <DropdownMenu
                    list={ENTITY_TYPE}
                    labelKey="name"
                    placeholder={strings("placeholder.selectEntity")}
                    selectedValue={selectedEntityType}
                    setSelected={setSelectedEntityType}
                />
                <LineMsg type="error"/>
            </div>
            <div className="flex justify-between gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.documentType")}/>
                    <DropdownMenu
                        list={DOCUMENT_TYPE}
                        labelKey="name"
                        placeholder={strings("placeholder.selectType")}
                        selectedValue={selectedDocumentType}
                        setSelected={setSelectedDocumentType}
                    />
                    <LineMsg type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.documentNumber")}/>
                    <Input
                        name="documentNumber"
                        value={documentNumber}
                        setValue={setDocumentNumber}
                    />
                    <LineMsg type="error"/>
                </div>
            </div>
            <div className="flex justify-between gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.documentIssuedCountry")}/>
                    <DropdownMenu
                        list={DOCUMENT_ISSUED_COUNTRY}
                        labelKey="name"
                        placeholder={strings("placeholder.selectCountry")}
                        selectedValue={selectedDocumentCountry}
                        setSelected={setSelectedDocumentCountry}
                    />
                    <LineMsg type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.documentExpiryDate")}/>
                    <DatePickerButton
                        value={documentExpiryDate}
                        setValue={setDocumentExpiryDate}
                    />
                    <LineMsg type="error"/>
                </div>
            </div>
            <div className="pb-grid-sm">
                <Label label={strings("label.documentFile")}/>
                <FileInput
                    fileRef={documentFileRef}
                    value={documentFile}
                    setValue={setDocumentFile}
                    selectFile={selectDocumentFile}
                />
                <LineMsg type="error"/>
            </div>
        </>
    );
};

export default CreateDirectoryStep3;