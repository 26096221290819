import React from "react";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import Rank from "./rank";
import CachedImg from "./cachedImg";
import Avatar from "./avatar";

import { strings } from "../../util/formatter";

const ChartContestantLarge = (props) =>
{
    const { item, path, roundedImg = true, size = "w-[135px] h-[135px]" } = props;

    return (
        <Link to={path} className="w-full xl:w-[33%]">
            <motion.div
                whileHover={{ scale: 1.05 }}
                className="h-[400px] flex bg-primary-lightest rounded-sm cursor-pointer shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]"
            >
                <div className="w-1/3 flex flex-col bg-primary-light text-sm font-primary rounded-s-sm text-center">
                    <div className="h-[25%] flex flex-col justify-center items-center p-grid-xxs border-b border-primary-lightest">
                        <p className="text-xl">
                            {item?.rank}
                        </p>
                        <div className="pb-2">
                            <Rank rank={item?.rank} rankStatus={item?.rank_status} />
                        </div>
                    </div>
                    <div className="h-[25%] flex flex-col justify-center items-center gap-grid-xs p-grid-xxs border-b border-primary-lightest">
                        <p>
                            {strings("common.weekSales")}
                        </p>
                        <p>
                            {item?.week_sales}
                        </p>
                    </div>
                    <div className="h-[25%] flex flex-col justify-center items-center gap-grid-xs p-grid-xxs border-b border-primary-lightest">
                        <div>
                            <p>
                                {strings("common.weeksIn")}
                            </p>
                            <p>
                                {strings("common.place")}<span className="ps-2">{item?.rank}</span>
                            </p>
                        </div>
                        <p>
                            {item?.weeks_in_place}
                        </p>
                    </div>
                    <div className="h-[25%] flex flex-col justify-center items-center gap-grid-xs p-grid-xxs">
                        <p>
                            {strings("common.weeksInCompetition")}
                        </p>
                        <p>
                            {item?.weeks_in_competition}
                        </p>
                    </div>
                </div>
                <div className={`w-2/3 flex flex-col ${roundedImg ? "justify-center" : "justify-between"} items-center p-grid-lg`}>
                    {roundedImg
                        ? <Avatar src={item?.cover || item?.image} size={size}/>
                        : <CachedImg src={item.cover} alt={item?.name} className="h-[65%]"/>
                    }
                    <div className="h-[15%] pt-grid-xs flex justify-center items-center">
                        <p className="text-center">
                            {item?.name}
                        </p>
                    </div>
                </div>
            </motion.div>
        </Link>
    );
};

export default ChartContestantLarge;