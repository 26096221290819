import React, { useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import Section from "../view/component/section";
import Loader from "../view/component/loader";
import DropdownMenu from "../view/component/dropdownMenu";
import Button from "../view/component/button";
import ChartContestantSmall from "../view/component/chartContestantSmall";

import { SessionContext } from "../util/context";
import { getLangCountryURL, strings } from "../util/formatter";

import book1 from "../assets/image/books/book1.jpg"
import book2 from "../assets/image/books/book2.jpg"
import book3 from "../assets/image/books/book3.jpg"
import book4 from "../assets/image/books/book4.jpg"
import book5 from "../assets/image/books/book5.jpg"
import book6 from "../assets/image/books/book6.jpeg"
import book7 from "../assets/image/books/book7.jpg"
import book8 from "../assets/image/books/book8.jpg"
import book9 from "../assets/image/books/book9.jpg"
import book10 from "../assets/image/books/book10.jpg"

const Catalog = () =>
{
    const { prefs } = useContext(SessionContext);
    const params = useParams();
    const navigate = useNavigate();
    const [shouldLoadMore, setShouldLoadMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [filterType, setFilterType] = useState({});
    const [filterValue, setFilterValue] = useState({});
    const ITEM = {
        id: 1,
        logo: "https://cdn.salla.sa/RnneE/gjrIBT01nmvWYhwJV9yjTLn8SzmWawTKM5GWT1pv.png",
        name: "دار ميلاد للنشر والتوزيع",
        commercial_register: "101097537535",
        country: "المملكة العربية السعودية",
        languages: [
            "العربية",
            "الانجليزية"
        ],
        categories: [
            "رواية",
            "رحلات",
            "تاريخ"
        ],
        products: [
            {
                id: 1,
                cover: book1,
                name: "شقة الحورية",
                contributor_name: "غازي القصيبي"
            },
            {
                id: 2,
                cover: book2,
                name: "جنوب الحدود غرب الشمس",
                contributor_name: "هاروكي موراكامي"
            },
            {
                id: 3,
                cover: book3,
                name: "ثلاثية غرناطة",
                contributor_name: "رضوى عاشور"
            },
            {
                id: 4,
                cover: book4,
                name: "قريب من البحر بعيد عن الزرقة",
                contributor_name: "جاسم الصحيح"
            },
            {
                id: 5,
                cover: book5,
                name: "مزرعة الحيوان",
                contributor_name: "جورج أورويل"
            },
            {
                id: 6,
                cover: book6,
                name: "السعودية المتغيرة",
                contributor_name: "شون فولي"
            },
            {
                id: 7,
                cover: book7,
                name: "الحرب و السلم",
                contributor_name: "ليف تولستوي"
            },
            {
                id: 8,
                cover: book8,
                name: "الحالة الحرجة للمدعو ك",
                contributor_name: "عزيز محمد"
            },
            {
                id: 9,
                cover: book9,
                name: "الليالي البيضاء",
                contributor_name: "دوستويفسكي"
            },
            {
                id: 10,
                cover: book10,
                name: "موت صغير",
                contributor_name: "محمد حسن علوان"
            }
        ]
    };
    const ITEM_EN = {
        id: 1,
        logo: "https://cdn.salla.sa/RnneE/gjrIBT01nmvWYhwJV9yjTLn8SzmWawTKM5GWT1pv.png",
        name: "Melad Publishing and Distribution House",
        commercial_register: "101097537535",
        country: "Saudi Arabia",
        languages: [
            "Arabic",
            "English"
        ],
        categories: [
            "Novel",
            "Trips",
            "History"
        ],
        products: [
            {
                id: 1,
                cover: book1,
                name: "شقة الحورية",
                contributor_name: "غازي القصيبي"
            },
            {
                id: 2,
                cover: book2,
                name: "جنوب الحدود غرب الشمس",
                contributor_name: "هاروكي موراكامي"
            },
            {
                id: 3,
                cover: book3,
                name: "ثلاثية غرناطة",
                contributor_name: "رضوى عاشور"
            },
            {
                id: 4,
                cover: book4,
                name: "قريب من البحر بعيد عن الزرقة",
                contributor_name: "جاسم الصحيح"
            },
            {
                id: 5,
                cover: book5,
                name: "مزرعة الحيوان",
                contributor_name: "جورج أورويل"
            },
            {
                id: 6,
                cover: book6,
                name: "السعودية المتغيرة",
                contributor_name: "شون فولي"
            },
            {
                id: 7,
                cover: book7,
                name: "الحرب و السلم",
                contributor_name: "ليف تولستوي"
            },
            {
                id: 8,
                cover: book8,
                name: "الحالة الحرجة للمدعو ك",
                contributor_name: "عزيز محمد"
            },
            {
                id: 9,
                cover: book9,
                name: "الليالي البيضاء",
                contributor_name: "دوستويفسكي"
            },
            {
                id: 10,
                cover: book10,
                name: "موت صغير",
                contributor_name: "محمد حسن علوان"
            }
        ]
    };
    const [list, setList] = useState(prefs?.lang === "ar" ? ITEM?.products : ITEM_EN?.products);

    const loadMore = () =>
    {
        setIsLoading(true);
        const pageList = [
            {
                id: 1,
                cover: book1,
                name: "شقة الحورية",
                contributor_name: "غازي القصيبي"
            },
            {
                id: 2,
                cover: book2,
                name: "جنوب الحدود غرب الشمس",
                contributor_name: "هاروكي موراكامي"
            }
        ];
        if (list?.length < 10)
        {
            setTimeout(() =>
            {
                setList(list?.concat(pageList));
                setIsLoading(false);
            }, 1500);
        }
        else
        {
            setShouldLoadMore(false);
            setIsLoading(false);
        }
    };

    const CATEGORY = [
        {
            title: "الجميع",
            key: "all"
        },
        {
            title: "التصنيف",
            key: "category"
        },
        {
            title: "الفئة العمرية",
            key: "age"
        }
    ];

    const VALUES = {
        category: [
            {
                title: "الجميع",
                key: "all"
            },
            {
                title: "رواية",
                key: "poetry"
            },
            {
                title: "تاريخ",
                key: "history"
            }
        ],
        age: [
            {
                title: "الجميع",
                key: "all"
            },
            {
                title: "أطفال",
                key: "kids"
            },
            {
                title: "بالغين",
                key: "adults"
            }
        ]
    };

    const onClickFilterList = () =>
    {
        navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/catalog/${filterType.key ? filterType.key : "all"}${filterValue?.key ? `/${filterValue?.key}` : ""}`);
    };

    useEffect(() =>
    {
        setIsLoading(true);
        setTimeout(() =>
        {
            setIsLoading(false);
        }, 1500);
    }, [params?.filterType, params?.filterValue]);

    useEffect(() =>
    {
        setFilterValue({});
    }, [filterType]);

    return (
        <Section>
            {isLoading && <Loader />}
            <div className="relative pt-[60%] sm:pt-[40%] md:pt-[35%] lg:pt-[20%]">
                <div className="flex justify-end items-center gap-grid-md">
                    <DropdownMenu
                        list={CATEGORY}
                        labelKey="title"
                        placeholder={strings("placeholder.filterType")}
                        selectedValue={filterType}
                        setSelected={setFilterType}
                        dropdownBG="bg-secondary-lightest"
                    />
                    <DropdownMenu
                        list={filterType?.key === "all" ? [] : VALUES[filterType?.key]}
                        labelKey="title"
                        placeholder={strings("placeholder.filterValue")}
                        selectedValue={filterValue}
                        setSelected={setFilterValue}
                        dropdownBG="bg-secondary-lightest"
                    />
                    <Button
                        text={strings("button.filterList")}
                        bgColor={"bg-accent-regular"}
                        hoverBGColor={"hover:bg-transparent"}
                        textColor={"text-accent-lightest"}
                        hoverTextColor={"hover:text-accent-regular"}
                        border={"border-2 border-transparent"}
                        hoverBorder={"hover:border-2 hover:border-accent-regular"}
                        padding="py-2 px-4"
                        onClick={onClickFilterList}
                    />
                </div>
                <div className="mx-grid-lg md:mx-auto md:container my-grid-xxl">
                    <p className="text-xl font-primary text-primary-lightest">
                        {strings(`common.booksDirectory`)}
                    </p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-grid-xl sm:gap-grid-sm md:gap-grid-xl py-grid-lg justify-items-center md:justify-start lg:justify-center">
                        {list?.map((product, index) => (
                            <ChartContestantSmall
                                key={index}
                                item={product}
                                containerClassName="min-h-[400px] max-w-[280px] max-h-[400px] p-grid-lg flex flex-col justify-between items-center bg-primary-lightest rounded-sm cursor-pointer shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]"
                                imgClassName="w-[150px] h-[210px]"
                                textClassName="pt-grid-xs text-center"
                            />
                        ))}
                    </div>
                </div>
                {shouldLoadMore &&
                (
                    <div className="text-center my-grid-xxl">
                        <Button
                            text={strings("common.loadMore")}
                            bgColor={"bg-transparent"}
                            hoverBGColor={"hover:bg-accent-lightest"}
                            textColor={"text-accent-lightest"}
                            hoverTextColor={"hover:text-primary-regular"}
                            border={"border-2 border-accent-lightest"}
                            hoverBorder={"hover:border-2 hover:border-transparent"}
                            onClick={loadMore}
                        />
                    </div>
                )}
            </div>
        </Section>
    );
};

export default Catalog;