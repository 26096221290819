import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import DropdownMenu from "../view/component/dropdownMenu";
import Button from "../view/component/button";
import Loader from "../view/component/loader";

import { SessionContext } from "../util/context";
import { getLangCountryURL, strings } from "../util/formatter";

const Directory = () =>
{
    const { prefs, setActiveModal, setModalOptions } = useContext(SessionContext);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const DIRECTORY = [
        {
            id: 1,
            name: "شركة ميلاد للنشر و التوزيع"
        },
        {
            id: 2,
            name: "دار رواشن للنشر و التوزيع"
        },
        {
            id: 3,
            name: "دار أثر للنشر و التوزيع"
        },
        {
            id: 4,
            name: "منشورات تكوين"
        }
    ];
    const [selected, setSelected] = useState({});

    useEffect(() =>
    {
        if (Object.keys(selected)?.length > 0)
        {
            setIsLoading(true);
            setTimeout(() =>
            {
                setIsLoading(false);
                navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/overview`);
            }, 1500);
        }
    }, [selected]);

    return (
        <div className="h-[calc(100vh-182px)] md:h-[calc(100vh-132px)] flex justify-center items-center">
            {isLoading && <Loader />}
            <div className="w-[350px] flex flex-col gap-grid-md">
                <DropdownMenu
                    list={DIRECTORY}
                    labelKey="name"
                    placeholder={strings("placeholder.selectEntity")}
                    selectedValue={selected}
                    setSelected={setSelected}
                    shadow="shadow-[0_20px_20px_0_rgba(0,0,0,0.3)]"
                    dropdownBG="bg-secondary-lightest"
                />
                <Button
                    text={strings("button.openNewEntityAccount")}
                    onClick={() =>
                    {
                        setActiveModal("createDirectory");
                        setModalOptions({
                            showStepsBar: true,
                            currentStep: 1,
                            totalSteps: 3,
                            title: strings("title.newEntity")
                        });
                    }}
                    bgColor="bg-secondary-darkest"
                    hoverTextColor="hover:text-primary-lightest"
                    hoverBorder="hover:border-2 hover:border-primary-lightest"
                    font="text-md"
                    padding="py-grid-xs pe-grid-xs ps-grid-sm"
                    optionalStyle="text-center shadow-[0_20px_20px_0_rgba(0,0,0,0.3)]"
                />
            </div>
        </div>
    );
};

export default Directory;