import React, { useEffect, useContext } from "react";

import Section from "../view/component/section";
import { getLangCountryURL } from "../util/formatter";

import { SessionContext } from "../util/context";

const ErrorHandler = () =>
{
    const { prefs } = useContext(SessionContext);
    useEffect(() =>
    {
        window.location = (`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/error/404`);
    }, []);

    return (
        <Section/>
    );
};

export default ErrorHandler;