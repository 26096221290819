import constant from "../config/constant";
import i18next from "i18next";

const LocalStorage =
{
    init: () =>
    {
        localStorage.setItem("access_token", "");
        localStorage.setItem("refresh_token", "");
        localStorage.setItem("lang", constant?.lang?.default);
        localStorage.setItem("country_code", constant?.country?.default);
        localStorage.setItem("dir", constant?.direction?.default);
        localStorage.setItem("dir_alt", constant?.direction?.alternative);
        localStorage.setItem("theme", constant?.theme?.default);
        localStorage.setItem("currency", constant?.currency?.default);
        localStorage.setItem("is_logged_in", "0");
    },

    set: async (key, value) =>
    {
        if (key === "lang")
        {
            localStorage.setItem(key, value === constant.lang.values[0] ? constant.lang.values[0] : constant.lang.values[1]);
            localStorage.setItem("dir", value === constant.lang.values[0] ? constant.direction.values[0] : constant.direction.values[1]);
            localStorage.setItem("dir_alt", value === constant.lang.values[0] ? constant.direction.values[1] : constant.direction.values[0]);
            i18next.changeLanguage(value === constant.lang.values[0] ? constant.lang.values[0] : constant.lang.values[1]);
            i18next.dir(value === constant.lang.values[0] ? constant.direction.values[0] : constant.direction.values[1]);
        }
        else if (key === "dir")
        {
            if (constant?.direction?.values?.indexOf(value?.toLowerCase()) === -1)
            {
                localStorage.setItem(key?.toLowerCase(), constant?.direction?.default);
                localStorage.setItem("dir_alt", constant?.alternative?.default);
            }
            else
            {
                localStorage.setItem(key?.toLowerCase(), value?.toLowerCase());

                if (value?.toLowerCase() === "ltr")
                {
                    localStorage.setItem("dir_alt", "rtl");
                }
                else
                {
                    localStorage.setItem("dir_alt", "ltr");
                }
            }
        }
        else if (key === "theme")
        {
            if (constant?.theme?.values?.indexOf(value?.toLowerCase()) === -1)
            {
                localStorage.setItem(key?.toLowerCase(), constant?.theme?.default);
            }
            else
            {
                localStorage.setItem(key?.toLowerCase(), value?.toLowerCase());
            }
        }
        else if (key === "currency" && constant?.currency?.values?.indexOf(value?.toLowerCase()) === -1)
        {
            if (constant?.currency?.values?.indexOf(value?.toLowerCase()) === -1)
            {
                localStorage.setItem(key?.toLowerCase(), constant?.currency?.default);
            }
            else
            {
                localStorage.setItem(key?.toLowerCase(), value?.toLowerCase());
            }
        }
        else if (key === "is_logged_in")
        {
            if (["0", "1"]?.indexOf(value?.toLowerCase()) === -1)
            {
                localStorage.setItem(key?.toLowerCase(), "0");
            }
            else
            {
                localStorage.setItem(key?.toLowerCase(), value);
            }
        }
        else
        {
            localStorage.setItem(key?.toLowerCase(), value);
        }
    },

    unset: async (key) =>
    {
        localStorage.removeItem(key);
    },

    get: async (key) =>
    {
        return (localStorage.getItem(key) !== undefined && localStorage.getItem(key) !== null) ? localStorage.getItem(key) : undefined;
    },

    destroy: async () =>
    {
        localStorage.clear();

        this.init();
    }
};

export default LocalStorage;