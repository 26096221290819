import React, { useContext, useState } from "react";

import SVGIcon from "./svgIcons";

import { getDate, strings } from "../../util/formatter";
import { SessionContext } from "../../util/context";

export const SUBSCRIPTION_TYPE = [
    {
        id: 1,
        label: "مراقبة حركة المنافسات",
        price: "100 رس",
        period: "شهريًا"
    },
    {
        id: 2,
        label: "إدارة المنشورات",
        price: "100 رس",
        period: "شهريًا"
    },
    {
        id: 3,
        label: "إدارة المبيعات",
        price: "100 رس",
        period: "شهريًا"
    },
    {
        id: 4,
        label: "استعراض المبيعات",
        price: "100 رس",
        period: "شهريًا"
    }
];

const ManageEntitySubscriptions = () =>
{
    const { prefs } = useContext(SessionContext);
    const [listOfSelected, setListOfSelected] = useState([{
        id: 3,
        label: "إدارة المبيعات",
        price: "100 رس",
        period: "شهريًا"
    }]);

    const selectSubscription = (item) =>
    {
        const arr = listOfSelected;
        if (arr?.some(selected => selected.id === item.id))
        {
            arr?.splice(arr?.map(e => e?.id)?.indexOf(item.id), 1);
            setListOfSelected([...arr]);
        }
        else
        {
            setListOfSelected([...listOfSelected, item]);
        }
    };
    return (
        <>
            <p className="text-md mb-grid-sm">
                {strings("common.subscriptionExpiresIn")?.replace("{DAYS}", 16)}
            </p>
            <p className="text-md">
                {strings("common.subscriptionRenewedOn")?.replace("{DATE}", getDate(prefs?.lang, Date.now()))?.replace("{CARD_NUMBER}", 2034)}
            </p>
            <div className="h-[1px] bg-primary-light my-grid-md"/>
            <div className="inline-block">
                {SUBSCRIPTION_TYPE.map((item, index) => (
                    <div key={index} className="mb-grid-md flex justify-start items-center gap-grid-sm">
                        <div
                            className={`w-[20px] h-[20px] ${listOfSelected?.some(selected => selected.id === item.id) ? "bg-secondary-darkest" : "bg-secondary-light"} cursor-pointer`}
                            onClick={() => selectSubscription(item)}
                        >
                            {listOfSelected?.some(selected => selected.id === item.id) &&
                                <SVGIcon name="checkMark" width={20} height={20} viewBox="-2 1 18 8" pathFill="fill-primary-lightest"/>
                            }
                        </div>
                        <div className="text-start">
                            {`${item.label} (${item.price} ${item.period})`}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default ManageEntitySubscriptions;