import React, { useContext } from "react";

import SVGIcon from "./svgIcons";
import Button from "./button";

import { SessionContext } from "../../util/context";

const InfoCard = (props) =>
{
    const { prefs } = useContext(SessionContext);
    const { item, buttonText, buttonAction } = props;
    return (
        <div className={`min-w-[330px] min-h-[410px] max-w-[330px] max-h-[410px] flex flex-col justify-between p-grid-md bg-primary-lightest text-secondary-darkest text-lg rounded-lg shadow-[0_30px_30px_0_rgba(0,0,0,0.1)]`}>
            <div>
                <div className="w-[50px] h-[50px] flex justify-center items-center bg-primary-light rounded-sm shadow-[0_0_50px_0_rgb(var(--color-primary-light))]">
                    <SVGIcon name={item?.iconName || "shop"} stroke="stroke-secondary-darkest"/>
                </div>
                <p className="pt-grid-md font-primary text-secondary-darkest">
                    {item?.title || "العنوان هنا"}
                </p>
                <p className="text-base py-grid-md font-secondary text-secondary-darkest">
                    {item?.description || "النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص "}
                </p>
            </div>
            <div className="flex justify-end">
                <Button
                    text={buttonText}
                    onClick={buttonAction}
                    icon="arrow"
                    font="text-md"
                    padding="py-grid-xxs pe-grid-xs ps-grid-sm"
                    iconStyle={`${prefs.dir === "rtl" ? "" : "rotate-180"} fill-none`}
                    optionalStyle="flex items-center gap-grid-md"
                />
            </div>
        </div>
    );
};

export default InfoCard;