import React from "react";

import LoadingSkeleton from "./loadingSkeleton";

import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";

const PieChartView = (props) =>
{
    const { loading = false, data, labelKey, valueKey, colors, showLegends = true } = props;
    return (
        <ResponsiveContainer width={"100%"} height={220 + (data?.length ? (data?.length * 23) : 0)}>
            {
                loading
                ? <LoadingSkeleton rows="8" rowHeight="h-[21px]" rowWidth = "w-full"/>
                : <PieChart>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={CustomizedLabel}
                        innerRadius={50}
                        outerRadius={90}
                        dataKey={valueKey}
                    >
                        {data?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors?.length]}/>
                        ))}
                    </Pie>
                    {showLegends
                        ? <Legend
                            verticalAlign="bottom"
                            horizontalAlign="center"
                            height={data?.length * 25}
                            content={<CustomizedLegend label={labelKey} value={valueKey}/>}
                        />
                        : null
                    }
                </PieChart>
            }
        </ResponsiveContainer>
    );
};

const CustomizedLabel = ({ cx, cy, startAngle, endAngle, innerRadius, outerRadius, percent }) =>
{
    const midAngle = (startAngle + endAngle) / 2;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

    return (
        <text x={x} y={y} className={`font-secondary`} fill="#fff" fontSize={8} textAnchor="middle" dominantBaseline="middle">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const CustomizedLegend = ({ payload, label, value }) =>
{
    const columns = 5; // Number of columns

    const columnsArray = Array.from({ length: Math.ceil(payload.length / columns) }, (_, index) =>
        payload?.slice(index * columns, index * columns + columns)
    );

    return (
        <div className="pt-grid-xl flex md:block gap-grid-md">
            {columnsArray?.map((column, columnIndex) => (
                <div key={columnIndex} className="flex flex-col">
                    {column.map((entry, rowIndex) => (
                        <div key={rowIndex} className="mb-grid-xs flex items-center gap-grid-xs">
                            <div className="w-[8px] h-[8px]" style={{ backgroundColor: entry?.color }}></div>
                            <div className="text-start text-sm text-neutral-low-dark transition-colors duration-fast ease-in">
                                {entry?.payload?.[label]}&nbsp;({entry?.payload?.[value]})
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default PieChartView;