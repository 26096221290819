import React, { useContext } from "react";

import { Link, useLocation } from "react-router-dom";

import CachedImg from "./cachedImg";

import { SessionContext } from "../../util/context";
import constant from "../../config/constant";
import { getLangCountryURL, strings } from "../../util/formatter";

import FACEBOOK from "../../assets/image/social_icons_facebook.png";
import X from "../../assets/image/social_icons_x.png";
import INSTAGRAM from "../../assets/image/social_icons_instagram.png";
import LINKEDIN from "../../assets/image/social_icons_linkedin.png";
import SNAPCHAT from "../../assets/image/social_icons_snapchat.png";
import TIKTOK from "../../assets/image/social_icons_tiktok.png";
import YOUTUBE from "../../assets/image/social_icons_youtube.png";

const Footer = () =>
{
    const { prefs, setUserPersona, setActiveModal, modalOptions, setModalOptions } = useContext(SessionContext);
    const location = useLocation();
    const FOOTER_SECTION = [
        {
            title: "title.myAccount",
            sortId: 0,
            items: [
                {
                    key: "signup",
                    isModal: true,
                    path: "signup",
                    title: "title.createProducerAccount",
                    userPersona: "producer",
                    sortId: 0
                },
                {
                    key: "signup",
                    isModal: true,
                    path: "signup",
                    title: "title.createDistributorAccount",
                    userPersona: "distributor",
                    sortId: 1
                },
                {
                    key: "signup",
                    isModal: true,
                    path: "signup",
                    title: "title.createSponsorAccount",
                    userPersona: "sponsor",
                    sortId: 2
                },
                {
                    key: "login",
                    isModal: true,
                    path: "login",
                    title: "title.login",
                    sortId: 3
                },
                {
                    key: "resetPassword",
                    isModal: true,
                    path: "resetPassword",
                    title: "title.resetPassword",
                    sortId: 4
                }
            ]
        },
        {
            title: "title.policies",
            sortId: 1,
            items: [
                {
                    key: "privacyPolicy",
                    isModal: false,
                    path: "page/privacy-policy",
                    title: "title.privacyPolicy",
                    sortId: 0
                },
                {
                    key: "usageAgreement",
                    isModal: false,
                    path: "page/usage-agreement",
                    title: "title.usageAgreement",
                    sortId: 1
                },
                {
                    key: "publishersAgreement",
                    isModal: false,
                    path: "page/publisher-agreement",
                    title: "title.publishersAgreement",
                    sortId: 2
                },
                {
                    key: "distributorsAgreement",
                    isModal: false,
                    path: "page/distributor-agreement",
                    title: "title.distributorsAgreement",
                    sortId: 3
                },
                {
                    key: "sponsorsAgreement",
                    isModal: false,
                    path: "page/sponsor-agreement",
                    title: "title.sponsorsAgreement",
                    sortId: 3
                }
            ]
        },
        {
            title: "title.information",
            sortId: 2,
            items: [
                {
                    key: "competitionCriteria",
                    isModal: false,
                    path: "page/classification-competition-criteria",
                    title: "title.competitionCriteria",
                    sortId: 0
                },
                {
                    key: "auditingAndComplianceAudit",
                    isModal: false,
                    path: "page/compliance-audit-standards",
                    title: "title.auditingAndComplianceAudit",
                    sortId: 1
                },
                {
                    key: "informationSecurity",
                    isModal: false,
                    path: "page/information-security",
                    title: "title.informationSecurity",
                    sortId: 2
                },
                {
                    key: "FAQ",
                    isModal: false,
                    path: "page/FAQ",
                    title: "title.FAQ",
                    sortId: 3
                }
            ]
        },
        {
            title: "title.developers",
            sortId: 3,
            items: [
                {
                    key: "developementGuide",
                    isModal: false,
                    path: "page/developement-guide",
                    title: "title.developementGuide",
                    sortId: 3
                },
                {
                    key: "linkingForms",
                    isModal: false,
                    path: "page/linking-forms",
                    title: "title.linkingForms",
                    sortId: 3
                }
            ]
        }
    ];

    const SOCIAL_APP = [
        {
            key: "linkedIn",
            path: "https://linkedin.com/company/mofahres/about/",
            img: LINKEDIN,
            sortId: 0
        },
        {
            key: "x",
            path: "https://x.com/mofahres_sa",
            img: X,
            sortId: 1
        },
        {
            key: "instagram",
            path: "https://instagram.com/mofahres_sa",
            img: INSTAGRAM,
            sortId: 2
        },
        {
            key: "snapchat",
            path: "https://snapchat.com/add/mofahres_sa",
            img: SNAPCHAT,
            sortId: 3
        },
        {
            key: "tiktok",
            path: "https://tiktok.com/@mofahres_sa",
            img: TIKTOK,
            sortId: 4
        },
        {
            key: "youtube",
            path: "https://youtube.com/@mofahres_sa",
            img: YOUTUBE,
            sortId: 5
        },
        {
            key: "facebook",
            path: "https://facebook.com/mofahres.sa",
            img: FACEBOOK,
            sortId: 6
        }
    ];
    return (
        <div className={`w-full transition-colors duration-middle ease-in text-primary-lightest`}>
            {!constant.hide_footer_details_path?.some(path => location.pathname.includes(path)) &&
                <div className="w-full lg:container lg:max-w-screen-lg px-grid-lg lg:px-0 lg:mx-auto flex justify-start lg:gap-grid-xl xl:gap-grid-xxl py-grid-xxl">
                    <div className="w-full flex flex-col lg:flex-row gap-grid-lg lg:gap-0 justify-between font-primary text-lg">
                        {FOOTER_SECTION?.map((section, index) => (
                            <div key={index} className={`${prefs?.lang === constant.lang.values[0] ? "pt-[3px]" : "pt-[6px]"}`}>
                                {strings(section.title)}
                                <hr className="w-[60px] h-grid-xxs mb-grid-md mt-grid-xs bg-primary-darkest border-none rounded-full"/>
                                {section?.items?.map((subSection, subIndex) => (
                                    <div key={subIndex} className="font-secondary text-md pb-grid-sm">
                                        {subSection?.isModal
                                            ? <div
                                                className="transition-opacity duration-fast hover:opacity-70 cursor-pointer"
                                                onClick={() =>
                                                {
                                                    if (subSection?.userPersona) setUserPersona(subSection?.userPersona);
                                                    setActiveModal(subSection?.key);
                                                    setModalOptions({ ...modalOptions, title: strings(`title.${subSection?.key}`) });
                                                }}
                                            >
                                                {strings(subSection.title)}
                                            </div>
                                            : <Link
                                                className="transition-opacity duration-fast hover:opacity-70"
                                                to={`${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${subSection.path}`}
                                            >
                                                {strings(subSection.title)}
                                            </Link>
                                        }
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            }
            <div className="py-grid-sm bg-primary-dark">
                <div className="w-full lg:container lg:max-w-screen-lg px-grid-lg lg:px-0 lg:mx-auto flex flex-col md:flex-row gap-grid-lg md:gap-0 justify-between items-center text-sm">
                    {strings("common.madeInRiyadh")}
                    <div className="flex gap-grid-sm">
                        {SOCIAL_APP?.map((app, index) => (
                            <Link key={index} target='_blank' to={app.path} className="w-[30px] h-[30px] flex justify-center items-center transition-opacity duration-fast rounded-full bg-primary-darkest hover:opacity-70">
                                <CachedImg src={app.img} alt={strings("mofahres")} className="w-[13px]"/>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;