import React, { useState } from "react";

import LineMsg from "./lineMsg";
import Label from "./label";
import Input from "./input";
import DropdownMenu from "./dropdownMenu";

import { strings } from "../../util/formatter";

export const TYPE = [
    {
        id: 1,
        name: "منشأة حكومية"
    },
    {
        id: 2,
        name: "منشأة خاصة"
    }
];

const ManageEntityDetails = () =>
{
    const [nameAR, setNameAR] = useState("");
    const [nameEN, setNameEN] = useState("");
    const [type, setType] = useState({});

    return (
        <>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.entityNameAR")}/>
                <Input
                    name="nameAR"
                    value={nameAR}
                    setValue={setNameAR}
                />
                <LineMsg type="error"/>
            </div>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.entityNameEN")}/>
                <Input
                    name="nameEN"
                    value={nameEN}
                    setValue={setNameEN}
                />
                <LineMsg type="error"/>
            </div>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.entityType")}/>
                <DropdownMenu
                    list={TYPE}
                    labelKey="name"
                    placeholder={strings("placeholder.selectType")}
                    selectedValue={type}
                    setSelected={setType}
                />
                <LineMsg type="error"/>
            </div>
        </>
    );
};

export default ManageEntityDetails;