import React, { useContext, useEffect, useRef } from "react";
import SearchInput from "./searchInput";
import { useInView } from "framer-motion";
import { SessionContext } from "../../util/context";

const Section2 = () =>
{
    const { setShowSearchInputInHeader } = useContext(SessionContext);
    const boxRef = useRef(null);
    const isInView = useInView(boxRef, {
        margin: "-154px 0px 4000px 0px"
    });

    useEffect(() =>
    {
        setShowSearchInputInHeader(isInView);
    }, [isInView]);

    return (
        <div className="relative pb-[100px]">
            <div className="w-full lg:container px-grid-lg md:px-0 lg:mx-auto py-grid-md">
                <SearchInput/>
                <div ref={boxRef}/>
            </div>
        </div>
    );
};

export default Section2;