import React from "react";
import Login from "../controller/login";
import Signup from "../controller/signup";
import ResetPassword from "../controller/resetPassword";
import OTP from "../controller/otp";
import CreateDirectory from "../controller/createDirectory";
import Subscription from "../controller/subscription";
import ProductModal from "../controller/productModal";
import ContributorModal from "../controller/contributorModal";
import ProducerModal from "../controller/producerModal";
import DistributorModal from "../controller/distributorModal";
import AddProductModal from "../controller/addProductModal";
import ShareProduct from "../controller/shareProduct";
import DownloadProduct from "../controller/downloadProduct";
import NewOutlet from "../controller/newOutlet";
import UploadReport from "../controller/uploadReport";
import UserAccount from "../controller/userAccount";
import ManageEntity from "../controller/manageEntity";
import DisableEntityAccount from "../controller/disableEntityAccount";
import AddContributorModal from "../controller/addContributorModal";
import NotificationModal from "../controller/notificationModal";

const MODAL = {
    login: <Login/>,
    signup: <Signup/>,
    resetPassword: <ResetPassword/>,
    otp: <OTP/>,
    createDirectory: <CreateDirectory/>,
    subscription: <Subscription/>,
    product: <ProductModal/>,
    contributor: <ContributorModal/>,
    producer: <ProducerModal/>,
    distributor: <DistributorModal/>,
    addProduct: <AddProductModal/>,
    shareProduct: <ShareProduct/>,
    downloadProduct: <DownloadProduct/>,
    newOutlet: <NewOutlet/>,
    uploadReport: <UploadReport />,
    userAccount: <UserAccount />,
    manageEntity: <ManageEntity />,
    disableEntityAccount: <DisableEntityAccount />,
    addContributor: <AddContributorModal />,
    notification: <NotificationModal/>
};

export default MODAL;