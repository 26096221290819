import React from "react";

import SVGIcon from "./svgIcons";
import LoadingSkeleton from "./loadingSkeleton";

const DataCardWithIcon = (props) =>
{
    const { loading = false, icon, iconViewBox, label, value } = props;

    return (
        <div className={`p-grid-sm flex flex-col gap-grid-sm justify-center items-center bg-neutral-high-regular text-neutral-low-dark transition-colors duration-fast ease-in text-md rounded-sm`}>
            <div className="p-grid-sm bg-neutral-high-dark transition-colors duration-fast ease-in rounded-full">
                <SVGIcon name={icon} width={16} height={16} viewBox={iconViewBox} stroke={"stroke-neutral-low-dark"} className={`${icon === "circleDollar" ? "fill-neutral-low-dark" : "fill-none"}`}/>
            </div>
            <p className="font-primary">
                {label}
            </p>
            {loading
            ? <LoadingSkeleton rows="1" rowHeight="h-[21px]" rowWidth = "w-28"/>
            : <p>{value}</p>
            }
        </div>
    );
};

export default DataCardWithIcon;