import React, { useContext, useState } from "react";

import Button from "../view/component/button";
import LineMsg from "../view/component/lineMsg";
import Input from "../view/component/input";
import Label from "../view/component/label";
import DropdownMenu from "../view/component/dropdownMenu";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";

export const NATIONALITY = [
    {
        label: "سعودي",
        value: "saudi"
    },
    {
        label: "إماراتي",
        value: "emirati"
    }
];

const AddContributorModal = () =>
{
    const { setActiveModal } = useContext(SessionContext);
    const [nameAR, setNameAR] = useState("");
    const [nameEN, setNameEN] = useState("");
    const [nationality, setNationality] = useState({});

    const next = () =>
    {
        setActiveModal(undefined);
    };

    return (
        <>
            <div className="flex flex-col sm:flex-row justify-between sm:gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.nameAR")}/>
                    <Input
                        name="nameAR"
                        value={nameAR}
                        setValue={setNameAR}
                    />
                    <LineMsg type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.nameEN")}/>
                    <Input
                        name="nameEN"
                        value={nameEN}
                        setValue={setNameEN}
                    />
                    <LineMsg type="error"/>
                </div>
            </div>
            <div className="w-full pb-grid-sm">
                <Label label={`${strings("label.nationality")} ${strings("label.optional")}`}/>
                <DropdownMenu
                    list={NATIONALITY}
                    labelKey="label"
                    placeholder={strings("placeholder.selectNationality")}
                    selectedValue={nationality}
                    setSelected={setNationality}
                />
                <LineMsg type="error"/>
            </div>
            <div className={`flex justify-end pt-grid-lg`}>
                <Button
                    text={strings("button.next")}
                    padding="py-2 px-grid-xl"
                    onClick={next}
                />
            </div>
        </>
    );
};

export default AddContributorModal;