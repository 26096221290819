import React, { useContext } from "react";

import LoadingSkeleton from "./loadingSkeleton";

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, Cell } from "recharts";

import { SessionContext } from "../../util/context";
import constant from "../../config/constant";

const BarChartView = (props) =>
{
    const { prefs } = useContext(SessionContext);
    const { loading = false, data, labelKey, valueKey, showLegends = false } = props;

    return (
        <ResponsiveContainer width="100%" height={250}>
            {
                loading
                ? <LoadingSkeleton rows="5" rowHeight="h-[21px]" rowWidth = "w-full"/>
                : <BarChart
                    data={data}
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                >
                    <CartesianGrid strokeDasharray="3 3" width="100%" />
                    <XAxis dataKey={labelKey} tick={{ fill: prefs?.theme === "dark" ? "#fff" : "#000" }}/>
                    <YAxis dataKey={valueKey} tick={<CustomizedYAxisTick prefs={prefs}/>} />
                    {/* <Tooltip content={<CustomTooltip />} /> */}
                    <Bar dataKey={valueKey} barSize={15}>
                        {data?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={constant?.chartColors[index % 20]} />
                        ))}
                    </Bar>
                    {(showLegends === true)
                        ? <Legend
                            layout="vertical"
                        />
                        : null
                    }
                </BarChart>
            }
        </ResponsiveContainer>
    );
};

const CustomizedYAxisTick = ({ x, y, payload }) =>
{
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0}
                textAnchor="start"
                fill="#666">{new Intl.NumberFormat("en").format(payload.value)}</text>
        </g>
    );
};

// const CustomTooltip = ({ active, payload, label }) =>
// {
//     if (active && payload && payload.length)
//     {
//         return (
//             <div className="color-background-bg p-1" style={{ border: "1px solid #000" }}>
//                 <p className="label">{`${label} (${payload[0]?.payload?.[value]})`}</p>
//             </div>
//         );
//     }
// };

export default BarChartView;