import React, { useContext, useState } from "react";

import Button from "../view/component/button";
import Tabs from "../view/component/tabs";
import ManageEntityDetails from "../view/component/manageEntityDetails";
import ManageEntityUsers from "../view/component/manageEntityUsers";
import ManageEntityLegal from "../view/component/manageEntityLegal";
import ManageEntitySubscriptions from "../view/component/manageEntitySubscriptions";
import ManageEntityIntegration from "../view/component/manageEntityIntegration";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";

const ENTITY = {
    users: [
        {
            id: 1,
            name: "اياد عبدالرحمن",
            type: {
                label: "مالك",
                value: "owner"
            }
        },
        {
            id: 2,
            name: "بسام العتيبي",
            type: {
                label: "مشرف",
                value: "admin"
            }
        },
        {
            id: 3,
            name: "عبدالعزيز الثقفي",
            type: {
                label: "مشرف",
                value: "admin"
            }
        }
    ]
};

const ManageEntity = () =>
{
    const { setActiveModal } = useContext(SessionContext);
    const [active, setActive] = useState("details");
    const [entity, setEntity] = useState(ENTITY);

    const save = () =>
    {
        setActiveModal(undefined);
    };

    return (
        <>
            <Tabs
                active={active}
                setActive={setActive}
                isModal={true}name
                list={[
                    { key: "details", label: strings("common.details") },
                    { key: "legalEntity", label: strings("common.legalEntity") },
                    { key: "users", label: strings("common.users") },
                    { key: "subscriptions", label: strings("common.subscriptionsAndPayment") },
                    { key: "integration", label: strings("common.integration") }
                ]}
                shouldShowAll={false}
            >
                <div className="p-grid-md text-neutral-low-darkest font-secondary">
                    {active === "details"
                        ? <ManageEntityDetails />
                        : active === "legalEntity"
                        ? <ManageEntityLegal />
                        : active === "users"
                        ? <ManageEntityUsers entity={entity} setEntity={setEntity}/>
                        : active === "subscriptions"
                        ? <ManageEntitySubscriptions />
                        : <ManageEntityIntegration />
                    }
                </div>
            </Tabs>
            <div className="flex justify-end pt-grid-lg mx-grid-md pb-grid-md">
                <Button
                    text={strings("button.save")}
                    padding="py-2 px-grid-xl"
                    onClick={save}
                />
            </div>
        </>
    );
};

export default ManageEntity;