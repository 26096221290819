import React, { useContext } from "react";

import DropdownMenu from "../component/dropdownMenu";
import DatePickerButton from "./datePickerButton";

import { SessionContext } from "../../util/context";
import { strings } from "../../util/formatter";

const ListOfSales = (props) =>
{
    const { isPostLogin } = useContext(SessionContext)
    const { list, totalSales, filterType = "none", filterList = [], filterValue, setFilterValue } = props;

    return (
        <>
            {filterType === "none"
                ? null
                : <div className="flex justify-end mx-grid-md md:mx-0 py-grid-lg pb-grid-md px-grid-lg">
                    <div className="w-1/3">
                        {filterType === "date"
                        ? <DatePickerButton
                            value={filterValue}
                            setValue={setFilterValue}
                        />
                        : <DropdownMenu
                            list={filterList}
                            labelKey="name"
                            selectedValue={filterValue}
                            setSelected={setFilterValue}
                        />
                        }
                    </div>
                </div>
            }
            <div className={`p-grid-md ${isPostLogin ? "text-neutral-low-darkest" : "text-secondary-darkest"} divide-y divide-solid divide-primary-light`}>
                {list.map((item, index) => (
                    <div key={index} className="p-grid-sm flex gap-grid-sm items-center justify-between text-sm">
                        <div className="flex gap-grid-xs items-center">
                            {item?.name}
                        </div>
                        <div className="flex items-center">
                            {`${item?.sales} ${strings("common.version")}`}
                        </div>
                    </div>
                ))}
                <div className="p-grid-sm text-sm text-end font-primary">
                    {`${totalSales} ${strings("common.version")}`}
                </div>
            </div>
        </>
    );
};

export default ListOfSales;