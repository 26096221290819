import React, { useContext, useRef } from "react";

import DateRange from "./dateRange";
import ShowAll from "./showAll";
import ChartContestantSmall from "./chartContestantSmall";

import { SessionContext } from "../../util/context";
import { getLangCountryURL, strings } from "../../util/formatter";
import useMediaQuery from "../../hook/useMediaQuery";

import publisher6 from "../../assets/image/publishers/publisher6.jpg";
import publisher7 from "../../assets/image/publishers/publisher7.webp";
import publisher8 from "../../assets/image/publishers/publisher8.webp";
import publisher9 from "../../assets/image/publishers/publisher9.webp";
import publisher10 from "../../assets/image/publishers/publisher10.png";

const Section5 = () =>
{
    const { prefs } = useContext(SessionContext);
    const targetRef = useRef(null);
    const isSmall = useMediaQuery("(min-width: 640px) and (max-width: 767px)");
    const isMedium = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
    const producersList = [
        {
            id: 1,
            image: publisher6,
            name: "دار الساقي",
            rank: 1,
            rank_status: "up"
        },
        {
            id: 2,
            image: publisher7,
            name: "رشم للنشر والتوزيع",
            rank: 2,
            rank_status: "star"
        },
        {
            id: 3,
            image: publisher8,
            name: "تكوين",
            rank: 3,
            rank_status: "down"
        },
        {
            id: 4,
            image: publisher9,
            name: "حياة",
            rank: 4,
            rank_status: "up"
        },
        {
            id: 5,
            image: publisher10,
            name: "دار ميلاد",
            rank: 5,
            rank_status: "same"
        }
    ];

    return (
        <div ref={targetRef} className={`relative mb-grid-xxl mx-grid-lg md:mx-0 flex flex-col justify-start`}>
            <p className="pb-grid-sm text-xl font-primary text-primary-lightest">
                {strings("landingPage.fifthSectionHead")}
            </p>
            <div className="flex justify-between items-start">
                <DateRange dateFrom={"2023-08-13"} dateTo={"2023-08-19"} />
                <ShowAll route={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/charts/producer/all`}/>
            </div>
            <div className="mb-[10%] w-full bg-primary-lightest shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)] rounded-md">
                <div className="md:mx-0 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-grid-lg p-grid-lg justify-items-center md:justify-start lg:justify-center">
                    {producersList.slice(0, isMedium ? 4 : isSmall ? 3 : 5).map((item, index) =>
                        (
                            <ChartContestantSmall
                                key={index}
                                item={item}
                                containerClassName="min-w-[176px] max-w-[176px] h-[243px] flex flex-col justify-between bg-primary-light text-secondary-darkest p-grid-md cursor-pointer rounded-sm"
                                roundedImg={true}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default Section5;