import React, { useContext } from "react";

import { getDate } from "../util/formatter";
import { SessionContext } from "../util/context";

const NotificationModal = () =>
{
    const { prefs, modalOptions } = useContext(SessionContext);
    const { notifications } = modalOptions;

    return (
        notifications?.length > 0 &&
        notifications?.map((item, index) => (
            <div key={index} className="">
               <div className="pb-1 flex justify-between text-base">
                <p className="font-primary">
                    {item?.title}
                </p>
                <p>
                    {getDate(prefs?.lang, item?.creation_date)}
                </p>
                </div>
                <p className="text-base">
                    {item?.description}
                </p>
                {index !== notifications?.length - 1 &&
                    <hr className="w-full h-[1px] my-grid-md bg-neutral-high-regular border-none"/>
                }
            </div>
        ))
    );
};

export default NotificationModal;