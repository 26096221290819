import React, { useContext, useState } from "react";

import dayjs from "dayjs";

import Table from "../view/component/table";
import DropdownMenu from "../view/component/dropdownMenu";
import DateRangeByFilter from "../view/component/dateRangeByFilter";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";

export const data = [
    { id: 1, currentPosition: 1, name: "شقة الحورية", totalSales: 25, prevouisPosition: "1", durationInCurrentPosition: "اسبوع", durationInCompetition: "6 أسابيع" },
    { id: 1, currentPosition: 2, name: "جنوب الحدود غرب الشمس", totalSales: 12, prevouisPosition: "3", durationInCurrentPosition: "4 أسابيع", durationInCompetition: "اسبوع" },
    { id: 1, currentPosition: 3, name: "ثلاثية غرناطة", totalSales: 141, prevouisPosition: "4", durationInCurrentPosition: "2 أسابيع", durationInCompetition: "3 أسابيع" },
    { id: 1, currentPosition: 4, name: "قريب من البحر بعيد عن الزرقة", totalSales: 32, prevouisPosition: "2", durationInCurrentPosition: "3 أسابيع", durationInCompetition: "2 أسابيع" },
    { id: 1, currentPosition: 5, name: "الحرب و السلم", totalSales: 56, prevouisPosition: "5", durationInCurrentPosition: "اسبوع", durationInCompetition: "8 أسابيع" },
    { id: 1, currentPosition: 6, name: "السعودية المتغيرة", totalSales: 23, prevouisPosition: "9", durationInCurrentPosition: "8 أسابيع", durationInCompetition: "6 أسابيع" },
    { id: 1, currentPosition: 7, name: "الحالة الحرجة للمدعو ك", totalSales: 82, prevouisPosition: "7", durationInCurrentPosition: "8 أسابيع", durationInCompetition: "6 أسابيع" },
    { id: 1, currentPosition: 8, name: "الليالي البيضاء", totalSales: 10, prevouisPosition: "8", durationInCurrentPosition: "8 أسابيع", durationInCompetition: "6 أسابيع" },
    { id: 1, currentPosition: 9, name: "موت صغير", totalSales: 41, prevouisPosition: "10", durationInCurrentPosition: "8 أسابيع", durationInCompetition: "6 أسابيع" }
];

const DATE_RANGE =
[
    {
        label: "اسبوع",
        value: "week"
    },
    {
        label: "شهر",
        value: "month"
    },
    {
        label: "ربع سنوي",
        value: "quarter"
    },
    {
        label: "سنة",
        value: "year"
    }
];

const TrendProduct = () =>
{
    const { setActiveModal, setModalOptions } = useContext(SessionContext);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState(data);
    const [dateRangeValue, setDateRangeValue] = useState(DATE_RANGE?.[0]);
    const [date, setDate] = useState(dayjs().toDate());
    const maxPageNumber = 1;

    const loadMore = (type) =>
    {
        setIsLoading(true);
        const pageList = [
            { id: 1, currentPosition: 1, name: "الاسم", totalSales: 25, prevouisPosition: "23,234", durationInCurrentPosition: "8 أسابيع", durationInCompetition: "6 أسابيع" },
            { id: 1, currentPosition: 1, name: "الاسم", totalSales: 25, prevouisPosition: "23,234", durationInCurrentPosition: "8 أسابيع", durationInCompetition: "6 أسابيع" },
            { id: 1, currentPosition: 1, name: "الاسم", totalSales: 25, prevouisPosition: "23,234", durationInCurrentPosition: "8 أسابيع", durationInCompetition: "6 أسابيع" }
        ];
        if (type === "next")
        {
            setTimeout(() =>
            {
                setPageNumber(prevState => prevState + 1);
                setTableData(pageList);
                setIsLoading(false);
            }, 1500);
        }
        else
        {
            setTimeout(() =>
            {
                setPageNumber(prevState => prevState - 1);
                setTableData(data);
                setIsLoading(false);
            }, 1500);
        }
    };

    const openProductModal = (item) =>
    {
        setActiveModal("product");
        setModalOptions({
            item,
            title: item.name,
            containerPadding: "p-0"
        });
    };

    const onDateChange = (e) =>
    {
        setIsLoading(true);
        setDate(e)
        setTimeout(() =>
        {
            setIsLoading(false);
        }, 1500);
    };

    return (
        <div className="p-grid-md">
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-end gap-grid-md pt-grid-md pb-grid-lg">
                <p className="pb-grid-xs lg:pb-0 text-lg font-primary text-neutral-low-dark transition-colors duration-fast ease-in">
                    {strings("title.trendProduct")}
                </p>
                <div className="flex self-end items-center">
                    <DropdownMenu
                        list={DATE_RANGE}
                        labelKey="label"
                        selectedValue={dateRangeValue}
                        setSelected={setDateRangeValue}
                        gap="gap-grid-sm sm:gap-grid-xl"
                        wrapperStyle="me-grid-sm"
                    />
                    <DateRangeByFilter
                        isDisabled={isLoading}
                        showMonthYearPicker={dateRangeValue?.value === "month"}
                        showQuarterYearPicker={dateRangeValue?.value === "quarter"}
                        showWeekPicker={dateRangeValue?.value === "week"}
                        showYearPicker={dateRangeValue?.value === "year"}
                        showTimeSelect={false}
                        inputClassName="ps-grid-sm bg-neutral-high-regular hover:bg-transparent text-neutral-low-darkest border-2 border-transparent hover:border-neutral-low-darkest transition-colors duration-fast ease-in"
                        value={date}
                        onChange={onDateChange}
                    />
                </div>
            </div>
            <Table
                loading={isLoading}
                titles={tableData?.length > 0 ? Object.keys(tableData[0])?.filter(column => !["id"]?.includes(column)) : []}
                tableData={tableData?.filter(column => !["id"]?.includes(column))}
                responsive={
                    {
                        maxXL: ["currentPosition", "name", "totalSales", "prevouisPosition", "durationInCurrentPosition", "durationInCompetition"],
                        maxLG: ["currentPosition", "name", "totalSales", "prevouisPosition"],
                        maxMD: ["currentPosition", "name", "totalSales", "prevouisPosition"],
                        maxSM: ["currentPosition", "name", "totalSales"],
                        maxXS: ["currentPosition", "name", "totalSales"]
                    }
                }
                columnType={{ clickable: ["name"] }}
                onClickKeys={
                    {
                        name: openProductModal
                    }
                }
                pageNumber={pageNumber}
                maxPageNumber={maxPageNumber}
                loadMore={loadMore}
            />
        </div>
    );
};

export default TrendProduct;