import React, { useContext } from "react";

import Button from "../view/component/button";
import CreateDirectoryStep1 from "../view/component/createDirectoryStep1";
import CreateDirectoryStep2 from "../view/component/createDirectoryStep2";
import CreateDirectoryStep3 from "../view/component/createDirectoryStep3";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";

const CreateDirectory = () =>
{
    const { setActiveModal, modalOptions, setModalOptions } = useContext(SessionContext);

    const prev = () =>
    {
        setModalOptions({
            ...modalOptions,
            currentStep: modalOptions.currentStep - 1
        });
    };

    const next = () =>
    {
        if (modalOptions?.totalSteps === modalOptions?.currentStep)
        {
            setModalOptions({
                ...modalOptions,
                title: strings("title.subscriptions")
            });
            setActiveModal("subscription");
        }
        else
        {
            setModalOptions({
                ...modalOptions,
                currentStep: modalOptions.currentStep + 1
            });
        }
    };
    return (
        <>
            {modalOptions.currentStep === 1
                ? <CreateDirectoryStep1/>
                : modalOptions.currentStep === 2
                    ? <CreateDirectoryStep2/>
                    : <CreateDirectoryStep3/>
            }
            <div className={`flex ${modalOptions.currentStep !== 1 ? "justify-between" : "justify-end"} pt-grid-lg`}>
                {modalOptions.currentStep !== 1 &&
                    <Button
                        text={strings("button.previous")}
                        bgColor="bg-secondary-darkest"
                        padding="py-2 px-grid-xl"
                        onClick={prev}
                    />
                }
                <Button
                    text={strings("button.next")}
                    bgColor="bg-secondary-darkest"
                    padding="py-2 px-grid-xl"
                    onClick={next}
                />
            </div>
        </>
    );
};

export default CreateDirectory;