import React, { useMemo } from "react";

const CachedImg = (props) =>
{
    const { src, alt = "", className } = props;

    const memoizedImage = useMemo(() =>
    {
        const img = new Image();
        img.src = src;
        if (className)
        {
            img.className = className;
        }
        img.alt = alt;
        return img;
    }, [src, className]);

    return <img src={memoizedImage.src} alt={`${alt}`} className={memoizedImage.className} />;
};

export default CachedImg;