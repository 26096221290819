import { useEffect, useState } from "react";

const checkNetworkConnection = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const updateNetState = () => {
      const connection = navigator.connection;
      if (connection) {
        setIsOnline(navigator.onLine);
      }
    };

    window.addEventListener("online", updateNetState);
    window.addEventListener("offline", updateNetState);

    return () => {
      window.removeEventListener("online", updateNetState);
      window.removeEventListener("offline", updateNetState);
    };
  }, []);

  return isOnline;
};

export default checkNetworkConnection;